import { Component, OnInit } from '@angular/core';
import { UpgradableComponent } from 'theme/components/upgradable';
import { BlankLayoutCardComponent } from 'app/components/blank-layout-card';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { AuthService } from 'app/services';
import { User } from 'app/models';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { NotificationService } from 'app/services/notification/notification.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-dashboard-comision',
  templateUrl: './dashboard-comision.component.html',
  styleUrls: ['./dashboard-comision.component.css']
})
export class DashboardComisionComponent extends BlankLayoutCardComponent implements OnInit {
  users:any[]=[];
  voto:any;
  public comisionForm: FormGroup;
  public comisionCreateForm: FormGroup;
  comision:any="";
  comisiones:any[]=[];
  hide:boolean = true;
  currentUser: User;
  currentUserSubscription: Subscription;
  filtroReunion:any="";
  p:any;
  admin: boolean = false;
  checkDetalle:boolean = false;
  checkModificar:boolean = false;
  checkAgregar:boolean = false;
  error: any;
  id_disponibles:any[]=[];
  id_reunion="";

  constructor(private http: HttpClient,
              private authService: AuthService,
              private fb: FormBuilder,
              private router: Router,
              public datepipe: DatePipe,
              private notifyService : NotificationService){
    super();
    this.comisionForm = this.fb.group({
      id_reunion: new FormControl('', Validators.required),
      fechaHoraInicia: new FormControl(''),
      fechaHoraFinaliza: new FormControl(''),
      webex_url: new FormControl(''),
      webex_active: new FormControl(''),
    });
    this.comisionCreateForm = this.fb.group({
      id_reunion: new FormControl('', Validators.required),
      webex_url: new FormControl(''),
      webex_active: new FormControl('', Validators.required),
    });
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      this.currentUser = user;});


   }

  ngOnInit(): void {
    localStorage.removeItem('idReunion');
    this.loadComision();
    if(this.currentUser.rol=="USUREL" || this.currentUser.rol=="UADMIN"){
      this.admin=true;
    }
    if(localStorage.getItem('dataComision') != 'null'){
      this.comisiones=JSON.parse(localStorage.getItem('dataComision'))
    }else{
      this.comisiones=[];
    }
    this.http.get(`${environment.apiBaseUrl}/api/users/all`).subscribe(
      (data:any)=>{
        this.users=data;
      });
    this.comisionForm.valueChanges.subscribe(() => {
      this.error = null;
    });
  }
  ingresar(id){

    localStorage.setItem('idReunion',JSON.stringify(id));
    this.router.navigate(['/app/reunion'])

  }

  public onInputChange(event) {
    event.target.required = true;
  }
  get f() { return this.comisionForm.controls; }
  get f_c() { return this.comisionCreateForm.controls; }

  afirmativo(){}
  negativo(){}
  agregar(){
    this.http.get(`${environment.apiBaseUrl}/api/GDespachoReunion/idReunionesDisponibles/${this.currentUser.id}`).subscribe((data:any)=>{
      this.id_disponibles=data;
    })
    this.checkDetalle=false;
    this.checkModificar=false;
    this.checkAgregar=true;
  }

  grabar(){
    let head = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    //console.log(this.comisionForm.value);
    this.http.put(`${environment.apiBaseUrl}/api/reunionInstancia/editar`,this.comisionForm.value,{headers: head}).subscribe(
    (data:any)=>{
      this.notifyService.showSuccess("Reunion actualizada con exito!","");
      //this.addUser.reset()
      this.http.get(`${environment.apiBaseUrl}/api/reunionInstancia/edit/${this.comision.id_reunion}`).subscribe(
        (data:any)=>{
          this.comision =data;
          // this.sesion=data;
          this.loadComision();
          this.checkDetalle=true;
          this.checkModificar=false;
          this.checkAgregar=false;
        });
    },
    error=>{
      this.notifyService.showError("Hubo un problema al editar la reunion!","");
    })

  }

  crear(){
    let head = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    var infoA:any;
    infoA=`{
      "id_reunion":"${this.id_reunion}",
      "webex_url":"${this.f_c.webex_url.value}",
      "webex_active":"${this.f_c.webex_active.value}",
      "presentes":null,
    }`
    this.http.post(`${environment.apiBaseUrl}/api/reunionInstancia/crear`,infoA,{headers: head}).subscribe(
      (data:any)=>{
        this.loadComision();
        this.comisionCreateForm.reset();
        this.notifyService.showSuccess("Reunion creada con exito!","");
        this.checkDetalle=false;
        this.checkModificar=false;
        this.checkAgregar=false;
      },
      error=>{
        this.notifyService.showError("Hubo un problema al crear al reunion!","");}
    )
  }

  iniciar(id){
    this.http.get(`${environment.apiBaseUrl}/api/reunionInstancia/inicializarReunion/${id}`).subscribe(
      (data:any)=>{
        this.loadComision()
      },
      error=>{this.notifyService.showError(error.error,"");});
  }
  finalizar(id){
    this.http.get(`${environment.apiBaseUrl}/api/reunionInstancia/finalizarReunion/${id}`).subscribe(
      (data:any)=>{
        this.loadComision()
      },
      error=>{this.notifyService.showError(error.error,"");});
  }
  renaudar(id){
    this.http.get(`${environment.apiBaseUrl}/api/reunionInstancia/reanudarReunion/${id}`).subscribe(
      (data:any)=>{
        this.loadComision()
      },
      error=>{this.notifyService.showError(error.error,"");});
  }
  modificar(id){
    this.http.get(`${environment.apiBaseUrl}/api/reunionInstancia/edit/${id}`).subscribe(
      (data:any)=>{
        this.comision = data;
        this.checkDetalle=false;
        this.checkModificar=true;
        this.checkAgregar=false;

        this.comisionForm.setValue({
          id_reunion: this.comision.id_reunion,
          fechaHoraInicia: this.comision.fechaHoraInicia,
          fechaHoraFinaliza: this.comision.fechaHoraFinaliza,
          webex_url: this.comision.webex_url,
          webex_active: this.comision.webex_active,
        });
      });
  }
  detalle(id){    this.http.get(`${environment.apiBaseUrl}/api/reunionInstancia/edit/${id}`).subscribe(
    (data:any)=>{
      this.comision=data;
      this.checkDetalle=true;
      this.checkModificar=false;
      this.checkAgregar=false;
      this.loadComision()
    },
    error=>{this.notifyService.showError(error.error,"");});}


  estaIniciada(reunion){
    if(reunion.fechaHoraInicia && !reunion.fechaHoraFinaliza){
      return "INICIADA"
    }else if(reunion.fechaHoraInicia && reunion.fechaHoraFinaliza){
      return "FINALIZADA"
    }else{
      return ""
    }
  }

  loadComision(){
    this.http.get(`${environment.apiBaseUrl}/api/users/comisiones/${this.currentUser.id}`).subscribe((data:any)=>{
      localStorage.setItem('dataComision',JSON.stringify(data));
      if (data != null){
      this.comisiones=JSON.parse(localStorage.getItem('dataComision'))
      }else{
        this.comisiones=[];
      }
    })

  }
  parsearFechaHora(datetime :Date){
    if(datetime){
      var dia = new Date(datetime).getDay();
      var mes = new Date(datetime).getMonth();
      var anio = new Date(datetime).getFullYear();
      var horas = new Date(datetime).getHours();
      var minutos = new Date(datetime).getMinutes();
      var segundos = new Date(datetime).getSeconds();
      return dia + "/" + mes + "/" +anio + " " + horas + ":" + minutos + ":" + segundos;
    }else{
      return "-"
    }
  }

  traductorDeBoolean(bool){
    if(bool){
      return "SI"
    }else{
      return "NO"
    }
  }
  parsearFechaHoraPipe(datetime :Date){
    var somaDateVar:any;
    somaDateVar = this.datepipe.transform(datetime, 'dd/MM/yyyy');
    if(datetime){
      return somaDateVar;
    }else{
      return "-"
    }
  }

}
