import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LoginRequestService } from 'app/services/signal-r/loginRequest.service';
import { SolicitudesService } from 'app/services/solicitudes.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-table-enroll-request',
  templateUrl: './table-enroll-request.component.html',
  styleUrls: ['./table-enroll-request.component.scss']
})
export class TableEnrollRequestComponent implements OnInit {
  
  constructor(public loginRequestService:LoginRequestService, private http: HttpClient,
    private solicitudesService:SolicitudesService) {}

  ngOnInit(): void {
    
    //Establece la conexion para recibir las notificaciones
    this.loginRequestService.startConnection(this)
    .then(() => 
    this.loginRequestService.startListeningEnrollRequest(this))
    
  }

  public reconnect(){
    // Intenta Reconectar a los 3seg
    return new Promise(function(resolve, reject) {  setTimeout(resolve, 3000)})
    .then(() => {
      console.log("Intentando reconexion")
      //Al terminar el Timeout (A los 3seg) Intentará Reconectar
      this.loginRequestService.connect()
    })
  }
  public addEnrollRequest(data){
    this.solicitudesService.solicitudNuevaEnroll(data);
  }

  public getEnrollRequests(){
    return this.solicitudesService.getSolicitudesEnroll()
  
  }

  public acceptEnrollRequest(item){
    //console.log(id)
    this.http.get(`${environment.apiBaseUrl}/api/users/acceptRequestRemoteEnroll/${item.id}`).subscribe((data:any)=>{
      this.solicitudesService.solicitudAtendidaEnroll(item);
    });
  }
}
