import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { MensajeHub } from '../../models/mensajeHub';
import { environment } from 'environments/environment';
import { Observable, Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { User } from 'app/models';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '..';
import { Router } from '@angular/router';
import { NotificationService } from '../notification/notification.service';


@Injectable({
  providedIn: 'root'
})
export class LoginRequestService {
  currentUser: User;
  currentUserSubscription: Subscription;

  private boolLogin: boolean=false
  private boolEnroll: boolean=false
  constructor(private authService: AuthService, private http: HttpClient,private router: Router){
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      this.currentUser = user;
    });


  }

  private hubConnection: signalR.HubConnection

  
  public startConnection(component) {
    return new Promise<void>((resolve, reject) => {
      //if hub is already builded
      
      if (this.hubConnection){
        resolve()
        return
      } 
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(environment.loginRequestHub)
        .build();
      // Mantengo la conexion por 120seg o 2min
      this.hubConnection.serverTimeoutInMilliseconds = 120000;
      this.connect()
        .then(() => resolve())
        .catch(() => reject())

      //El evento se ejecutará cada vez que se caiga el websocket
      this.hubConnection.onclose((data) => {
        //console.log("Se ha cerrado la conexión " + data)
     
        component.reconnect()
      });
    }) 
  }

  public connect(){
    return new Promise<void>((resolve, reject) => {
      this.hubConnection
        .start()
        .then(() => { console.log('Conexion Establecida'), resolve() })
        .catch(err => { console.log('Ocurrió el siguiente error al intentar reconectar la conexión: ' + err), reject() })
    })
  }

  public getConnectionID() {
    this.hubConnection.invoke('getConnectionId')
    .then((loginData) => console.log("ConnectionID =", loginData))
    .catch(err => console.log("Error en GetConnectionID", err));
  }

  public startListeningNewLoginRequest(component) {
    console.log("Waiting Login Request");
   
    if(!this.boolLogin){
     this.hubConnection.on('newLoginRequest', (loginData) => {
       component.addLoginRequest(loginData)
      });
    this.boolLogin=true  
    }
  }

  public startListeningEnrollRequest(component) {
    
    console.log("Waiting Enroll Request");
    
    if(!this.boolEnroll){
    this.hubConnection.on('newEnrollRequest', (enrollData) => {
      //console.log("enroll data",enrollData);
      component.addEnrollRequest(enrollData)
    });
    this.boolEnroll=true  
  }
  }

  closeConnection() {
    this.hubConnection.stop()
  };

}
