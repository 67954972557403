import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { User } from 'app/models';
import { Login } from 'app/models/login';


@Injectable({ providedIn: 'root' })
export class CurrentUserService {
    currentUser:Observable<User>;
    constructor(private http: HttpClient) {
    }

    public login(usuario: string, contraseña: string): Observable<User> {
        var url = `${environment.apiBaseUrl}/api/users/loginWithUsername`
        this.currentUser = this.http.post<User>(url, { usuario, contraseña })
        return this.currentUser
    }

    public getCurrentUser(){
        return this.currentUser;
    }


}