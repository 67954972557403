import { SolicitudesService } from './../../services/solicitudes.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-solicitudes',
  templateUrl: './solicitudes.component.html',
  styleUrls: ['./solicitudes.component.scss']
})
export class SolicitudesComponent {

  constructor(private solicitudesService:SolicitudesService) {}

  solicitudesPendientes() {
    return this.solicitudesService.hayPendientes();
  }

  cantidadSolicitudes() {
    return this.solicitudesService.cantidadSolicitudes();
  }
}
