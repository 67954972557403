import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule as NgForms } from '@angular/forms';
import { AuthInterceptor, AuthService } from '@services/*';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChartsModule } from './pages/charts';
import { LoginComponent } from './pages/pages/login';
import { ErrorComponent } from './pages/pages/error';
import { ComponentsModule } from './pages/components';
import { DashboardModule } from './pages/dashboard';
import { Dashboard2Module } from './pages/dashboard2';
import { ThemeModule } from 'theme';
import { FormsModule } from './pages/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { RenaperModule } from 'app/renaper/renaper.module';
import { MenuModule } from 'app/menu/menu.module';
import { NominalComponent } from 'app/nominal/nominal.component';
import { DiputadosComponent } from './diputados/diputados.component';
import { AgregarUsuarioComponent } from './agregar-usuario/agregar-usuario.component';
import { UsuariosComponent } from './usuarios/usuarios.component'
import { NgxPaginationModule } from 'ngx-pagination';
import { FilterPipe } from './pipes/filter.pipe';
import { FilterUsPipe } from './pipes/filterUs.pipe';
import { FilterReunionPipe } from './pipes/filterReunion.pipe';
import { FilterSesionPipe } from './pipes/filterSesion.pipe';
import { DashboardComisionComponent } from './comision/dashboard/dashboard-comision.component';
import { DashboardComisionModule } from './comision/dashboard/dashboard-comision.module';
import { BifurcacionComponent } from './bifurcacion/bifurcacion.component';
import { SesionAdminComponent } from './sesion-admin/sesion-admin.component';
import { AgregarSesionComponent } from './agregar-sesion/agregar-sesion.component';
import { ReunionComponent } from './comision/reunion/reunion.component';
import { registerLocaleData } from '@angular/common';
import { DatePipe } from '@angular/common';
//import { GrillaComponent } from './grilla/grilla.component'
import localeEsAr from '@angular/common/locales/es-AR';
import { AusentesComponent } from './ausentes/ausentes.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoginWithFingerprintComponent } from './login-with-fingerprint/login-with-fingerprint.component';
import { EnrollFingerprintComponent } from './enroll-fingerprint/enroll-fingerprint.component';
import { EnrollAdminComponent } from './enroll-admin/enroll-admin.component';

import { IndexPresencialDiputadoComponent } from './index-presencial-diputado/index-presencial-diputado.component';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { UjierComponent } from './ujier/ujier.component';


import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MdePopoverModule } from '@material-extended/mde';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';








/* import { VotacionModalComponent } from './components/votacion-modal.component'; */
//import { VotoDiputadoComponent } from './components/voto-diputado/voto-diputado.component';


//import { HemicicloFullComponent } from './components/hemiciclo-full/hemiciclo-full.component';

//registerLocaleData(localeEsAr, 'es-Ar');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ErrorComponent, 
    NominalComponent, 
    DiputadosComponent, 
    AgregarUsuarioComponent, 
    FilterUsPipe, 
    FilterReunionPipe, 
    UsuariosComponent, 
    FilterPipe, 
    FilterSesionPipe, 
    DashboardComisionComponent, 
    BifurcacionComponent, 
    SesionAdminComponent, 
    AgregarSesionComponent, 
    ReunionComponent, 
    AusentesComponent, 
    LoginWithFingerprintComponent, 
    EnrollFingerprintComponent, 
    EnrollAdminComponent,

    
    
    
    ],



  imports: [
    
    BrowserModule,
    AppRoutingModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    NgForms,
    ComponentsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    ClipboardModule,
    DashboardModule,
    ClipboardModule,
    RenaperModule,
    DashboardComisionModule,
    Dashboard2Module,
    MenuModule,
    FormsModule,
    ChartsModule,
    ThemeModule,
    HttpClientModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    PdfViewerModule,
    ToastrModule.forRoot({preventDuplicates: true}),
    MatCardModule,
    MatToolbarModule,
    MdePopoverModule,
    MatToolbarModule,
    MatTabsModule,
    MatSelectModule,   
    MatFormFieldModule,     
    NgbModule,
  
    
    
  ],

  providers: [
    
    DatePipe,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    
    
  ],
  
  bootstrap: [AppComponent],
})
export class AppModule { }
