import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { MensajeHub } from '../../models/mensajeHub';
import { environment } from 'environments/environment';
import { Observable, Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { User } from 'app/models';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '..';
import { Router } from '@angular/router';
import { NotificationService } from '../notification/notification.service';


@Injectable({
  providedIn: 'root'
})
export class LoginWithFingerprintService {
  currentUser: User;
  currentUserSubscription: Subscription;
  waitingEnroll: boolean = false;
  waitingLogin: boolean = false;

  constructor(private authService: AuthService, private http: HttpClient,private router: Router){
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  private hubConnection: signalR.HubConnection

  public startConnection(component) {
    return new Promise<void>((resolve, reject) => {
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(environment.loginWithFingerprintHubUrl)
        .build();
      // Mantengo la conexion por 120seg o 2min
      this.hubConnection.serverTimeoutInMilliseconds = 120000;

      this.connect()
        .then(() => resolve())
        .catch(() => reject())

      //El evento se ejecutará cada vez que se caiga el websocket
      this.hubConnection.onclose((data) => {
        //console.log("Se ha cerrado la conexión " + error)
        if (data)
          component.reconnect()
      });
    }) 
  }

  public connect(){
    return new Promise<void>((resolve, reject) => {
      this.hubConnection
        .start()
        .then(() => { console.log('Connexion Establecida'), resolve() })
        .catch(err => { console.log('Ocurrió el siguiente error al intentar reconectar la conexión: ' + err), reject() })
    })
  }

  public addConnectionId(ip) {
    return new Promise<void>((resolve, reject) => {
      this.hubConnection.invoke('addConnectionId', (ip))
      .then((data) => {console.log("AddConnectionId: " + data),  resolve()})
      .catch(err => {console.log("Error en AddConnectionId", err), reject()});   
    })
  }

  public addToLoginList(localIp) {
    return new Promise<void>((resolve, reject) => {
      this.hubConnection.invoke('addToLoginList', (localIp))
      .then((data) => {
        if (data){
          //console.log("AddToLoginList Succefull, ConnectionId: "+data)
          resolve()
        }
        /*
        else{
          console.log("Error en AddToLoginList")
          this.closeConnection()
          this.router.navigate(['app/enrollFingerprint'], { queryParams: { ip: localIp } })
          reject()
        }*/
      })
      .catch(err => {console.log("Error en AddToLoginList", err), reject()});   
    })
  }

  public addToEnrollList(localIp, fingerNumber) {
    return new Promise<void>((resolve, reject) => {
      this.hubConnection.invoke('addToEnrollList', localIp, fingerNumber)
      .then((data) => {
        if (data){
          //console.log("AddToEnrollList Succefull, ConnectionId: ", data),  
          resolve()
        }
        else{
          console.log("Error en AddToEnrollList")
          this.closeConnection()
          reject()
        }
      })
      .catch(err => {console.log("Error en AddToEnrollList", err), reject()});   
    })
  }

  public AddToEnrollListByAdmin(id, fingerNumber) {
    return new Promise<void>((resolve, reject) => {
      this.hubConnection.invoke('addToEnrollListByAdmin',  Number(id), fingerNumber)
      .then((data) => {
        if (data){
          console.log("AddToEnrollListByAdmin Succefull, ConnectionId:.. "),  
          resolve()
        }
        else{
          console.log("Error en AddToEnrollListByAdmin")
          this.closeConnection()
          reject()
        }
      })
      .catch(err => {console.log("Error en AddToEnrollListByAdmin", err), reject()});   
    })
  }

  public getConnectionID() {
    this.hubConnection.invoke('getConnectionId')
    .then((loginData) => console.log("ConnectionID =", loginData))
    .catch(err => console.log("Error en GetConnectionID", err));
  }

  public startListeningEnroll(component) {
    console.log("Waiting Enroll");
    this.waitingEnroll = true
    this.hubConnection.on('enroll', (data) => { 
      this.waitingEnroll = false
      const enrollData = JSON.parse(data)
      if (enrollData.result == 200)
        component.succefullFingerprint(enrollData.message)
      else
        component.failFingerprint(enrollData.message)
    })
  }

  public startListeningEnrollByAdmin(component) {
    console.log("Waiting enrollByAdmin");
    this.hubConnection.on('enrollByAdmin', (data) => { 
      const enrollData = JSON.parse(data)
      //console.log(enrollData)
      if (enrollData.result == 200)
        component.succefullFingerprint(enrollData.message)
      else
        component.failFingerprint(enrollData.message)
    })
  }

  public startListeningLogin(component) {
    console.log("Waiting Login");
    this.waitingLogin = true
    this.hubConnection.on('login', (loginData) => {
      this.waitingLogin = false
      loginData = JSON.parse(loginData)
     // console.log(loginData);

      this.authService.loginByFingerprint(loginData)
      .then(() => {
        if (loginData.enroll){
          component.goToEnroll()
          return
        }  
        this.http.get(`${environment.apiBaseUrl}/api/users/comisiones/${this.currentUser.id}`).subscribe((dataComision:any) =>
        { 
          localStorage.setItem('dataComision',JSON.stringify(dataComision))
          this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/iniciado`).subscribe((data:any)=>
          { 
            localStorage.setItem('dataIniciado', JSON.stringify(data))
            if(data){
              if(data.fechaHoraInicia && data.fechaHoraFinaliza == null || dataComision){
                if(this.currentUser.rol == "USUDIP") {
                  this.router.navigate(['app/index-presencial']);
                } else if(this.currentUser.rol == "UPRESI"){
                  this.router.navigate(['app/presidente']);//va a ir a presidente
                } else if(this.currentUser.rol == "SECLEG"){
                  this.router.navigate(['app/secretariolegislativo']);
                } else if(this.currentUser.rol == "SECADM"){
                  this.router.navigate(['app/secretarioadministrativo']);
                } else if(this.currentUser.rol == "UUJIER"){
                  this.router.navigate(['app/ujier']);
                } else if(this.currentUser.rol == "UADMIN"){
                  this.router.navigate(['app/dashboard']);
                } else if(this.currentUser.rol == "USULEG"){
                  this.router.navigate(['app/usuariolegislativo']);
                } else if(this.currentUser.rol == "OBSERV"){
                  this.router.navigate(['app/observador']);
                }
              }else{
                this.router.navigate(['app/off-sesion']);
              }
            }else{
              this.router.navigate(['app/off-sesion']); 
            }               
          })
        })
        component.succefullFingerprint()
      })
      .catch(() => {
        console.log("error.");
        
        component.failFingerprint()
      });
    })
  }

  // Disable flag for work
  //edge://flags/#enable-webrtc-hide-local-ips-with-mdns
  public determineLocalIpByRtc() {
    return new Promise<string>((resolve, reject) => {
      try{
        console.log("determineLocalIpByRtc")
        var ipRegex = new RegExp(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/);
        window.RTCPeerConnection = window.RTCPeerConnection;
        var pc = new RTCPeerConnection({iceServers:[]}), noop = function(){};
        pc.createDataChannel('');
        pc.createOffer().then(pc.setLocalDescription.bind(pc));
        pc.onicecandidate = function(ice) {  //listen for candidate events          
          if(ice && ice.candidate && ice.candidate.candidate){
            var myIP = ipRegex.exec(ice.candidate.candidate)[1];
            //console.log('IP: ', myIP);   
            pc.onicecandidate = noop;
            resolve(myIP);
          }
          reject();
        };
      }
      catch{
        reject() 
      }
    }) 
  }

  public determineLocalIpByUrl() {
    return new Promise<string>((resolve, reject) => {
      try{
        var ip = (location.href).split("?")[1].split("ip=")[1]
        if (ip){ 
          //console.log("IP: ", ip)
          resolve(ip);
        }
        else{
          this.determineLocalIpByRtc()
          .then((ip) => resolve(ip))
          .catch(() => reject())
        }
      }
      catch{
        this.determineLocalIpByRtc()
        .then((ip) => resolve(ip))
        .catch(() => reject())
      }
    }) 
  }

  closeConnection() {
    this.hubConnection.stop()
  };

}
