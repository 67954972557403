import { Component, HostBinding, OnInit, OnDestroy, HostListener } from '@angular/core';
import { UpgradableComponent } from 'theme/components/upgradable';
import { Observable, Subject, Subscription } from 'rxjs';
import { SignalRService } from 'app/services/signal-r/signal-r.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@services/*';
import { User } from 'app/models';
import { GrillaService} from 'app/services/grilla.service';
import { environment } from 'environments/environment';
import { ConferenceService } from 'app/services/conference/conference.service';


@Component({
  selector: 'app-index-remote-diputado',
  templateUrl: './index-remote-diputado.component.html',
  styleUrls: ['./index-remote-diputado.component.scss']
})
export class IndexRemoteDiputadoComponent extends UpgradableComponent implements OnInit,OnDestroy {
  @HostBinding('class.mdl-grid') private readonly mdlGrid = true;
  @HostBinding('class.mdl-grid--no-spacing') private readonly mdlGridNoSpacing = true;
  @HostListener('window:unload', ['$event'])
  unloadHander($event) {
    this.SignalR.closeConnection();
    return $event.returnValue='Your changes will not be saved';
  }

  currentUser: User;
  currentUserSubscription: Subscription;
  mostrarVotacion: any=false;
  

  constructor(
    public SignalR:SignalRService,
    private authService: AuthService,
    public gr: GrillaService,
    
    private confeservice: ConferenceService,
    private http:HttpClient,) {
    super();
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      this.currentUser = user;
    })
  }
  
  ngOnDestroy() {

  };
  
  
  ngOnInit(): void {
      if (this.currentUser.rol =="USUDIP" ) {
        this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/${this.currentUser.id}/entrar`).subscribe(data=>{
          this.confeservice.loadConference();
        });
      } else {
        this.confeservice.loadConference();
    }
  }

  chequearHemiciclo(){
    if(localStorage.getItem("mostrarVotacion")){
      this.mostrarVotacion=localStorage.getItem("mostrarVotacion");
    }else{
      this.mostrarVotacion="false";
    }
    return this.mostrarVotacion;
  }

 
  stopConnectio(){
    this.SignalR.closeConnection();
  }
  
  
}