import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { environment } from 'environments/environment';
import { Observable, Subscription } from 'rxjs';
import { GrillaService } from '../grilla.service';

@Injectable({
  providedIn: 'root'
})
export class ActualizarVotacionesService {

  private hubConnection: signalR.HubConnection


  constructor(private http: HttpClient, public gr: GrillaService) {
    this.gr.loadGrilla()
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.votacionHubUrl)
      .build();

    //una vez que se recibe el mensaje, llama a recargar la grilla
    this.hubConnection.on('actualizarVotaciones', (votaciones) => {
      this.gr.reloadGrilla(JSON.parse(votaciones))
    });
    this.hubConnection
      .start()
      .then(() => console.log('Se inició la conexión correctamente'))
      .catch(err => console.log('Ocurrió el siguiente error al iniciar la conexión: ' + err))
  }
}
