import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule as NgFormsModule } from '@angular/forms';
import {ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '../pages/forms';
import { MatSelectModule } from '@angular/material/select';
import { TooltipModule } from '../../theme/directives/tooltip/tooltip.module';
import { ThemeModule } from 'theme';
//import { MenuComponent } from 'app/menu/menu.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TooltipModule,
    //MaterialAngularSelectModule,
    NgFormsModule,
    FormsModule, 
    MatSelectModule,
    ThemeModule,
    ReactiveFormsModule
  ]
})
export class MenuModule { }
