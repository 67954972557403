import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { SesionInstanciaActiva } from 'app/models/sesionInstanciaActiva';


@Injectable({ providedIn: 'root' })
export class SesionService {
    constructor(private http: HttpClient) { }

    getActiva() {
        return this.http.get<SesionInstanciaActiva>(`${environment.apiBaseUrl}/api/sesionInstancia/iniciado`);
    }

}