
import { Component, HostBinding, OnInit, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { UpgradableComponent } from 'theme/components/upgradable';
import { SignalRService } from 'app/services/signal-r/signal-r.service';
import { GrillaService} from 'app/services/grilla.service';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { VotacionModalResultadoService } from 'app/services/signal-r/votacion-modal-resultado.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-votacion-modal-resultado',
  templateUrl: './votacion-modal-resultado.component.html',
  styleUrls: ['./votacion-modal-resultado.component.scss']
})

export class VotacionModalResultadoComponent extends UpgradableComponent implements OnInit,OnDestroy{
  @HostBinding('class.mdl-grid') private readonly mdlGrid = true;
  @HostBinding('class.mdl-grid--no-spacing') private readonly mdlGridNoSpacing = true;
  @HostListener('window:unload', ['$event'])
  unloadHander($event) {
    this.SignalR.closeConnection();
    return $event.returnValue='Your changes will not be saved';
  }

  @ViewChild('votacionmodal', {static: false}) contenidoDelModal: ElementRef;
  closeResult = '';
  scrollbol:boolean=false;
  votacion:any;
  hide:boolean = true;


  
  constructor(


    private modalService: NgbModal,
    public SignalR:SignalRService,
    public gr: GrillaService,
    private votacionModalService: VotacionModalResultadoService,
    public router: Router,) {
    super();

  }

  ngOnInit() {
    
     this.votacionModalService.getVotacion().subscribe({ 
     next: (votacion) => { 
      if(votacion && votacion.fechaHoraFinaliza){           
            this.votacion = votacion ;
            this.hide = false
            this.open(this.contenidoDelModal);
      }
    },
    error: (err) => { },
  });


  


      //------------------------------------------------------------------


/* 
      //agregando el signalR para que muestre la modal una vez finalizada la votacion por servicio.
      this.SignalR.getVotacion$().subscribe((data:MensajeHub)=>{
     
        if (data.tipo == MensajeHub.CONST_TIPO_FINALIZAR_VOTO)
        {
                this.hide = false
                this.open(this.contenidoDelModal);
              
        }
      })
      this.SignalR.startListeningNewLogoutRequest(this)
  
 */

    }

  ngOnDestroy() {

  };

  scroll(el: HTMLElement) {
    if(this.scrollbol){
      el.scrollIntoView({behavior: 'smooth'});
      this.scrollbol=false;
    }
  }

  


  open(votacionModal) {
    
    this.modalService.open(votacionModal, {ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: "static", size:'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  close() {
    this.votacion = null ;
    this.hide = true;
    this.modalService.dismissAll();
  }

  redirectSecAdmin(){
    this.votacion = null ;
    this.hide = true;
    this.modalService.dismissAll();
    this.router.navigate(['/app/secretarioadministrativo']) 
  }

  modoAprobacion():string{
    switch (this.votacion.modoAprobacion){
      case "MA": return "MAYORIA ABSOLUTA"; 
      case "MS": return "MAYORIA SIMPLE"; 
      case "P13": return "UN TERCIO DE LOS PRESENTES"; 
      case "P15": return "UN QUINTO DE LOS PRESENTES"; 
      case "P23": return "DOS TERCIOS DE LOS PRESENTES";
      case "C23": return "DOS TERCIOS DE LA CAMARA";
      default:"";
    }
  
  }


  resultadoVotacion():string{
    
    switch(this.votacion.resultado){
      case "A":return "APROBADO";
      case "D":return "DESAPROBADO";
      default:"";
    }
  }

}