import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Caratula } from 'app/models/caratula';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class CaratulaService {

    
    constructor(private http: HttpClient) { }

    public getAll(): Observable<Caratula[]> {
        return this.http.get<Caratula[]>(`${environment.apiBaseUrl}/api/caratula`);
    }

    public getBy(letra:string, numero:number, anio:string, alcance:number):  Observable<Caratula> {
        return this.http.get<Caratula>
        (`${environment.apiBaseUrl}/api/caratula/${letra}/${numero}/${anio}/${alcance}`);
    }

   
}