import { Component, OnInit } from '@angular/core';

import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { NotificationService } from 'app/services/notification/notification.service';
import { BlankLayoutCardComponent } from 'app/components/blank-layout-card';
import { UpgradableComponent } from 'theme/components/upgradable';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { User } from 'app/models';
import { AuthService } from 'app/services';


@Component({
  selector: 'app-agregar-usuario',
  templateUrl: './agregar-usuario.component.html',
  host: {'class': 'blank-layout-card mdl-grid ui-components'},
  styleUrls: ['./agregar-usuario.component.css']
})
export class AgregarUsuarioComponent extends BlankLayoutCardComponent implements OnInit {
  public diputados:any[]= [];
  formData= new FormData;
  public bancas:any[]=[];
  public bloques:any[]=[];
  public roles:any[]=[];
  bancaS:any;
  bloqueS:any;
  file:any="";
  rolS:any;
  href:any;
  currentUser: User;
  currentUserSubscription: Subscription;
  public addUser: FormGroup;
  usuario: any;
  nombre: any;
  apellido: any;
  contrasenia: any;
  email: any;
  error: any;
  dni: any;
  genero: any;
  facebook: any;
  twitter: any;
  instagram: any;
  distrito: any;
  seccionElectoral: any;
  mandato: any;
  oficina: any;
  interno: any;
  bloqueId: any;
  pictureUrl: any;
  id_banca: any;
  presidenteBloque: any;
  validarRenaper: any;
  banca: any;
  rol: any;

  constructor(private http: HttpClient,
              public router: Router,
              private fb: FormBuilder,
              private authService: AuthService,
              private notifyService : NotificationService,
              ) {
                super();
                this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
                  this.currentUser = user;})
                  this.bancas=[];
    this.diputados=[];
    this.addUser = this.fb.group({
      usuario: new FormControl('', Validators.required),
      nombre: new FormControl('', Validators.required),
      apellido: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      dni: new FormControl('', Validators.required),
      genero: new FormControl('', Validators.required),
      rol: new FormControl('', Validators.required),
      facebook: new FormControl(''),
      twitter: new FormControl(''),
      instagram: new FormControl(''),
      distrito: new FormControl(''),
      seccionElectoral: new FormControl(''),
      mandato: new FormControl(''),
      oficina: new FormControl(''),
      interno: new FormControl(''),
      bloqueId: new FormControl(''),
      pictureUrl: new FormControl(''),
      presidenteBloque: new FormControl(''),
      validarRenaper: new FormControl('',Validators.required),
      banca: new FormControl(''),
    });
    this.usuario = this.addUser.get('usuario');
    this.nombre = this.addUser.get('nombre');
    this.apellido = this.addUser.get('apellido');
    this.email = this.addUser.get('email');
    this.dni = this.addUser.get('dni');
    this.genero = this.addUser.get('genero');
    this.facebook = this.addUser.get('facebook');
    this.twitter = this.addUser.get('twitter');
    this.instagram = this.addUser.get('instagram');
    this.distrito = this.addUser.get('distrito');
    this.seccionElectoral = this.addUser.get('seccionElectoral');
    this.mandato = this.addUser.get('mandato');
    this.oficina = this.addUser.get('oficina');
    this.rol = this.addUser.get('rol');
    this.interno = this.addUser.get('interno');
    this.bloqueId = this.addUser.get('bloqueId');
    this.pictureUrl = this.addUser.get('pictureUrl');
    this.presidenteBloque = this.addUser.get('presidenteBloque');
    this.validarRenaper = this.addUser.get('validarRenaper');
    this.banca = this.addUser.get('banca');

   }
   get f() { return this.addUser.controls; }

   public onInputChange(event) {
     event.target.required = true;
   }
   onFileSelected(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.addUser.get('pictureUrl').setValue(this.f.usuario.value.toLowerCase()+'.'+(this.file.name.split('.'))[(this.file.name.split('.')).length -1]);
      this.formData.append('file',this.file);
      
    }
  }
  ngOnInit(): void {
    this.addUser.valueChanges.subscribe(() => {
      this.error = null;
    });
    this.http.get(`${environment.apiBaseUrl}/api/banca/disponibles`).subscribe(
      (data:any)=>{
        this.bancas=data;
      });
    this.http.get(`${environment.apiBaseUrl}/api/bloque/disponibles`).subscribe(
      (data:any)=>{
        this.bloques=data;
      });
    this.http.get(`${environment.apiBaseUrl}/api/users/rolDisponibles`).subscribe(
      (data:any)=>{
        this.roles=data;
      });
  }
  grabar(){
    if(this.f.pictureUrl.value == ''){
      this.addUser.get('pictureUrl').setValue('logocamara.png');
    }else{
      this.addUser.get('pictureUrl').setValue(this.f.usuario.value.toLowerCase()+'.'+(this.file.name.split('.'))[(this.file.name.split('.')).length -1]);  
    }
    let head = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    var infoA:any;
    var banca:any;
    if(!this.bancaS){
      banca=0;
    }else{
      banca=this.bancaS
    }
    infoA=`{
      "dni":"${this.f.dni.value}",
      "genero":"${this.f.genero.value}",
      "nombre":"${this.f.nombre.value}",
      "apellido":"${this.f.apellido.value}",
      "email":"${this.f.email.value}",
      "facebook":"${this.f.facebook.value}",
      "twitter":"${this.f.twitter.value}",
      "instagram":"${this.f.instagram.value}",
      "distrito":"${this.f.distrito.value}",
      "seccionElectoral":"${this.f.seccionElectoral.value}",
      "mandato":"${this.f.mandato.value}",
      "oficina":"${this.f.oficina.value}",
      "interno":"${this.f.interno.value}",
      "bloqueId":${this.f.bloqueId.value},
      "pictureUrl":"${this.f.pictureUrl.value}",
      "id_banca":"${banca}",
      "presidenteBloque":${this.f.presidenteBloque.value},
      "usuario":"${this.f.usuario.value}",
      "rol":"${this.f.rol.value}",
      "validarRenaper":${this.f.validarRenaper.value},
      "banca":"${this.f.banca.value}",
      "creadoPor":"${this.currentUser.usuario}",
      "modificadoPor":"${this.currentUser.usuario}",
  }`
  if(this.f.pictureUrl.value != "logocamara.png"){
    this.http.post<any>(`${environment.apiBaseUrl}/api/users/uploadFiles/${this.f.usuario.value.toLowerCase()}/${(this.file.name.split('.'))[(this.file.name.split('.')).length -1]}`,this.formData)
    .subscribe(res=>{this.formData = new FormData;this.file="";},
      error=>{})
  };
  this.http.post(`${environment.apiBaseUrl}/api/users/crear`,infoA,{headers: head}).subscribe(
    (data:any)=>{
      this.notifyService.showSuccess("Usuario creado con exito!","");
      this.router.navigate(['/app/diputados']);
      this.addUser.reset();
    },
    error=>{
      var totalError= " ";
      if(error.error[0]){
        totalError=totalError+error.error[0]+" "
        this.notifyService.showError(error.error[0],"");
      }
      if(error.error[1]){
        totalError=totalError+error.error[1]+" "
        this.notifyService.showError(error.error[1],"");
      }
      if(error.error[2]){
        totalError=totalError+error.error[2]+" "
        this.notifyService.showError(error.error[2],"");
      }
      if(totalError== " "){
        totalError="Hubo un error al crear usuario"
        this.notifyService.showError(totalError,"");
      }
    })

  }
  volver(){
    this.router.navigate(['/app/diputados'])
  }
}
