import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterUs'
})
export class FilterUsPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    if (arg === '') return value;  
    const resultPosts = [];
    for (const post of value) {
      if ( post.usuario.toLowerCase().indexOf(arg.toLowerCase()) > -1 )  {
        resultPosts.push(post);
      }
    };
    return resultPosts;
  }


}