import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlankLayoutCardComponent } from 'app/components/blank-layout-card';
import { ConferenceService } from 'app/services/conference/conference.service.js';
import { environment } from '../../environments/environment';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'app/services';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { User } from 'app/models';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { SignalRService } from 'app/services/signal-r/signal-r.service';
import { MensajeHub } from '../models/mensajeHub';
import { delay } from 'rxjs/operators';


@Component({
  selector: 'app-sesion',
  templateUrl: './sesion.component.html',
  styleUrls: ['./sesion.component.scss']
})
export class SesionComponent extends BlankLayoutCardComponent implements OnInit {
  public xmlItems: any;
  webexForm: FormGroup;
  code:any;
  parametros: any;
  checksum:any;
  checksumJoin:any;
  parametrosJoin:any;
  meetings: any;
  result:any;
  onLive:any;
  urlTogo:any;
  parametrosExit: string;
  checksumExit: string;
  currentUser: User;
  currentUserSubscription: Subscription;
  mostrar:any;
  cantidadPresentes: number=0;
  fecha_sesion:any="";
  nro_periodo:any="";
  nro_sesion:any="";
  cantMinQuorum: number=0;
  quorum:string="";
  private mensajeOBJ: any;
  private httpSI: HttpClient;
  idSala: any;
  pwSala: any;
  error: any;
  haySesion:boolean;
  urlSala: any;
  data:any;

  constructor(private router: Router,
              private authservice: AuthService,
              private http: HttpClient,
              private handler: HttpBackend,
              public confeservice: ConferenceService,
              private fb: FormBuilder,
              public SignalR:SignalRService
              ) {
                super();
                  this.httpSI = new HttpClient(handler);
                  this.currentUserSubscription = this.authservice.currentUser.subscribe(user => {
                    this.currentUser = user;
                    this.webexForm = this.fb.group({
                      idSala: new FormControl('', Validators.required),
                      pwSala: new FormControl('',Validators.required),
                      urlSala: new FormControl('',Validators.required),
                    });
                    this.idSala = this.webexForm.get('idSala');
                    this.pwSala = this.webexForm.get('pwSala');
                    this.urlSala = this.webexForm.get('pwSala');
                });
     }

  ngOnInit(): void {
    this.data=JSON.parse(localStorage.getItem("dataIniciado"))
    var data:any="";
    var webexUrl="";
    if(data.fechaHoraInicia){
      this.haySesion=true;
    }else{
      this.haySesion=false;
    }
    webexUrl=data.webex_url;
    if(this.currentUser.rol == "UADMIN"){
      this.mostrar=true
    };
    if(webexUrl){
      if(webexUrl.includes('https://') || webexUrl.includes('http://')){
        this.urlTogo=webexUrl
      }else{
        this.urlTogo="https://"+webexUrl
      }
    }
    this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/iniciado`).subscribe((data:any)=>{
       if(data){
        if(data.cantPresentes){
          this.cantidadPresentes = data.cantPresentes;
        };
        if(data.fecha_sesion){
        this.fecha_sesion=data.fecha_sesion;}
        if(data.nro_periodo){
        this.nro_periodo=data.nro_periodo;}
        if(data.nro_sesion){
        this.nro_sesion=data.nro_sesion;}
        if(data.cantMinQuorum){
          this.cantMinQuorum=data.cantMinQuorum;}
        if(this.cantidadPresentes>=this.cantMinQuorum){
          this.quorum="HAY QUORUM";
        }else{this.quorum="";}
      }
    })
    this.webexForm.valueChanges.subscribe(() => {
      this.error = null;
    });


    this.SignalR.getVotacion$().subscribe((data:MensajeHub)=>{



      if (data.tipo == MensajeHub.CONST_TIPO_ENTRA_SESION || data.tipo == MensajeHub.CONST_TIPO_SALE_SESION)
      {
        this.mensajeOBJ=JSON.parse(data.mensajeJSON);
        this.cantidadPresentes = this.mensajeOBJ.cantPresentes;
        if(this.cantidadPresentes>=this.cantMinQuorum){
          this.quorum="HAY QUORUM";
        }else{this.quorum="";};
      }
    })
    this.running();
  }

  get f() { return this.webexForm.controls; }

  public onInputChange(event) {
    event.target.required = true;
  }

  checkSesion(){
    this.data=JSON.parse(localStorage.getItem("dataIniciado"))
    if(this.data.fechaHoraInicia != null){
      return true;
    }else{
      return false;
    }
  }

  enviar(){
    var infoA:any;
    var data:any="";
    var id=""
    data=JSON.parse(localStorage.getItem("dataIniciado"))
    id=data.id_sesion
    let head = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    infoA=`{
      "id_sesion":"${id}",
      "fechaHoraInicia": "${data.fechaHoraInicia}",
      "webex_mk":"${this.f.idSala.value}",
      "webex_pw":"${this.f.pwSala.value}",
      "webex_url":"${this.f.urlSala.value}",
      "webex_active":true,
      "sumarioUrl":"${data.sumarioUrl}",
      "ordenDelDiaUrl":"${data.ordenDelDiaUrl}",
      "cantMinQuorum":"${data.cantMinQuorum}",
      "presentes":"${data.presentes}"
  }`
  this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/finalizarInstancia/${id}`).subscribe(
    (data:any)=>{

      this.http.put(`${environment.apiBaseUrl}/api/sesionInstancia/editar`,infoA,{headers: head}).subscribe(
        (data:any)=>{
              this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/iniciado`).subscribe((data:any)=>{
                localStorage.setItem('dataIniciado',JSON.stringify(data))
                localStorage.setItem("presentes",data.cantPresentes.toString());
            })
          })
        })
    this.confeservice.activeOff();
    //window.open(`http://gba-ar.webex.com/gba-ar/m.php?AT=JM&MK=${this.f.idSala.value}&PW=${this.f.pwSala.value}`)
    this.webexForm.reset();
    this.confeservice.activeOn();
  }
  running(){
    var data:any="";
    data=JSON.parse(localStorage.getItem("dataIniciado"))
    if(!data.webex_active){
      this.confeservice.activeOff();
    } else {
      this.confeservice.activeOn();
    }
  }
  JoinConference(){
    window.open(this.urlTogo,"_blank")
  }

  Mandar() {
  }
  ExitConference(){
    var infoA:any;
    var data:any="";
    var id=""
    data=JSON.parse(localStorage.getItem("dataIniciado"))
    id=data.id_sesion
    let head = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    infoA=`{
      "id_sesion":"${id}",
      "fechaHoraInicia": "${data.fechaHoraInicia}",
      "webex_mk":null,
      "webex_pw":null,
      "webex_url":null,
      "webex_active":false,
      "sumarioUrl":"${data.sumarioUrl}",
      "ordenDelDiaUrl":"${data.ordenDelDiaUrl}",
      "cantMinQuorum":"${data.cantMinQuorum}",
      "presentes":"${data.presentes}",
  }`
  this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/finalizarInstancia/${id}`).subscribe(
    (data:any)=>{

      this.http.put(`${environment.apiBaseUrl}/api/sesionInstancia/editar`,infoA,{headers: head}).subscribe(
        (data:any)=>{


              this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/iniciado`).subscribe((data:any)=>{
                localStorage.setItem('dataIniciado',JSON.stringify(data))
                localStorage.setItem("presentes",data.cantPresentes.toString());

            })
          })
        })

    this.confeservice.activeOff();
  }
}

