export class MensajeHub {
    public static readonly CONST_TIPO_INICIAR_VOTO = "IV";
    public static readonly CONST_TIPO_FINALIZAR_VOTO = "FV";
    public static readonly CONST_TIPO_ANULAR_VOTO = "AV";

    public static readonly CONST_TIPO_VOTO_NEGATIVO = "VN";
    public static readonly CONST_TIPO_VOTO_POSITIVO = "VP";
    public static readonly CONST_TIPO_VOTO_ABSTENCION = "VA";
    public static readonly CONST_TIPO_VOTO_HEMICICLO = "VH";
    public static readonly CONST_TIPO_CERRAR_VOTO_HEMICICLO = "XH";

    public static readonly CONST_TIPO_ENTRA_SESION = "ES";
    public static readonly CONST_TIPO_SALE_SESION = "SS";

    public static readonly CONST_TIPO_ENTRA_REUNION = "ER";
    public static readonly CONST_TIPO_SALE_REUNION = "SR";

    public static readonly CONST_TIPO_VOTO_REUNION_POSITIVO = "RP";
    public static readonly CONST_TIPO_VOTO_REUNION_NEGATIVO = "RN";

    public static readonly CONST_TIPO_INICIAR_VOTO_REUNION = "IR";
    public static readonly CONST_TIPO_FINALIZAR_VOTO_REUNION = "FR";
    public static readonly CONST_TIPO_ANULAR_VOTO_REUNION = "AR";

    public static readonly CONST_TIPO_ORADORES_UPDATE = "O_UPDATE";

    public static readonly  ARCHIVO = "ARCH";

    

    tipo: string;
    mensajeJSON: string;
}
