import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BlankLayoutCardComponent } from 'app/components/blank-layout-card';
import { environment } from 'environments/environment';
import { NotificationService } from 'app/services/notification/notification.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SesionInstancia } from '../models/sesion';
import {DatePipe} from '@angular/common';
import * as jsPDF from 'jspdf'
import { PdfMakeWrapper, Img, Table, Columns, Txt, Cell, Ul } from 'pdfmake-wrapper';
import pdfFonts from "pdfmake/build/vfs_fonts";

//providers: [DatePipe]
@Component({
  selector: 'app-sesion-admin',
  templateUrl: './sesion-admin.component.html',
  styleUrls: ['./sesion-admin.component.css']
})
export class SesionAdminComponent extends BlankLayoutCardComponent implements OnInit {
  @ViewChild('htmlData') htmlData:ElementRef;

  [x: string]: any;
  public sesion: SesionInstancia;
  public sesionForm: FormGroup;
  filtroSesion:any="";
  p:any;
  hayArchivo:boolean=false;
  sesiones:any[]=[];
  formData= new FormData;
  checkDetalle:boolean = false;
  checkModificar:boolean = false;
  // sesion:any="";
  error: any;
  diputadosReporte:any="";
  constructor(private router: Router,
              private http: HttpClient,
              private fb: FormBuilder,
              private notifyService : NotificationService,
              public datepipe: DatePipe,
              private elementRef: ElementRef) {
      super();
      this.sesion = new SesionInstancia();
      this.sesionForm = this.fb.group({
        id_sesion: new FormControl(''),
        fechaHoraInicia: new FormControl(''),
        fechaHoraFinaliza: new FormControl(''),
        webex_mk: new FormControl(''),
        webex_pw: new FormControl(''),
        webex_url: new FormControl(''),
        webex_active: new FormControl(''),
        sumarioUrl: new FormControl(null),
        ordenDelDiaUrl: new FormControl(null),
        cantMinQuorum: new FormControl('')
      });
    }

  public onInputChange(event) {
    event.target.required = true;
  }
  get f() { return this.sesionForm.controls; }

  ngOnInit(): void {
    this.sesionForm.valueChanges.subscribe(() => {
      this.error = null;
    });
    this.loadSesiones();
  }

  iniciar(id){
    this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/inicializarInstancia/${id}`).subscribe(
      (data:any)=>{
        this.loadSesiones();
      },
      error=>{
        this.notifyService.showError(error.error,"");
      });
  }

  finalizar(id){
    this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/finalizarInstancia/${id}`).subscribe(
      (data:any)=>{
        this.loadSesiones();
      },
      error=>{
        this.notifyService.showError(error.error,"");
      });
  }

  renaudar(id){
    this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/reanudarInstancia/${id}`).subscribe(
      (data:any)=>{
        this.loadSesiones();
      },
      error=>{
        this.notifyService.showError(error.error,"");
      });
  }

  modificar(id){
    this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/${id}`).subscribe(
      (data:any)=>{
        this.sesion = new SesionInstancia(data);
        this.checkDetalle=false;
        this.checkModificar=true;
        this.sesionForm.setValue({
          id_sesion: this.sesion.id_sesion,
          fechaHoraInicia: this.sesion.fechaHoraInicia,
          fechaHoraFinaliza: this.sesion.fechaHoraFinaliza,
          webex_mk: this.sesion.webex_mk,
          webex_pw: this.sesion.webex_pw,
          webex_url: this.sesion.webex_url,
          webex_active: this.sesion.webex_active,
          sumarioUrl: this.sesion.sumarioUrl,
          ordenDelDiaUrl: this.sesion.ordenDelDiaUrl,
          cantMinQuorum: this.sesion.cantMinQuorum
        });
        this.elementRef.nativeElement.querySelector('#nombre-arch-sumario').innerHTML = this.sesion.sumarioUrl? this.sesion.sumarioUrl: "Ningún archivo seleccionado.";
        this.elementRef.nativeElement.querySelector('#nombre-arch-orden').innerHTML = this.sesion.ordenDelDiaUrl? this.sesion.ordenDelDiaUrl: "Ningún archivo seleccionado.";
      });
  }

  iniciada(id){
    var data:any;
    data=JSON.parse(localStorage.getItem("dataIniciado"))
    if(id==data.id_sesion){
      return "Actualmente sesionando"
    }else{
      return ""
    }
  }
  onFileSelectedOrden(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const modifiedFile = this.cambiarNombre(file,"ordenDelDia");
      this.sesionForm.get('ordenDelDiaUrl').setValue(modifiedFile.name);
      this.formData.append('ordenFile', modifiedFile);
      this.sesion.ordenDelDiaUrl = modifiedFile.name;
      this.elementRef.nativeElement.querySelector('#nombre-arch-orden').innerHTML = file.name;
    }
  }

  onFileSelectedSumario(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const modifiedFile = this.cambiarNombre(file,"sobreTablas");
      this.sesionForm.get('sumarioUrl').setValue(modifiedFile.name);
      this.formData.append('sumarioFile', modifiedFile);
      this.sesion.sumarioUrl = modifiedFile.name;
      this.elementRef.nativeElement.querySelector('#nombre-arch-sumario').innerHTML = file.name;
    }
  }

  quitarSumario(event) {
    event.preventDefault();
    this.sesionForm.get('sumarioUrl').setValue(null); // Limpiar el valor en el formulario
    this.sesion.sumarioUrl = null;
    if (this.formData.has('sumarioFile')){
      this.formData.delete('sumarioFile'); // Quitar el archivo del formData
    }
    this.elementRef.nativeElement.querySelector('#sumarioUrl').value = null;
    this.elementRef.nativeElement.querySelector('#nombre-arch-sumario').innerHTML = 'Ningún archivo seleccionado.';
  }

  quitarOrden(event) {
    event.preventDefault();
    this.sesionForm.get('ordenDelDiaUrl').setValue(null);
    this.sesion.ordenDelDiaUrl = null;
    if (this.formData.has('ordenFile')){
      this.formData.delete('ordenFile');
    }
    this.elementRef.nativeElement.querySelector('#ordenDelDiaUrl').value = null;
    this.elementRef.nativeElement.querySelector('#nombre-arch-orden').innerHTML = 'Ningún archivo seleccionado.';
  }

  elegirSumario(event){
    event.preventDefault();
    this.elementRef.nativeElement.querySelector('#sumarioUrl').click();
  }

  elegirOrden(event){
    event.preventDefault();
    this.elementRef.nativeElement.querySelector('#ordenDelDiaUrl').click();
  }

  private cambiarNombre(file: File, tipo: string) {
    let fileExtension = file.name.split('.').pop();
    let modifiedFileName = `${tipo}_${this.sesionForm.value.id_sesion}.${fileExtension}`;
    const modifiedFile = new File([file], modifiedFileName);
    return modifiedFile;
  }

  private uploadFile(tipo:string){
    const url = `${environment.apiBaseUrl}/api/sesionInstancia/uploadFile`;
    const file = this.formData.get(`${tipo}`) as File;
    const formData = new FormData();
    formData.append(`${tipo}`, file);
    this.http.post<any>(url, formData).subscribe(() => {},error => {});
  }

  detalle(id){
    this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/${id}`).subscribe(
      (data:any)=>{

        this.sesion = new SesionInstancia(data);
        // this.sesion=data;
        this.checkDetalle=true;
        this.checkModificar=false;
      });
  }

  grabar(){
    if (this.formData.has('ordenFile')){
      this.uploadFile("ordenFile");
    }
    if (this.formData.has('sumarioFile')){
      this.uploadFile("sumarioFile");
    }

    let head = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.http.put(`${environment.apiBaseUrl}/api/sesionInstancia/editar`,this.sesionForm.value,{headers: head}).subscribe(
    (data:any)=>{

        this.notifyService.showSuccess("Sesion actualizada con exito!","");
        //this.addUser.reset()
        this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/${this.sesion.id_sesion}`).subscribe(
          (data:any)=>{

            this.sesion = new SesionInstancia(data);
            // this.sesion=data;
            this.loadSesiones();
            this.checkDetalle=true;
            this.checkModificar=false;
          })
      },error=>{
        this.notifyService.showError("Hubo un problema al editar la sesion!","");
      }
    )
  }

  loadSesiones(){
    this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/all`).subscribe(
      (data:any)=>{
        this.sesiones=data;
      });
      this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/iniciado`).subscribe((data:any)=>{
        localStorage.setItem('dataIniciado',JSON.stringify(data))

        localStorage.setItem("presentes",data.cantPresentes.toString());
      });
  }

  parsearFechaHora(datetime :Date){
    var somaDateVar:any;
    somaDateVar = this.datepipe.transform(datetime, 'dd/MM/yyyy HH:mm');
    if(datetime){
      return somaDateVar;
    }else{
      return "-"
    }
  }

  traductorDeBoolean(bool){
    if(bool){
      return "SI"
    }else{
      return "NO"
    }
  }
  agregar(){
    this.router.navigate(['/app/agregar-sesion']);
  }
  async reporte2(id){
    PdfMakeWrapper.setFonts(pdfFonts);
    const pdf = new PdfMakeWrapper();
    var datetime= new Date();
    var somaDateVar:any;
    var fecha_sesion="";
    var nro_periodo="";
    var nro_sesion="";
    var presentes= [];
    var ausentes=[]
    pdf.pageSize('A4');
    somaDateVar = this.datepipe.transform(datetime, 'dd/MM/yyyy');
    new Img("../../assets/logosSesionar/escudo_colorNT.jpg").fit([125,100]).alignment('center').build().then( img => {
      pdf.header( img );
    });
    new Img("../../assets/logosSesionar/logosParaPdf.jpeg").fit([480,35]).alignment('center').build().then( img => {
      pdf.footer( img );
      pdf.pageMargins([ 140, 80, 60, 40 ]);


      this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/reporte_usuarios/${id}`).subscribe(
        (data:any)=>{
          this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/reporte_sesion/${id}`).subscribe(
            (data2:any)=>{
                if(data2){
                  fecha_sesion=data2.fecha_sesion;
                  nro_periodo=data2.nro_periodo;
                  nro_sesion=data2.nro_sesion;
                }

            this.diputadosReporte=data;

            pdf.add(new Txt('La Plata, '+somaDateVar+'.-').alignment('right').end)
            pdf.add(new Txt(' ').alignment('center').end)
            pdf.add(new Txt('Por medio de la presente se detallan los diputados y diputadas').alignment('right').end)
            pdf.add(new Txt(' ').alignment('center').end)
            pdf.add(new Txt('presentes y ausentes de un total de 92 diputados, correspondientes a: ').alignment('left').end)
            pdf.add(new Txt(' ').alignment('center').end)
            pdf.add(new Txt("Período Legislativo: "+nro_periodo+"  Sesión Ordinaria Nro.: "+nro_sesion).alignment('left').bold().end)
            pdf.add(new Txt(' ').alignment('center').end)
            pdf.add(new Txt("Fecha: "+fecha_sesion).alignment('left').bold().end)
            pdf.add(new Txt(' ').alignment('center').end)
            pdf.add(new Txt(`Total Presentes: ${data.presentes.length}`).alignment('left').bold().end)
            pdf.add(new Txt(' ').alignment('center').end)

            if(data.presentes.length>0){
              presentes.push([new Txt('Nombre y Apellido').fontSize(14).italics().bold().end,new Txt('Banca').fontSize(14).italics().bold().end,new Txt('Bloque').fontSize(14).italics().bold().end])
              for (let elemento of data.presentes){
                presentes.push([elemento.apellido+" "+elemento.nombre,elemento.banca,elemento.bloqueNombre]);
              }
              pdf.add(new Table(presentes).end)
              pdf.add(new Txt(' ').alignment('center').end)
            }
            if(data.ausentes.length>0){

              pdf.add(new Txt("Así mismo, se informa que los diputados ausentes fueron: ").alignment('center').end)
              pdf.add(new Txt(' ').alignment('center').end)
              pdf.add(new Txt(`Total Ausentes: ${data.ausentes.length}`).alignment('left').bold().end)
              pdf.add(new Txt(' ').alignment('center').end)
              ausentes.push([new Txt('Nombre y Apellido').fontSize(14).italics().bold().end,new Txt('Banca').fontSize(14).italics().bold().end,new Txt('Bloque').fontSize(14).italics().bold().end])
              for (let elemento of data.ausentes){
                ausentes.push([elemento.apellido+" "+elemento.nombre,elemento.banca,elemento.bloqueNombre]);
              }
              pdf.add(new Table(ausentes).end)

            }


            pdf.add(new Txt(' ').alignment('center').end)
            pdf.add(new Txt("Sin otro particular, saluda a Ud. muy atte.").alignment('center').end)
            pdf.create().download(`reporte_de_sesion_${id}`);
          })
        })
      });



      /*
        let doc = new jsPDF('p','pt', 'a4');
        let handleElement = {
          '#editor':function(element,renderer){
            return true;
          }
          };
        let DATA = this.htmlData.nativeElement;

        setTimeout(() => {
            doc.fromHTML(DATA.innerHTML,55,0,{
              'width': 0,
              'elementHandlers': handleElement
            },function (dispose) {
              doc.save('pruebaPDF.pdf');
              })
          }, 1000);
      */

  }
  reporte(idSesion){
    var datetime= new Date();
    var somaDateVar:any;
    somaDateVar = this.datepipe.transform(datetime, 'dd/MM/yyyy');
    var cadena:any="";
    var fecha_sesion="";
    var nro_periodo="";
    var nro_sesion="";
    var doc = new jsPDF()
    var img = new Image()
    var img2 = new Image();
    var img3 = new Image();
    var posHorCant = 50
    var posHorNombre = 55
    var posHorBanca = 130
    var posHorBloque = 145
    var posVer = 80
    var cant = 1
    this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/reporte_sesion/${idSesion}`).subscribe(
      (data:any)=>{
        if(data){
          fecha_sesion=data.fecha_sesion;
          nro_periodo=data.nro_periodo;
          nro_sesion=data.nro_sesion;
        }
      })
    img.src="../../assets/logosSesionar/logosParaPdf.jpeg";
    //img2.src="../../assets/logosSesionar/honorableCamDip.jpeg";
    img2.src="../../assets/logosSesionar/escudo_color.png";

    doc.setFontSize(12);
    doc.setFontStyle('arial');
    doc.setFontSize(20);
    //doc.text(`Presentes en la Sesion ${idSesion}:`,5,45)
    doc.setFontSize(11);

    this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/reporte_usuarios/${idSesion}`).subscribe((data:any)=>{
      doc.addImage(img, "JPEG",25,283, 165, 15);
      doc.addImage(img2, "JPEG",75,0,55, 35);
      doc.text(`La Plata, ${somaDateVar}.-`,150,40)
      doc.text("Por medio de la presente se detallan los diputados y diputadas",80,45)
      doc.text("presentes correspondientes a: ",55,50)
      doc.setFontType("bold");
      doc.text("Período Legislativo: "+nro_periodo+"  Sesión Ordinaria Nro.: "+nro_sesion,55,60)
      doc.setFontSize(12);
      if(data.presentes.length>0){
        doc.text("Nombre",posHorNombre,75)
        doc.text("Banca",posHorBanca,75)
        doc.text("Bloque",posHorBloque,75)
      }else{
        doc.setFontSize(9);
        doc.setFontType("");
        doc.text("No hubo presentes en la sesion.", 55,72)
      }
      doc.setFontSize(11);
      doc.setFontType("bold");
      doc.text("Fecha: "+fecha_sesion+`  Total Presentes: ${data.presentes.length}`, 55,67)
      doc.setFontSize(9);
      doc.setFontType("");

      if(data.presentes.length < 50){
        for (let elemento of data.presentes){
            doc.text(`${cant}. `,posHorCant,posVer)
            if(elemento.banca != 0){
              doc.text(elemento.apellido+" "+elemento.nombre,posHorNombre,posVer)
              doc.text(`${elemento.banca}`,posHorBanca,posVer)
              doc.text(elemento.bloqueNombre,posHorBloque,posVer)
              //cadena=cadena+elemento.apellido+" "+elemento.nombre+"                                                                                      "+elemento.banca.id+"                                                       "+elemento.bloqueNombre+"\n"+"\n"
            }else{
              doc.text(elemento.apellido+" "+elemento.nombre,posHorNombre,posVer)
              doc.text("Sin Banca",posHorBanca,posVer)
              doc.text(elemento.bloqueNombre,posHorBloque,posVer)
              //cadena=cadena+elemento.apellido+" "+elemento.nombre+"                                                                           "+"Sin Banca"+"                                             "+elemento.bloqueNombre+"\n"+"\n"
            }
            posVer=posVer+4
            cant=cant+1
          }
        if(data.ausentes.length > 0){
          cant = 1;
          posVer=posVer+4
          doc.text(`Así mismo, se informa que los diputados ausentes fueron: `, 75,posVer)
          posVer=posVer+8
          doc.setFontSize(10);
          doc.setFontType("bold");
          doc.text(`Total Ausentes: ${data.ausentes.length}`, 55,posVer)
          doc.setFontSize(12);
          posVer=posVer+6
          doc.text("Nombre",posHorNombre,posVer)
          doc.text("Banca",posHorBanca,posVer)
          doc.text("Bloque",posHorBloque,posVer)
          doc.setFontSize(9);
          doc.setFontType("");
          posVer=posVer+4
          if(data.ausentes.length < 50){
            for (let elemento of data.ausentes){
              if(posVer<277){
                doc.text(`${cant}. `,posHorCant,posVer)
                if(elemento.banca != 0){
                  doc.text(elemento.apellido+" "+elemento.nombre,posHorNombre,posVer)
                  doc.text(`${elemento.banca}`,posHorBanca,posVer)
                  doc.text(elemento.bloqueNombre,posHorBloque,posVer)
                  //cadena=cadena+elemento.apellido+" "+elemento.nombre+"                                                                                      "+elemento.banca.id+"                                                       "+elemento.bloqueNombre+"\n"+"\n"
                }else{
                  doc.text(elemento.apellido+" "+elemento.nombre,posHorNombre,posVer)
                  doc.text("Sin Banca",posHorBanca,posVer)
                  doc.text(elemento.bloqueNombre,posHorBloque,posVer)
                  //cadena=cadena+elemento.apellido+" "+elemento.nombre+"                                                                           "+"Sin Banca"+"                                             "+elemento.bloqueNombre+"\n"+"\n"
                }
                posVer=posVer+10
                cant=cant+1
              }
            }

          }else{
            for (let i = cant-1; i < 50; i++){
              if(posVer<277){
              doc.text(`${cant}. `,posHorCant,posVer)
              if(data.ausentes[i].banca != 0){
                doc.text(data.ausentes[i].apellido+" "+data.ausentes[i].nombre,posHorNombre,posVer)
                doc.text(`${data.ausentes[i].banca}`,posHorBanca,posVer)
                doc.text(data.ausentes[i].bloqueNombre,posHorBloque,posVer)
                //cadena=cadena+elemento.apellido+" "+elemento.nombre+"                                                                                      "+elemento.banca.id+"                                                       "+elemento.bloqueNombre+"\n"+"\n"
              }else{
                doc.text(data.ausentes[i].apellido+" "+data.ausentes[i].nombre,posHorNombre,posVer)
                doc.text("Sin Banca",posHorBanca,posVer)
                doc.text(data.ausentes[i].bloqueNombre,posHorBloque,posVer)
                //cadena=cadena+elemento.apellido+" "+elemento.nombre+"                                                                           "+"Sin Banca"+"                                             "+elemento.bloqueNombre+"\n"+"\n"
              }
              posVer=posVer+4
              cant=cant+1
            }
            }

            doc.addPage()
            posVer=40;
            doc.addImage(img, "JPEG",25,283, 165, 15);
            doc.addImage(img2, "JPEG",75,0,55, 35);

            doc.setFontSize(9);
            doc.setFontType("");
            var total=data.ausentes.length
            for (let i = cant-1; i < total; i++){
              doc.text(`${cant}. `,posHorCant,posVer)
              if(data.ausentes[i].banca != 0){
                doc.text(data.ausentes[i].apellido+" "+data.ausentes[i].nombre,posHorNombre,posVer)
                doc.text(`${data.ausentes[i].banca}`,posHorBanca,posVer)
                doc.text(data.ausentes[i].bloqueNombre,posHorBloque,posVer)
                //cadena=cadena+elemento.apellido+" "+elemento.nombre+"                                                                                      "+elemento.banca.id+"                                                       "+elemento.bloqueNombre+"\n"+"\n"
              }else{
                doc.text(data.ausentes[i].apellido+" "+data.ausentes[i].nombre,posHorNombre,posVer)
                doc.text("Sin Banca",posHorBanca,posVer)
                doc.text(data.ausentes[i].bloqueNombre,posHorBloque,posVer)
                //cadena=cadena+elemento.apellido+" "+elemento.nombre+"                                                                           "+"Sin Banca"+"                                             "+elemento.bloqueNombre+"\n"+"\n"
              }
              posVer=posVer+4
              cant=cant+1
            }

          }

        }

      }else{
        for (let i = cant-1; i < 50; i++){
          doc.text(`${cant}. `,posHorCant,posVer)
          if(data.presentes[i].banca != 0){
            doc.text(data.presentes[i].apellido+" "+data.presentes[i].nombre,posHorNombre,posVer)
            doc.text(`${data.presentes[i].banca}`,posHorBanca,posVer)
            doc.text(data.presentes[i].bloqueNombre,posHorBloque,posVer)
            //cadena=cadena+elemento.apellido+" "+elemento.nombre+"                                                                                      "+elemento.banca.id+"                                                       "+elemento.bloqueNombre+"\n"+"\n"
          }else{
            doc.text(data.presentes[i].apellido+" "+data.presentes[i].nombre,posHorNombre,posVer)
            doc.text("Sin Banca",posHorBanca,posVer)
            doc.text(data.presentes[i].bloqueNombre,posHorBloque,posVer)
            //cadena=cadena+elemento.apellido+" "+elemento.nombre+"                                                                           "+"Sin Banca"+"                                             "+elemento.bloqueNombre+"\n"+"\n"
          }
          posVer=posVer+4
          cant=cant+1
        }

        doc.addPage()
        posVer=40;
        doc.addImage(img, "JPEG",25,283, 165, 15);
        doc.addImage(img2, "JPEG",75,0,55, 35);

        doc.setFontSize(9);
        doc.setFontType("");
        var total=data.presentes.length
        for (let i = cant-1; i < total; i++){
          doc.text(`${cant}. `,posHorCant,posVer)
          if(data.presentes[i].banca != 0){
            doc.text(data.presentes[i].apellido+" "+data.presentes[i].nombre,posHorNombre,posVer)
            doc.text(`${data.presentes[i].banca}`,posHorBanca,posVer)
            doc.text(data.presentes[i].bloqueNombre,posHorBloque,posVer)
            //cadena=cadena+elemento.apellido+" "+elemento.nombre+"                                                                                      "+elemento.banca.id+"                                                       "+elemento.bloqueNombre+"\n"+"\n"
          }else{
            doc.text(data.presentes[i].apellido+" "+data.presentes[i].nombre,posHorNombre,posVer)
            doc.text("Sin Banca",posHorBanca,posVer)
            doc.text(data.presentes[i].bloqueNombre,posHorBloque,posVer)
            //cadena=cadena+elemento.apellido+" "+elemento.nombre+"                                                                           "+"Sin Banca"+"                                             "+elemento.bloqueNombre+"\n"+"\n"
          }
          posVer=posVer+4
          cant=cant+1
        }
        if(data.ausentes.length > 0){
          cant = 1;
          posVer=posVer+4
          doc.text(`Así mismo, se informa que los diputados ausentes fueron: `, 75,posVer)
          posVer=posVer+8
          doc.setFontSize(10);
          doc.setFontType("bold");
          doc.text(`Total Ausentes: ${data.ausentes.length}`, 55,posVer)
          doc.setFontSize(12);
          posVer=posVer+6
          doc.text("Nombre",posHorNombre,posVer)
          doc.text("Banca",posHorBanca,posVer)
          doc.text("Bloque",posHorBloque,posVer)
          doc.setFontSize(9);
          doc.setFontType("");
          posVer=posVer+4
          if(data.ausentes.length < 50){
            for (let elemento of data.ausentes){
              if(posVer<277){
                doc.text(`${cant}. `,posHorCant,posVer)
                if(elemento.banca != 0){
                  doc.text(elemento.apellido+" "+elemento.nombre,posHorNombre,posVer)
                  doc.text(`${elemento.banca}`,posHorBanca,posVer)
                  doc.text(elemento.bloqueNombre,posHorBloque,posVer)
                  //cadena=cadena+elemento.apellido+" "+elemento.nombre+"                                                                                      "+elemento.banca.id+"                                                       "+elemento.bloqueNombre+"\n"+"\n"
                }else{
                  doc.text(elemento.apellido+" "+elemento.nombre,posHorNombre,posVer)
                  doc.text("Sin Banca",posHorBanca,posVer)
                  doc.text(elemento.bloqueNombre,posHorBloque,posVer)
                  //cadena=cadena+elemento.apellido+" "+elemento.nombre+"                                                                           "+"Sin Banca"+"                                             "+elemento.bloqueNombre+"\n"+"\n"
                }
                posVer=posVer+10
                cant=cant+1
              }
            }

          }else{
            for (let i = cant-1; i < 50; i++){
              if(posVer<277){
              doc.text(`${cant}. `,posHorCant,posVer)
              if(data.ausentes[i].banca != 0){
                doc.text(data.ausentes[i].apellido+" "+data.ausentes[i].nombre,posHorNombre,posVer)
                doc.text(`${data.ausentes[i].banca}`,posHorBanca,posVer)
                doc.text(data.ausentes[i].bloqueNombre,posHorBloque,posVer)
                //cadena=cadena+elemento.apellido+" "+elemento.nombre+"                                                                                      "+elemento.banca.id+"                                                       "+elemento.bloqueNombre+"\n"+"\n"
              }else{
                doc.text(data.ausentes[i].apellido+" "+data.ausentes[i].nombre,posHorNombre,posVer)
                doc.text("Sin Banca",posHorBanca,posVer)
                doc.text(data.ausentes[i].bloqueNombre,posHorBloque,posVer)
                //cadena=cadena+elemento.apellido+" "+elemento.nombre+"                                                                           "+"Sin Banca"+"                                             "+elemento.bloqueNombre+"\n"+"\n"
              }
              posVer=posVer+4
              cant=cant+1
            }
            }

            doc.addPage()
            posVer=40;
            doc.addImage(img, "JPEG",25,283, 165, 15);
            doc.addImage(img2, "JPEG",75,0,55, 35);

            doc.setFontSize(9);
            doc.setFontType("");
            var total=data.ausentes.length
            for (let i = cant-1; i < total; i++){
              doc.text(`${cant}. `,posHorCant,posVer)
              if(data.ausentes[i].banca != 0){
                doc.text(data.ausentes[i].apellido+" "+data.ausentes[i].nombre,posHorNombre,posVer)
                doc.text(`${data.ausentes[i].banca}`,posHorBanca,posVer)
                doc.text(data.ausentes[i].bloqueNombre,posHorBloque,posVer)
                //cadena=cadena+elemento.apellido+" "+elemento.nombre+"                                                                                      "+elemento.banca.id+"                                                       "+elemento.bloqueNombre+"\n"+"\n"
              }else{
                doc.text(data.ausentes[i].apellido+" "+data.ausentes[i].nombre,posHorNombre,posVer)
                doc.text("Sin Banca",posHorBanca,posVer)
                doc.text(data.ausentes[i].bloqueNombre,posHorBloque,posVer)
                //cadena=cadena+elemento.apellido+" "+elemento.nombre+"                                                                           "+"Sin Banca"+"                                             "+elemento.bloqueNombre+"\n"+"\n"
              }
              posVer=posVer+4
              cant=cant+1
            }

          }

        }

      }
      posVer=posVer+6
      doc.text("Sin otro particular, saluda a Ud. muy atte.", 75,posVer)
      doc.save(`reporte_de_sesion_${idSesion}.pdf`)
      })
  }
}
