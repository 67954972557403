import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { SignalRService } from 'app/services/signal-r/signal-r.service';
import { MensajeHub } from '../models/mensajeHub';
import * as jsPDF from 'jspdf'

@Component({
  selector: 'app-sumario',
  templateUrl: './sumario.component.html',
  styleUrls: ['./sumario.component.css']
})
export class SumarioComponent implements OnInit {
  constructor(private http: HttpClient,
              public SignalR: SignalRService) {}
  sumarioURL: string;
  ordenDelDiaURL: string;
  fecha_sesion: string;
  nro_sesion: string;
  nro_periodo: string;
  idSesion:any;
  data:any="";

  ngOnInit(): void {
    // Revisar este if. Es necesario? Cuál es su razón de ser? No podemos solo quedarnos con  el código del else?
    /* if(localStorage.getItem("dataIniciado")){
      this.data=JSON.parse(localStorage.getItem("dataIniciado"))
      this.idSesion=this.data.id_sesion
        this.sumarioURL=this.data.sumarioUrl;
        this.ordenDelDiaURL=this.data.ordenDelDia;
        if(this.data.fecha_sesion){
          this.fecha_sesion=this.data.fecha_sesion;
          }
        if(this.data.nro_periodo){
          this.nro_periodo=this.data.nro_periodo;
          }
        if(this.data.nro_sesion){
          this.nro_sesion=this.data.nro_sesion;
          }
    }else{ */
      this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/iniciado`).subscribe((data:any)=>{
        if(data){
          this.idSesion=data.id_sesion
          this.sumarioURL=data.sumarioUrl;
          this.ordenDelDiaURL=data.ordenDelDiaUrl;
        }
      });
    /* } */

    this.SignalR.getArchivo$().subscribe((data: MensajeHub) => {
      if (data.tipo == MensajeHub.ARCHIVO) {
        let arch = JSON.parse(data.mensajeJSON);
        if (arch.Tipo == "sumarioFile"){
          this.sumarioURL = arch.Nombre;
        }else if (arch.Tipo == "ordenFile"){
          this.ordenDelDiaURL = arch.Nombre;
        }else if (arch.Tipo == "eliminarSumario"){
          this.sumarioURL = null;
        }else if (arch.Tipo == "eliminarOrden"){
          this.ordenDelDiaURL = null;
        };
      }
    })

    this.SignalR.startListeningToArchivos();
  }
  reporte(){
    var cadena:any="";
    var doc = new jsPDF()
    var img = new Image()
    var img2 = new Image();
    var img3 = new Image();
    var posHorCant = 5
    var posHorNombre = 10
    var posHorBanca = 85
    var posHorBloque = 145
    var posVer = 85
    var cant = 1

    img.src="../../assets/logosSesionar/logosParaPdf.jpeg";
    img2.src="../../assets/logosSesionar/honorableCamDip.jpeg";
    doc.addImage(img, "JPEG",25,283, 165, 15);
    doc.addImage(img2, "JPEG",85,0,40, 35);
    doc.setFontSize(12);
    doc.setFontStyle('italic');
    doc.setFontType("bold");
    this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/reporte_usuarios/${this.idSesion}`).subscribe((data:any)=>{
        doc.setFontSize(20);
        doc.text(`Presentes en la Sesion ${this.idSesion}:`,5,45)
        doc.setFontSize(10);
        doc.text("Fecha de Sesion: "+this.fecha_sesion+"\n"+"Numero de Periodo: "+this.nro_periodo+"\n"+"Numero de Sesion: "+this.nro_sesion,10,55)
        doc.setFontSize(12);
        doc.text("Nombre                                                 Banca                                             Bloque",10,75)
      doc.setFontSize(8);
      doc.setFontType("");
      for (let elemento of data){
        doc.text(`${cant}. `,posHorCant,posVer)
        if(elemento.banca != 0){
          doc.text(elemento.apellido+" "+elemento.nombre,posHorNombre,posVer)
          doc.text(`${elemento.banca}`,posHorBanca,posVer)
          doc.text(elemento.bloqueNombre,posHorBloque,posVer)
          //cadena=cadena+elemento.apellido+" "+elemento.nombre+"                                                                                      "+elemento.banca.id+"                                                       "+elemento.bloqueNombre+"\n"+"\n"
        }else{
          doc.text(elemento.apellido+" "+elemento.nombre,posHorNombre,posVer)
          doc.text("Sin Banca",posHorBanca,posVer)
          doc.text(elemento.bloqueNombre,posHorBloque,posVer)
          //cadena=cadena+elemento.apellido+" "+elemento.nombre+"                                                                           "+"Sin Banca"+"                                             "+elemento.bloqueNombre+"\n"+"\n"
        }
        posVer=posVer+3
        cant=cant+1
        
      }
      doc.setFontSize(10);
      doc.setFontType("bold");
      doc.text(`Cantidad de diputados presentes: ${cant - 1}`, 10,280)
      doc.save(`reporte_de_sesion_${this.idSesion}.pdf`)
      })
  }

  descargarArchivo(nombreArchivo: string) {
    const url = `${environment.apiBaseUrl}/api/archivo/descargar-archivo/${nombreArchivo}`;

    this.http.get(url, { responseType: 'arraybuffer' }).subscribe(response => {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = nombreArchivo;
      link.click();
    });
  }

//WORKINGHERE falta validar que el archivo exista dentro
  /* existeSumario(){
    var temp = "assets/descargas/"+this.sumarioURL
    if(this.sumarioURL!=null && (temp) ) {
      return true
    }
    else{
      return false
    }
  }

  existeOrden(){
    var temp = "assets/descargas/"+this.sumarioURL
    if (this.ordenDelDia!=null && (temp) ) {
      return true
    }
    else{
      return false
    }

  } */


  




}
