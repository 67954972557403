export class SesionInstancia {
  public id_sesion: string;
  public fechaHoraInicia?: Date;
  public fechaHoraFinaliza?: Date;
  public webex_mk: string;
  public webex_pw: string;
  public webex_url: string;
  public webex_active: boolean;
  public sumarioUrl: string;
  public ordenDelDiaUrl: string;
  public cantMinQuorum: string;

  public constructor(data?: any) {
      this.id_sesion = (data && data.id_sesion ? data.id_sesion : null);
      this.fechaHoraInicia = (data && data.fechaHoraInicia ? data.fechaHoraInicia : null);
      this.fechaHoraFinaliza = (data && data.fechaHoraFinaliza ? data.fechaHoraFinaliza : null);
      this.webex_mk = (data && data.webex_mk ? data.webex_mk : null);
      this.webex_pw = (data && data.webex_pw ? data.webex_pw : null);
      this.webex_url = (data && data.webex_url ? data.webex_url : null);
      this.webex_active = (data && data.webex_active ? data.webex_active : false);
      this.sumarioUrl = (data && data.sumarioUrl ? data.sumarioUrl : null);
      this.ordenDelDiaUrl = (data && data.ordenDelDiaUrl ? data.ordenDelDiaUrl : null);
      this.cantMinQuorum = (data && data.cantMinQuorum ? data.cantMinQuorum : 0);
  }
}
