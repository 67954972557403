import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';

import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { NotificationService } from 'app/services/notification/notification.service';
import { BlankLayoutCardComponent } from 'app/components/blank-layout-card';
import { UpgradableComponent } from 'theme/components/upgradable';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'app/services';
import { Subscription } from 'rxjs';
import { User } from 'app/models';
import { PdfMakeWrapper, Img, Table, Columns, Txt, Cell, Ul } from 'pdfmake-wrapper';
import { PdfService } from 'app/services/pdf.service';
import vfs_fonts from 'pdfmake/build/vfs_fonts';

@Component({
  selector: 'app-diputados',
  templateUrl: './diputados.component.html',
  host: { class: 'blank-layout-card mdl-grid ui-components' },
  styleUrls: ['./diputados.component.scss'],
})
export class DiputadosComponent extends BlankLayoutCardComponent implements OnInit {
  filtroUsuario: any = '';
  item: any;
  public diputados: any[] = [];
  public bancas: any[] = [];
  public bloques: any[] = [];
  public roles: any[] = [];
  bancaS: any;
  bloqueS: any;
  file: any = '';
  formData = new FormData;
  href: any;
  p: any;
  botonPin = false;
  varTrue = true;
  public addUser: FormGroup;
  checkDetalle = false;
  usuario: any;
  varpresi: boolean;
  varrenaper: boolean;
  nombre: any;
  apellido: any;
  contrasenia: any;
  email: any;
  error: any;
  currentUser: User;
  currentUserSubscription: Subscription;
  info: any = '';
  admin = false;
  checkModificar = false;
  dni: any;
  genero: any;
  facebook: any;
  twitter: any;
  instagram: any;
  distrito: any;
  seccionElectoral: any;
  mandato: any;
  oficina: any;
  interno: any;
  bloqueId: any;
  pictureUrl: any;
  id_banca: any;
  presidenteBloque: any;
  validarRenaper: any;
  banca: any;
  rol: any;
  habilitado: any;
  cantVotosNegativos: any = 0;
  cantVotosPostivios: any = 0;
  constructor(private http: HttpClient,
    public router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private notifyService: NotificationService,
    private pdfService: PdfService,
  ) {
    super();
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.diputados = [];
    this.bancas = [];
    this.bloques = [];
    this.addUser = this.fb.group({
      usuario: new FormControl('', Validators.required),
      nombre: new FormControl('', Validators.required),
      apellido: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      dni: new FormControl('', Validators.required),
      genero: new FormControl('', Validators.required),
      facebook: new FormControl(''),
      twitter: new FormControl(''),
      instagram: new FormControl(''),
      distrito: new FormControl(''),
      seccionElectoral: new FormControl(''),
      mandato: new FormControl(''),
      oficina: new FormControl(''),
      interno: new FormControl(''),
      rol: new FormControl('', Validators.required),
      bloqueId: new FormControl('', Validators.required),
      pictureUrl: new FormControl('', Validators.required),
      presidenteBloque: new FormControl(''),
      validarRenaper: new FormControl(''),
      banca: new FormControl(''),
      habilitado: new FormControl('')
    });
    this.usuario = this.addUser.get('usuario');
    this.nombre = this.addUser.get('nombre');
    this.apellido = this.addUser.get('apellido');
    this.email = this.addUser.get('email');
    this.dni = this.addUser.get('dni');
    this.genero = this.addUser.get('genero');
    this.facebook = this.addUser.get('facebook');
    this.twitter = this.addUser.get('twitter');
    this.instagram = this.addUser.get('instagram');
    this.distrito = this.addUser.get('distrito');
    this.seccionElectoral = this.addUser.get('seccionElectoral');
    this.mandato = this.addUser.get('mandato');
    this.oficina = this.addUser.get('oficina');
    this.rol = this.addUser.get('rol');
    this.interno = this.addUser.get('interno');
    this.bloqueId = this.addUser.get('bloqueId');
    this.pictureUrl = this.addUser.get('pictureUrl');
    this.presidenteBloque = this.addUser.get('presidenteBloque');
    this.validarRenaper = this.addUser.get('validarRenaper');
    this.banca = this.addUser.get('banca');
    this.habilitado = this.addUser.get('habilitado');

  }

  public onInputChange(event) {
    event.target.required = true;
  }
  onFileSelected(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.addUser.get('pictureUrl').setValue(this.f.usuario.value.toLowerCase() + '.' + (this.file.name.split('.'))[(this.file.name.split('.')).length - 1]);
      this.formData.append('file', this.file);

    }
  }

  ngOnInit(): void {

    if (this.currentUser.rol == 'UADMIN') {
      this.admin = true;
    }
    this.addUser.valueChanges.subscribe(() => {
      this.error = null;
    });
    this.http.get(`${environment.apiBaseUrl}/api/users/all`).subscribe(
      (data: any) => {
        this.diputados = data;
      });
    this.http.get(`${environment.apiBaseUrl}/api/banca/disponibles`).subscribe(
      (data: any) => {
        this.bancas = data;
      });
    this.http.get(`${environment.apiBaseUrl}/api/bloque/disponibles`).subscribe(
      (data: any) => {
        this.bloques = data;
      });
    this.http.get(`${environment.apiBaseUrl}/api/users/rolDisponibles`).subscribe(
      (data: any) => {
        this.roles = data;
      });
  }
  afirmativo(id) {
    if (confirm('Estás seguro ingresar positivo?')) {
      this.http.post(`${environment.apiBaseUrl}/api/votacion/${localStorage.getItem('voto_id_signo')}/${id}/positivo`, '').subscribe(
        x => {
          this.notifyService.showSuccess('Voto positivo cargado!', '');

          if (localStorage.getItem('votosPositivos')) {
            this.cantVotosPostivios = localStorage.getItem('votosPositivos');
            // this.cantVotosPostivios=  parseInt(this.cantVotosPostivios) +1;// JSON.parse(data.mensajeJSON);
            // localStorage.setItem("votosPositivos",this.cantVotosPostivios)
          }

        });
    }
  }
  negativo(id) {
    if (confirm('Estás seguro ingresar negativo?')) {
      this.http.post(`${environment.apiBaseUrl}/api/votacion/${localStorage.getItem('voto_id_signo')}/${id}/negativo`, '').subscribe(
        x => {
          this.notifyService.showSuccess('Voto negativo cargado!', '');
          if (localStorage.getItem('votosNegativos')) {
            this.cantVotosNegativos = localStorage.getItem('votosNegativos');
            // this.cantVotosNegativos=parseInt(this.cantVotosNegativos) +1;// JSON.parse(data.mensajeJSON);
            // localStorage.setItem("votosNegativos",this.cantVotosNegativos)
          }
        });
    }
  }
  grabar() {
    if (this.f.pictureUrl.value == this.info.pictureUrl) {
      this.addUser.get('pictureUrl').setValue(this.info.pictureUrl);
    } else {
      this.addUser.get('pictureUrl').setValue(this.f.usuario.value.toLowerCase() + '.' + (this.file.name.split('.'))[(this.file.name.split('.')).length - 1]);
    }
    const head = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let infoA: any;
    let boolrena: any;
    let boolpresi: any;
    let banca: any;
    let boolhabilitado: any;
    if (!this.f.presidenteBloque.value) {
      boolpresi = this.info.presidenteBloque;
    } else {
      boolpresi = this.f.presidenteBloque.value;
    }
    if (!this.f.validarRenaper.value) {
      boolrena = this.info.validarRenaper;
    } else {
      boolrena = this.f.validarRenaper.value;
    }
    if (!this.bancaS) {
      banca = this.info.id_banca;
    } else {
      banca = this.bancaS;
    }
    if (!this.f.habilitado.value) {
      boolhabilitado = this.info.habilitado;
    } else {
      boolhabilitado = this.f.habilitado.value;
    }

    infoA = `{
      "dni":"${this.f.dni.value}",
      "genero":"${this.f.genero.value}",
      "nombre":"${this.f.nombre.value}",
      "apellido":"${this.f.apellido.value}",
      "email":"${this.f.email.value}",
      "facebook":"${this.f.facebook.value}",
      "twitter":"${this.f.twitter.value}",
      "instagram":"${this.f.instagram.value}",
      "distrito":"${this.f.distrito.value}",
      "seccionElectoral":"${this.f.seccionElectoral.value}",
      "mandato":"${this.f.mandato.value}",
      "oficina":"${this.f.oficina.value}",
      "interno":"${this.f.interno.value}",
      "bloqueId":${this.f.bloqueId.value},
      "pictureUrl":"${this.f.pictureUrl.value}",
      "id_banca":"${banca}",
      "presidenteBloque":${boolpresi},
      "usuario":"${this.f.usuario.value}",
      "rol":"${this.f.rol.value}",
      "validarRenaper":${boolrena},
      "pin":"${this.info.pin}",
      "fechaCrea":"2020-05-01T00:00:00",
      "creadoPor":"andres",
      "modificadoPor":"${this.currentUser.usuario}",
      "id":"${this.info.id}",
      "habilitado":${boolhabilitado}
  }`;


    if (this.f.pictureUrl.value != this.info.pictureUrl) {

      this.http.post<any>(`${environment.apiBaseUrl}/api/users/uploadFiles/${this.f.usuario.value.toLowerCase()}/${(this.file.name.split('.'))[(this.file.name.split('.')).length - 1]}`, this.formData)
        .subscribe(res => { this.formData = new FormData; this.file = ''; },
          error => { });
    }

    //console.log(infoA);

    this.http.put(`${environment.apiBaseUrl}/api/users/editar`, infoA, { headers: head }).subscribe(
      (data: any) => {
        this.notifyService.showSuccess('Usuario actulizado con exito!', '');
        // this.addUser.reset()
        this.http.get(`${environment.apiBaseUrl}/api/users/${this.info.id}`).subscribe(
          (data: any) => {
            this.info = data;
            this.checkDetalle = true;
            this.checkModificar = false;
          });
        this.http.get(`${environment.apiBaseUrl}/api/banca/disponibles`).subscribe(
          (data: any) => {
            this.bancas = data;
          });
      },
      error => {
        let totalError = ' ';
        if (error.error[0]) {
          totalError = totalError + error.error[0] + ' '
          this.notifyService.showError(error.error[0], '');
        }
        if (error.error[1]) {
          totalError = totalError + error.error[1] + ' '
          this.notifyService.showError(error.error[1], '');
        }
        if (error.error[2]) {
          totalError = totalError + error.error[2] + ' '
          this.notifyService.showError(error.error[2], '');
        }
        if (totalError == ' ') {
          //console.log(error);
          totalError = 'Hubo un error al actualizar'
          this.notifyService.showError(totalError, '');
        }
      });

  }
  iniciarSesion(id, nombre, apellido) {
    this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/${id}/entrar`).subscribe((data: any) => {
    },
      error => {
      });
  }
  enviar(id) {
    const head = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let pin_user: any;

    pin_user = `{"id_usuario":"${id}","pin":""}`;
    // console.log(pin_user);
    this.http.post(`${environment.apiBaseUrl}/api/users/sendPIN`, pin_user, { headers: head }).subscribe(
      (data: any) => {
        this.notifyService.showSuccess(data.message, '');
      },
      error => {
        this.notifyService.showError(error.error, '');
      });
  }
  detalle(id) {
    this.http.get(`${environment.apiBaseUrl}/api/users/${id}`).subscribe(
      (data: any) => {

        this.info = data;
        this.checkDetalle = true;
        this.checkModificar = false;
      });
  }
  modificar(id) {
    this.http.get(`${environment.apiBaseUrl}/api/users/${id}`).subscribe(
      (data: any) => {
        this.info = data;
        this.addUser.valueChanges.subscribe(() => {
          this.error = null;
        });
        this.checkDetalle = false;
        this.checkModificar = true;
        this.addUser.controls['usuario'].setValue(this.info.usuario, Validators.required);
        this.addUser.controls['nombre'].setValue(this.info.nombre, Validators.required);
        this.addUser.controls['apellido'].setValue(this.info.apellido, Validators.required);
        this.addUser.controls['email'].setValue(this.info.email, Validators.required);
        this.addUser.controls['dni'].setValue(this.info.dni, Validators.required);
        this.addUser.controls['genero'].setValue(this.info.genero, Validators.required);
        this.addUser.controls['facebook'].setValue(this.info.facebook);
        this.addUser.controls['twitter'].setValue(this.info.twitter);
        this.addUser.controls['instagram'].setValue(this.info.instagram);
        this.addUser.controls['distrito'].setValue(this.info.distrito);
        this.addUser.controls['seccionElectoral'].setValue(this.info.seccionElectoral);
        this.addUser.controls['mandato'].setValue(this.info.mandato);
        this.addUser.controls['oficina'].setValue(this.info.oficina);
        this.addUser.controls['interno'].setValue(this.info.interno);
        this.addUser.controls['rol'].setValue(this.info.rol, Validators.required);
        this.addUser.controls['bloqueId'].setValue(this.info.bloqueId);
        this.addUser.controls['pictureUrl'].setValue(this.info.pictureUrl);
        this.addUser.controls['banca'].setValue(this.info.id_banca);
        this.addUser.controls['habilitado'].setValue(this.info.habilitado);
        // this.addUser.controls["presidenteBloque"].setValue(this.info.presidenteBloque,Validators.required)
        // this.addUser.controls["validarRenaper"].setValue(this.info.validarRenaper,Validators.required)
      });
  }
  get f() { return this.addUser.controls; }
  resetPins() {
    if (confirm('Estas seguro que desea resetar los pins?')) {
      this.botonPin = true;
      this.http.get(`${environment.apiBaseUrl}/api/users/reset-pin`).subscribe(x => {
        this.botonPin = false;
        this.notifyService.showSuccess('Pins de seguridad reseteados con exito!', '')
      },
        error => {
          this.botonPin = false;
          this.notifyService.showError('Hubo un error al resetear los pins de seguridad!', '')
        });
    }
  }

  descargarReporte() {
   this.pdfService.getReporte().subscribe({
      next: (data) => {
        this.reporteUsuarios(data);
      },
      error: (err) => { },
    });



  }

  reporteUsuarios(users) {
    PdfMakeWrapper.setFonts(vfs_fonts);
    const pdf = new PdfMakeWrapper();
    pdf.pageSize('A4');
    var usuarios = [];
    usuarios.push(
      [new Txt('Apellido').fontSize(14).italics().bold().end, 
      new Txt('Nombre').fontSize(14).italics().bold().end, 
      new Txt('Bloque').fontSize(14).italics().bold().end, 
      new Txt('Email').fontSize(14).italics().bold().end, 
      new Txt('Pin').fontSize(14).italics().bold().end]);


    for (let user of users) {
      if (user.rol != 'UADMIN') {
        if (user.bloque) {
          usuarios.push([user.apellido, user.nombre, user.bloque, user.email, user.pin]);
        } else {
          usuarios.push([user.apellido, user.nombre, '', user.email, user.pin]);
        }

      }
    }
    //defino 
    pdf.add(new Table(usuarios).widths( ['20%', '20%', '20%', '20%', '20%']).end);
    pdf.create().download(`reporte_de_usuario_pin`);
  }
  checkVotacion() {
    if (localStorage.getItem('voto_id_signo')) {
      return true;
    } else {
      return false;
    }
  }

  checkAdmin(rol) {
    if (rol == 'UADMIN') {
      return true;
    } else {
      return false;
    }
  }
  traductorDeBoolean(bool) {
    if (bool) {
      return 'SI'
    } else {
      return 'NO'
    }
  }
  retunrRol(rol) {
    if (rol == 'UADMIN') {
      return 'Administrador'
    } else if (rol == 'USUDIP') {
      return 'Diputado'
    } else if (rol == 'USULEG') {
      return 'Usuario legislativo'
    } else if (rol == 'UPRESI') {
      return 'Presidente'
    } else if (rol == 'SECLEG') {
      return 'secretario legislativo'
    } else if (rol == 'SECADM') {
      return 'Secretario administrativo'
    } else if (rol == 'UUJIER') {
      return 'Ujier'
    } else {
      return 'Observador'
    }
  }

  retunrRolForInfo(rol) {
    if (rol == 'UADMIN') {
      return 'Administrador'
    } else if (rol == 'USUDIP') {
      return 'Diputado'
    } else if (rol == 'USULEG') {
      return 'Usuario legislativo'
    } else if (rol == 'UPRESI') {
      return 'Presidente'
    } else if (rol == 'SECLEG') {
      return 'secretario legislativo'
    } else if (rol == 'SECADM') {
      return 'Secretario administrativo'
    } else if (rol == 'UUJIER') {
      return 'Ujier'
    } else {
      return 'Observador'
    }
  }
  checked(bool) {
    if (bool) {
      return 'checked'
    } else {
      return ''
    }
  }
  traductorDeGenero(gen) {
    if (gen == 'M') {
      return 'Masculino'
    } else if (gen == 'F') {
      return 'Femenino'
    } else {
      return 'Otros'
    }
  }
  agregar() {
    this.router.navigate(['/app/agregar-usuario']);
  }

  cerrarSesionForUser(id) {
    this.http.get(`${environment.apiBaseUrl}/api/users/remoteLogout/${id}`).subscribe((data: any) => {
    })
  }
}
