import { SolicitudesService } from './../../services/solicitudes.service';
import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ThemeModule } from 'theme';
import { TooltipModule } from '../../../theme/directives/tooltip/tooltip.module';
import { CotoneasterCardComponent } from './cotoneaster-card';
import { DashboardComponent } from './dashboard.component';
import { LineChartComponent } from './line-chart';
import { PieChartComponent } from './pie-chart';
import { RobotCardComponent } from './robot-card';
import { TableCardComponent } from './table-card';
import { TodoListComponent } from './todo-list';
import { TrendingComponent } from './trending';
import { WeatherComponent } from './weather';
import { ConferenceComponent } from '../../conference';
import { MenuComponent } from 'app/menu/menu.component';
import { GrillaComponent } from 'app/grilla/grilla.component';
import { SumarioComponent } from '../../sumario/sumario.component';
import { SumarioPresencialComponent } from '../../sumarioPresencial/sumarioPresencial.component';
import { SesionComponent } from '../../sesion';
import { HemicicloComponent } from '../../hemiciclo/hemiciclo.component';
import { HemicicloVotacionComponent } from '../../hemiciclo-votacion/hemiciclo-votacion.component';
import { IndexRemoteDiputadoComponent } from 'app/index-remote-diputado/index-remote-diputado.component';
import { IndexPresencialDiputadoComponent } from 'app/index-presencial-diputado/index-presencial-diputado.component';
import { UjierComponent } from 'app/ujier/ujier.component';
import { HemicicloFullComponent } from 'app/components/hemiciclo-full/hemiciclo-full.component';


import { PdfViewerModule } from 'ng2-pdf-viewer';



import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MdePopoverModule } from '@material-extended/mde';
import { MatTabsModule } from '@angular/material/tabs';
import { ListaPresentesComponent } from 'app/components/lista-presentes/lista-presentes.component';
import { ListaAusentesComponent } from 'app/components/lista-ausentes/lista-ausentes.component';

import { VotoDiputadoComponent } from 'app/components/voto-diputado/voto-diputado.component';
import { VotacionModalComponent } from 'app/components/votacion-modal/votacion-modal.component';


import { PresidenteComponent } from 'app/components/presidente/presidente.component';
import { ObserverComponent } from 'app/components/observer/observer.component';

import { UsulegComponent } from 'app/components/usuleg/usuleg.component';
import { SeclegComponent } from 'app/components/secleg/secleg.component';
import { CargarVotacionComponent } from 'app/components/cargar-votacion/cargar-votacion.component';
import { EstadisticasComponent } from 'app/components/estadisticas/estadisticas.component';
import { ActualizarVotacionesService } from 'app/services/signal-r/actualizarVotaciones';
import { SecadminComponent } from 'app/components/secadmin/secadmin.component';
import { VotacionModalResultadoComponent } from 'app/components/votacion-modal-resultado/votacion-modal-resultado.component';
import { VotacionModalResultadoService } from 'app/services/signal-r/votacion-modal-resultado.service';
import { HemicicloConColoresComponent } from 'app/components/hemiciclo-con-colores/hemiciclo-con-colores.component';
import { HemicicloObserverComponent} from 'app/components/hemiciclo-observer/hemiciclo-observer.component'
import { HemicicloObserverNumeradoComponent } from 'app/components/hemiciclo-observer-numerado/hemiciclo-observer-numerado.component';
import { HemicicloNumeradoComponent } from 'app/hemiciclo-numerado/hemiciclo-numerado.component';
import { OradoresComponent } from 'app/components/oradores/oradores.component';
import { MatDividerModule } from '@angular/material/divider';

//drag n drop



import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    CommonModule,
    ThemeModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    PdfViewerModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MdePopoverModule,
    MatTabsModule,
    MatDividerModule,
    DragDropModule
    
   


  ],
  declarations: [
    DashboardComponent,
    LineChartComponent,
    SumarioComponent,
    SumarioPresencialComponent,
    PieChartComponent,
    HemicicloVotacionComponent,
    MenuComponent,
    GrillaComponent,
    SesionComponent,
    WeatherComponent,
    CotoneasterCardComponent,
    TableCardComponent,
    RobotCardComponent,
    TodoListComponent,
    TrendingComponent,
    ConferenceComponent,
    HemicicloComponent,
    HemicicloFullComponent,
    IndexRemoteDiputadoComponent,
    IndexPresencialDiputadoComponent,
    UjierComponent,
    ListaAusentesComponent,
    ListaPresentesComponent,
    VotoDiputadoComponent,
    VotacionModalComponent,
    PresidenteComponent,
    ObserverComponent,
    UsulegComponent,
    SeclegComponent,
    CargarVotacionComponent,
    EstadisticasComponent,
    SecadminComponent,
    VotacionModalResultadoComponent,
    HemicicloConColoresComponent,
    HemicicloObserverComponent,
    HemicicloObserverNumeradoComponent,
    HemicicloNumeradoComponent,
    OradoresComponent,
    
  ],
  exports: [
    WeatherComponent,
    TrendingComponent,
    VotacionModalResultadoComponent
  ],
  providers: [
    SolicitudesService,
    ActualizarVotacionesService,
    VotacionModalResultadoService
  ]
})
export class DashboardModule { }
