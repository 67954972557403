import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConferenceService } from 'app/services/conference/conference.service.js';
import { environment } from '../../environments/environment';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'app/services';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { User } from 'app/models';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { SignalRService } from 'app/services/signal-r/signal-r.service';
import { MensajeHub } from '../models/mensajeHub';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-conference',
  templateUrl: './conference.component.html',
  styleUrls: ['./conference.component.css'],
})

export class ConferenceComponent implements OnInit {
  public xmlItems: any;
  urlTogo:any;
  code:any;
  parametros: any;
  checksum:any;
  checksumJoin:any;
  parametrosJoin:any;
  meetings: any;
  result:any;
  onLive:any;
  parametrosExit: string;
  checksumExit: string;
  currentUser: User;
  currentUserSubscription: Subscription;
  mostrar:any;
  cantidadPresentes: number;
  fecha_sesion:any="";
  nro_periodo:any="";
  nro_sesion:any="";
  cantMinQuorum: number;
  quorum:string="";
  private mensajeOBJ: any;
  private httpSI: HttpClient;
  idSala: any;
  pwSala: any;
  error: any;
  urlSala: any;
  data:any;
  constructor(private router: Router,
              private authservice: AuthService,
              private http: HttpClient,
              private handler: HttpBackend,
              public confeservice: ConferenceService,
              private fb: FormBuilder,
              public SignalR:SignalRService,
              ) {
                  //this.confeservice.loadConference();
                  //this.loadData();
                  this.httpSI = new HttpClient(handler);
                  this.currentUserSubscription = this.authservice.currentUser.subscribe(user => {
                    this.currentUser = user;
                  });
     }


 

  ngOnInit(): void {
    this.running();
    var data:any="";
    data=JSON.parse(localStorage.getItem("dataIniciado"))
    if(data.webex_url){
      if(data.webex_url.includes('https://') || data.webex_url.includes('http://')){
        this.urlTogo=data.webex_url
      }else{
        this.urlTogo="https://"+data.webex_url
      }
    }
    //setTimeout(()=>{this.loadData()},1000);

    

    if(this.currentUser.rol == "UADMIN"){
      this.mostrar=true
    };

    this.SignalR.getVotacion$().subscribe((data:MensajeHub)=>{

      if (data.tipo == MensajeHub.CONST_TIPO_ENTRA_SESION || data.tipo == MensajeHub.CONST_TIPO_SALE_SESION)
      {
        this.mensajeOBJ=JSON.parse(data.mensajeJSON);
        this.running();
      };
  });
}


  running(){
    var data:any="";
    data=JSON.parse(localStorage.getItem("dataIniciado"))
    if(!data.webex_active){
      this.confeservice.activeOff();
    } else {
      this.confeservice.activeOn();
    }
     
    
  }

  JoinConference(){
    window.open(this.urlTogo,"_blank")
  }
}
