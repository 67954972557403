import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SidebarComponent as BaseSidebarComponent } from 'theme/components/sidebar';
import { AuthService } from 'app/services';
import { Subscription } from 'rxjs';
import { User } from 'app/models';

@Component({
  selector: 'app-sidebar',
  styleUrls: ['../../../theme/components/sidebar/sidebar.component.scss', './sidebar.component.scss'],
  templateUrl: '../../../theme/components/sidebar/sidebar.component.html',
})
export class SidebarComponent extends BaseSidebarComponent implements OnInit {
  public diputados:any;
  sesion:any;
  currentUser: User;
  currentUserSubscription: Subscription;
  constructor(private authService: AuthService,
              private http: HttpClient,){
    super();
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
    this.currentUser = user;})
  }
  public title = 'sesionar';
  public menu = [];
  ngOnInit(): void {
    this.sesion=JSON.parse(localStorage.getItem('dataIniciado'));
    if (this.currentUser.rol=="UADMIN"){
        this.menu=[
          { name: 'Sesión', link: '/app/dashboard', icon: 'home' },
          { name: 'Comisión', link: '/app/dashboard-comision', icon: 'work' },
          { name: 'Teleconferencia', link: '/app/sesion', icon: 'dialer_sip' },
          { name: 'Admin Sesión', link: '/app/sesion-admin' , icon: 'people' },
          { name: 'Cargar Votaciones',link:'/app/cargar-votaciones',icon:'drafts'},
          { name: 'Usuarios', link:'/app/diputados',icon: 'person'},
          { name: 'Ausentes',link:'/app/ausentes',icon:'people'},
          { name: 'Registrar Huella',link:'/app/enrollAdmin',icon:'fingerprint'},
          { name: 'Cerrar', link: '/login', icon: 'logout' },
        ]
    } else if (this.currentUser.rol=="USULEG"){
      this.menu=[
        { name: 'Sesión', link: '/app/usuariolegislativo', icon: 'home' },
        { name: 'Cargar Votaciones',link:'/app/cargar-votaciones',icon:'drafts'},
        { name: 'Cerrar', link: '/login', icon: 'logout' },
      ];
    /* } else if (this.currentUser.rol=="USUL"){
      this.menu=[]; */
    } else {
      if(!this.sesion.fechaHoraFinaliza && !this.sesion.fechaHoraInicia){
        this.menu=[
          { name: 'Comision', link: '/app/dashboard-comision', icon: 'home' },
          { name: 'Cerrar', link: '/login', icon: 'logout' },
        ];
      }
      if(localStorage.getItem('dataComision') == 'null'){
        this.menu=[
          { name: 'Sesion', link: '/app/dashboard', icon: 'home' },
          { name: 'Cerrar', link: '/login', icon: 'logout' },
        ];
      }
      if(!this.sesion.fechaHoraFinaliza && this.sesion.fechaHoraInicia && localStorage.getItem('dataComision') != 'null'){
        this.menu=[
          { name: 'Comision', link: '/app/dashboard-comision', icon: 'home' },
          { name: 'Sesion', link: '/app/dashboard', icon: 'home' },
          { name: 'Cerrar', link: '/login', icon: 'logout' },
        ];
      }
    }
  }
}
