export class NuevaVotacion {
    id_sesion: string  
    id_expediente: string 
    origen_exp: string  
    numero_exp: string  
    anios_exp: string  
    descripcion: string  
    modoAprobacion: string  
    orden:number
    
}