import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NuevaVotacion } from 'app/models/nuevaVotacion';
import { BlankLayoutCardComponent } from 'app/components/blank-layout-card';
@Injectable({
  providedIn: 'root'
})
export class GrillaService extends BlankLayoutCardComponent implements OnInit{
  public elements:any[]= [];

  constructor(private http:HttpClient) {
    super();
    this.elements=[];
    this.http.get(`${environment.apiBaseUrl}/api/votacion`).subscribe(
     (data:any)=>{this.elements=data} );

   }

  ngOnInit(): void {
  }

  public get Grilla() {
    var arr: [];
    return arr;
  }

  deleteElement(i){
    this.elements.splice(i,1);
    localStorage.setItem("grilla",JSON.stringify(this.elements));
  }

  loadGrilla(){    
    this.http.get(`${environment.apiBaseUrl}/api/votacion`).subscribe(
      (data:any)=>{
        this.elements=data.sort((a, b) => a.orden - b.orden);
      });
      
    
  }

  reloadGrilla(data){
    this.elements=data.sort((a, b) => a.orden - b.orden);
  }

  setGrilla(orden,expediente,detalle){
    var info:any;
    if(expediente!=null){
      if(expediente=="null"){
        expediente=null;
      }else{
      expediente=`"${expediente}"`;}
    }
    let head = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    detalle=detalle.replace("\n",' ');
    info=`{"id_sesion":"${orden}","id_expediente":${expediente},"descripcion":"${detalle}"}`;
    //console.log(info);
    this.http.post(`${environment.apiBaseUrl}/api/votacion`,JSON.parse(info),{headers: head}).subscribe(
      data=>{
        this.loadGrilla();
        }
    )
  }

  nuevaVotacion(nuevaVotacion:NuevaVotacion){
    console.log("nueva Votacion", nuevaVotacion)
     this.http.post<NuevaVotacion>(`${environment.apiBaseUrl}/api/votacion`,nuevaVotacion).subscribe(
      data=>{
        this.loadGrilla();
        }
    ) 
  }
  
  getCantVotaciones():number{
    return this.elements.length
  }

  //devuelve la votacion que corresponde de la grilla
  getVotacionId(id):any{
    this.elements.find(x=> x.id == id);
    return this.elements.find(x=> x.id == id);
  }
}
