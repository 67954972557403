import { Injectable, ErrorHandler, Injector, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate,Router, RouterStateSnapshot} from '@angular/router';
import { ConferenceService } from 'app/services/conference/conference.service.js';
import { AuthService } from './auth.service';
import { NotificationService } from '../../services/notification/notification.service';
import { zip } from 'rxjs';
import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate{
  private check: boolean = true;
  constructor(private authService: AuthService,
              private authenticationService: AuthService,
              private router: Router,
              private notifyService : NotificationService,
              private confeservice: ConferenceService,) {
  }


  canActivate(route: ActivatedRouteSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    const loginByFingerprint = this.authenticationService.loginByFingerprintValue;

    //console.log("currentUser",currentUser)
    //console.log("loginByFingerprint",loginByFingerprint)

    if (currentUser){
      // authorised so return true
      if (loginByFingerprint == false){
        try{
          if(localStorage.getItem('conferenceCode') != null && CryptoJS.AES.decrypt((JSON.parse(localStorage.getItem('conferenceCode'))).toString(),"codigoRenaper_secret").toString(CryptoJS.enc.Utf8)=="true"){
            return true;
          }else{
            this.router.navigate(['/renaper']);
            this.showToasterError("Acceso denegado a esta pagina, sin código de conferencia.");
            return false;
          }
        }catch{
          this.router.navigate(['/renaper']);
          this.showToasterError("Acceso denegado a esta pagina, No se pudo leer código de conferencia");
          return false;
        }
      }
      else{
        return true;
      }
    }else{
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/login']);
      this.showToasterError("Acceso denegado, primero loggeate con un usuario valido.");
      return false;
    }
  }

  showToasterError(msj1,msj2=""){
    if(this.check==true){
      this.notifyService.showError(msj1,msj2);
      this.check=false;
      return;
    }
    this.check=true;
  }
}
