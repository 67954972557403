import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SignalRService } from 'app/services/signal-r/signal-r.service';
import { MensajeHub } from '../models/mensajeHub';
import { ConferenceService } from 'app/services/conference/conference.service';
import { BlankLayoutCardComponent } from 'app/components/blank-layout-card';
import { UpgradableComponent } from 'theme/components/upgradable';
import { User } from 'app/models';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth/auth.service';
import { SesionService } from 'app/services/sesion.service';
import { SesionInstanciaActiva } from 'app/models/sesionInstanciaActiva';

@Component({
  selector: 'app-hemiciclo',
  templateUrl: './hemiciclo.component.html',
  styleUrls: ['./hemiciclo.component.scss']
})

export class HemicicloComponent extends UpgradableComponent implements OnInit {
  public diputados: any[] = [];
  public presentes: any[] = [];
  private mensajeOBJ: any;
  currentUser: User;
  sesionActiva: SesionInstanciaActiva
  showHemiciclo: boolean = true;
  showPresentes: boolean = false;
  showAusentes: boolean = false;
  public now: Date = new Date();

  constructor(private http: HttpClient,
    public SignalR: SignalRService,
    public confeservice: ConferenceService,
    private router: Router,
    private authService: AuthService,
    private sesionService: SesionService) {
    super();

    //seteo la hora actual para mostrar en pantalla
    setInterval(() => {
      this.now = new Date();
    }, 1);



    this.authService.currentUser.subscribe(user => {
      this.currentUser = user;
    })
    this.diputados = [];
    this.presentes = [];
  }


  ngOnInit(): void {
    this.loadData();
    
    this.http.get(`${environment.apiBaseUrl}/api/users/hemiciclo`).subscribe(
      (data: any) => {
        this.diputados = data;
        
        this.http.get(`${environment.apiBaseUrl}/api/sesionInstanciaPresente/presentes`).subscribe(
          (data: any) => {
            this.presentes = data;
            for (var i = 0; i < this.diputados.length; i++) {
              for (var x = 0; x < this.presentes.length; x++) {
                if (this.diputados[i].id == this.presentes[x].usuario_id) {
                  this.diputados[i].presente = true;
                }
              }
            }
          });
      });


    this.SignalR.getVotacion$().subscribe((data: MensajeHub) => {
      if (data.tipo == MensajeHub.CONST_TIPO_ENTRA_SESION || data.tipo == MensajeHub.CONST_TIPO_SALE_SESION) {
        this.loadData()
        
        this.mensajeOBJ = JSON.parse(data.mensajeJSON);
        for (var i = 0; i < this.diputados.length; i++) {
          this.diputados[i].presente = false;
          for (var x = 0; x < this.mensajeOBJ.presentes.length; x++) {
            if (this.diputados[i].id == this.mensajeOBJ.presentes[x]) {
              this.diputados[i].presente = true;
            }
          }
        }
      }
    })
    
  }
  

  
  loadData() {
    this.sesionService.getActiva().subscribe({
      next: (data) => {
        this.sesionActiva = data    
      },
      error: (err) => { },
    });
  }




  public Salir() {
    if (this.currentUser.rol == "USUDIP" || this.currentUser.rol == "UPRESI") {
      this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/${this.currentUser.id}/salir`).subscribe((data: any) => {
        this.router.navigate(['/login']);
      },
        error => {
        });
    }
    this.SignalR.closeConnection();
    this.router.navigate(['/login']);
  }

  public esDiputado() {
    if (this.currentUser.rol == 'USUDIP' || this.currentUser.rol == 'UPRESI') {
      return true;
    }
    return false;
  }


  permisoAusentar(){
    return (this.currentUser.rol=="UUJIER"||this.currentUser.rol=="UADMIN")
  }
  permisoAsistir(){
    return (this.currentUser.rol=="UADMIN")
  }

  mostrarPresentes() {
    this.showHemiciclo = false;
    this.showAusentes = false;
    this.showPresentes = true;
  }

  mostrarAusentes() {
    this.showHemiciclo = false;
    this.showAusentes = true;
    this.showPresentes = false;
  }

  mostrarHemiciclo() {
    this.showHemiciclo = true;
    this.showAusentes = false;
    this.showPresentes = false;
  }


  public ausentar(id) {
    if (this.currentUser.rol == "UADMIN" || this.currentUser.rol == "UPRESI" ||
    this.currentUser.rol=="UUJIER" || this.currentUser.rol=="SECADM") { 
      this.http.get(`${environment.apiBaseUrl}/api/users/remoteLogout/${id}`).subscribe((data:any)=>{
    })
     
    }
  }


  public asistir(id) {
    if (this.currentUser.rol == "UADMIN" || this.currentUser.rol == "UPRESI" ||
    this.currentUser.rol=="UUJIER" || this.currentUser.rol=="SECADM") { 
      this.http.get(`${environment.apiBaseUrl}/api/users/remoteLogin/${id}`).subscribe((data:any)=>{
    })
     
    }
  }
}
