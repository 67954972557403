import { Component, HostBinding, OnInit, OnDestroy, HostListener } from '@angular/core';
import { UpgradableComponent } from 'theme/components/upgradable';
import {  Subscription } from 'rxjs';
import { SignalRService } from 'app/services/signal-r/signal-r.service';

import { AuthService } from '@services/*';
import { User } from 'app/models';
import { GrillaService } from 'app/services/grilla.service';
@Component({
  selector: 'app-usuleg',
  templateUrl: './usuleg.component.html',
  styleUrls: ['./usuleg.component.scss']
})

export class UsulegComponent extends UpgradableComponent implements OnInit, OnDestroy {
  @HostBinding('class.mdl-grid') private readonly mdlGrid = true;
  @HostBinding('class.mdl-grid--no-spacing') private readonly mdlGridNoSpacing = true;
  @HostListener('window:unload', ['$event'])
  unloadHander($event) {
    this.SignalR.closeConnection();
    return $event.returnValue = 'Your changes will not be saved';
  }

  currentUser: User;
  currentUserSubscription: Subscription;
  mostrarVotacion: any = false;

  constructor(
    public SignalR: SignalRService,
    private authService: AuthService,
    public gr: GrillaService) {
    super();
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      this.currentUser = user;
    })
  }

  ngOnDestroy() { }

  ngOnInit(): void {
  }

  chequearHemiciclo() {
    if (localStorage.getItem("mostrarVotacion")) {
      this.mostrarVotacion = localStorage.getItem("mostrarVotacion");
    } else {
      this.mostrarVotacion = "false";
    }
    return this.mostrarVotacion;
  }

  stopConnectio() {
    this.SignalR.closeConnection();
  }

}
