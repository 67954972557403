import { Component, HostBinding, OnInit, OnDestroy, HostListener } from '@angular/core';
import { UpgradableComponent } from 'theme/components/upgradable';
import { Observable, Subject, Subscription } from 'rxjs';
import { SignalRService } from 'app/services/signal-r/signal-r.service';

import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@services/*';
import { User } from 'app/models';
import { MensajeHub } from '../models/mensajeHub';
import { GrillaService } from 'app/services/grilla.service';
import { NotificationService } from 'app/services/notification/notification.service';
import { delay } from 'rxjs/operators';
import { ConferenceService } from 'app/services/conference/conference.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-index-presencial-diputado',
  templateUrl: './index-presencial-diputado.component.html',
  styleUrls: ['./index-presencial-diputado.component.scss']
})
export class IndexPresencialDiputadoComponent extends UpgradableComponent implements OnInit, OnDestroy {
  hide: boolean = true;
  currentUser: User;
  currentUserSubscription: Subscription;
  admin: boolean = false;
  observ: boolean = false;
  check: any = true;
  fecha_sesion: string;
  nro_sesion: string;
  nro_periodo: string;
  cantidadPresentes: number;
  cantMinQuorum: number;
  public quorum: string = "";
  data: any;
  public now: Date = new Date();
  mostrarMic: boolean = false;



  scrollbol: boolean = false;
  constructor(
    private http: HttpClient,
    private confeservice: ConferenceService,
    private notifyService: NotificationService,
    private authService: AuthService,
    public gr: GrillaService,
    private router: Router,
    public SignalR: SignalRService,) {
    super();

    //seteo la hora actual para mostrar en pantalla
    setInterval(() => {
      this.now = new Date();
    }, 1);


    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      this.currentUser = user;
    })

  }

  loadData() {
    //this.confeservice.loadConference();

    if (localStorage.getItem("dataIniciado")) {
      this.data = JSON.parse(localStorage.getItem("dataIniciado"))
      if (this.data) {
        localStorage.setItem("presentes", this.data.cantPresentes.toString());

        if (this.data.cantPresentes) {
          this.cantidadPresentes = this.data.cantPresentes;
        };
        if (this.data.fecha_sesion) {
          this.fecha_sesion = this.data.fecha_sesion;
        }
        if (this.data.nro_periodo) {
          this.nro_periodo = this.data.nro_periodo;
        }
        if (this.data.nro_sesion) {
          this.nro_sesion = this.data.nro_sesion;
        }
        if (this.data.cantMinQuorum) {
          this.cantMinQuorum = this.data.cantMinQuorum;
        }
        if (this.cantidadPresentes >= this.cantMinQuorum) {
          this.quorum = "HAY QUORUM";
        } else {
          this.quorum = "";
          localStorage.setItem("quorum", "")
        };
      }
    }
  }
 /*  this.SignalR.getVotacion$().subscribe((data:MensajeHub)=>{
    //console.log("signal"+this.hide)
    
    if (data.tipo == MensajeHub.mic_on)
    {
      if(!this.observ){
        delay(2000)
        this.toasWcheck=true
        this.showToasterEfectuar(`Atención! Efectúe su voto.`);
      } */
  ngOnInit(): void {

    this.SignalR.getMicrofono$().subscribe((data)=>{
      
      if(data.mensajeJSON=='"mic_on"'){
       this.mostrarMic=true;
      }
      else{
        this.mostrarMic=false;   
      }
     
    })

    this.loadData();
    localStorage.removeItem('idReunion');
    if (localStorage.getItem("currentUser")) {
      if (this.currentUser.rol == "USUDIP") {
        this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/${this.currentUser.id}/entrar`).subscribe(data => {
          this.confeservice.loadConference();
        });
      } else {
        this.confeservice.loadConference();
      }
    }
  }

  ngOnDestroy() { }

  public Salir() {
    if (this.currentUser.rol == "USUDIP" || this.currentUser.rol == "UPRESI") {
      this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/${this.currentUser.id}/salir`).subscribe((data: any) => {
        this.router.navigate(['/login']);
      }, error => { });
    }
    this.SignalR.closeConnection();
    this.router.navigate(['/login']);
  }


  public testMic(){
    this.mostrarMic = !this.mostrarMic ;
  }

}
