import { Component, OnInit } from '@angular/core';

import { FormGroup, FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlankLayoutCardComponent } from 'app/components/blank-layout-card';
import { GrillaService } from 'app/services/grilla.service';
import { GrillaComponent } from 'app/grilla/grilla.component'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { JsonHubProtocol } from '@aspnet/signalr';
import { AuthService } from 'app/services';
import { User } from 'app/models';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';


import { NotificationService } from 'app/services/notification/notification.service';
import { CaratulaService } from 'app/services/caratula.service';
import { SesionService } from 'app/services/sesion.service';


import { Caratula } from 'app/models/caratula';
import { SesionInstanciaActiva } from 'app/models/sesionInstanciaActiva';
import { NuevaVotacion } from 'app/models/nuevaVotacion';


import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { getAllJSDocTagsOfKind } from 'typescript';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],

})


export class MenuComponent extends BlankLayoutCardComponent implements OnInit {

  encuesta: FormGroup;
  nuevaVotacion: FormGroup;
  caratulas: Caratula[] = [];
  sesionActiva: SesionInstanciaActiva;
  modosAprobacion: any[] = [];

  currentUser: User;
  currentUserSubscription: Subscription;

  numeroProximoOrden: number;


  constructor(private fb: FormBuilder,
    private gr: GrillaService,
    private http: HttpClient,
    private notifyService: NotificationService,
    private authService: AuthService,
    private caratulaService: CaratulaService,
    private sesionService: SesionService,
    private formPrueba: MatFormFieldModule,
    ) {

      
    super();
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      this.currentUser = user;
    })

    

    //defino el formulario de la nueva votacion
    this.nuevaVotacion = this.fb.group({
      id_expediente: new FormControl(''),
      detalle: new FormControl('', Validators.required),
      aprobacion: new FormControl('', Validators.required),
      orden: new FormControl(this.numeroProximoOrden),
    });

    //defino el formulario de la busqueda de expediente
    this.encuesta = this.fb.group({
      letra: new FormControl('', Validators.required),
      numero: new FormControl('', Validators.required),
      anio: new FormControl('', Validators.required),
      alcance: new FormControl('', Validators.required),
     
      
    });
    //defino los metodos de aprobacion
    this.setModosAprobacion()
   

  }

  ngOnInit() {
    this.cargarSesion();
    //this.cargarCaratulas()
  }

  cargarSesion(): void {
    this.sesionService
      .getActiva()
      .subscribe({
        next: (data) => {
          this.sesionActiva = data;
        },
        error: (err) => {},
      });     
  
  }


  //carga de expedientes
  cargarCaratulas(): void {
    this.caratulaService
      .getAll()
      .subscribe({
        next: (data) => {
          this.caratulas = data;
        },
        error: (err) => { },
      });
  }

  //carga de expedientes
  actualizarCaratula(): void {
    this.caratulaService
      .getBy(
        this.encuesta.controls.letra.value,
        this.encuesta.controls.numero.value,
        this.encuesta.controls.anio.value,
        this.encuesta.controls.alcance.value
      )
      .subscribe({
        next: (data) => {
          this.nuevaVotacion.controls.detalle.setValue(data.asunto)
          this.nuevaVotacion.controls.id_expediente.setValue(data.exp_ppal)
        },
        error: (err) => {
          console.log(err.error);
          this.nuevaVotacion.controls.id_expediente.setValue("NULL")
          this.nuevaVotacion.controls.detalle.setValue("")
        },
      });
  }


  setModosAprobacion() {
    
    this.modosAprobacion.push({ modo: 'MS' ,valor:'Mayoria Simple'})
    this.modosAprobacion.push({ modo: 'MA' ,valor:'Mayoria Absoluta'})
    this.modosAprobacion.push({ modo: 'C23',valor:'Camara 2/3' })
    this.modosAprobacion.push({ modo: 'P15',valor:'Presentes 1/5'})
    this.modosAprobacion.push({ modo: 'P13',valor:'Presentes 1/3' })
    this.modosAprobacion.push({ modo: 'P23',valor:'Presentes 2/3'})
   
  }

  enviar() {
    //cargo la votacion y reseteo los forms.
    this.cargarVotacion()
    this.encuesta.reset();
    this.nuevaVotacion.reset();    
    delay(2000)
    this.showToasterCargar(`La votación ha sido cargada`);
  }



  cargarVotacion(){
    const nuevaVotacion = new NuevaVotacion();

    
   
    //Cargo la clase nuevaVotacion con los datos que vienen del formulario

    nuevaVotacion.id_sesion =  this.sesionActiva.id_sesion
    nuevaVotacion.id_expediente= this.nuevaVotacion.controls.id_expediente.value
    nuevaVotacion.origen_exp = this.encuesta.controls.letra.value
    nuevaVotacion.numero_exp = this.encuesta.controls.numero.value
    nuevaVotacion.anios_exp= this.encuesta.controls.anio.value
    nuevaVotacion.descripcion=this.nuevaVotacion.controls.detalle.value
    nuevaVotacion.modoAprobacion=  this.nuevaVotacion.controls.aprobacion.value
    nuevaVotacion.orden= this.nuevaVotacion.controls.orden.value

    if ((this.nuevaVotacion.controls.orden.value) || (this.nuevaVotacion.controls.orden.value == this.numeroProximoOrden)){
      nuevaVotacion.orden = this.nuevaVotacion.controls.orden.value
    }
    else {
       nuevaVotacion.orden =  this.numeroProximoOrden
    }   
    console.log("enviar(): ", this.nuevaVotacion.controls.orden)
    
    this.gr.nuevaVotacion(nuevaVotacion)

  }

  //al completar los campos de encuesta, se envia la solicitud a la base de datos.
  public onInputChange(event) {
    if (this.encuesta.valid) {
      this.actualizarCaratula()
    }
  }

  showToasterCargar(msj1, msj2 = "") {
    this.notifyService.showSuccess(msj1, msj2);
  }



  
  getArrVotaciones(){ 
  
      this.numeroProximoOrden = this.gr.getCantVotaciones() + 1;
      return [...Array(this.numeroProximoOrden-1).keys()];
  }
}
