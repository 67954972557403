import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class ConferenceService {
  private active: Boolean;
  private code:any;
  private conferenceActiveSubjet: BehaviorSubject<Boolean>;
  public conferenceActiva: Observable<Boolean>;
  iniciadoData: any;
  data: any="";

  constructor(private http: HttpClient,) {

    this.conferenceActiveSubjet = new BehaviorSubject<Boolean>(JSON.parse(localStorage.getItem('conferenceActive')));
    this.conferenceActiva = this.conferenceActiveSubjet.asObservable();
    this.active = this.conferenceActiveSubjet.value;

   }

  public get conferenceActive(): Boolean {
    var bool: Boolean;
    this.conferenceActiveSubjet.pipe(first())
    .subscribe(x =>{
      //console.log(x);
      bool= x;
      //console.log(bool);
    });
    return bool;// this.conferenceActiveSubjet.value;
  }

  loadConference(){
    this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/iniciado`).subscribe((data:any)=>{
      localStorage.setItem('dataIniciado',JSON.stringify(data))

      //fuerza el sincronismo.
      if(localStorage.getItem('dataIniciado')!="null"){
        this.data=JSON.parse(localStorage.getItem('dataIniciado'))
        localStorage.setItem("presentes",this.data.cantPresentes.toString());
      }
      localStorage.setItem("presentes",data.cantPresentes.toString());
 
    });


    
  
  }

  returnPresentes(){
    return(localStorage.getItem("presentes"));
  }

  activeOn(){
    localStorage.setItem('conferenceActive', JSON.stringify(true));
    this.conferenceActiveSubjet.next(true);
    //console.log(this.active)
  }

  activeOff(){
    localStorage.removeItem('conferenceActive');
    this.conferenceActiveSubjet.next(null);
    //console.log(this.active)
  }

  getActive(){
    //console.log( this.active);
    return this.active;
  }
}
