import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { LoginWithFingerprintService } from 'app/services/signal-r/loginWithFingerprint.service';
import { NotificationService } from 'app/services/notification/notification.service';

@Component({
  selector: 'app-enroll-admin',
  templateUrl: './enroll-admin.component.html',
  host: {'class': 'mdl-grid block p-5'},
  styleUrls: ['./enroll-admin.component.scss']
})
export class EnrollAdminComponent implements OnInit {

  public diputados:any[]= [];
  waitingEnroll: boolean = false;
  private attemps = 0;
  public isSelected = false;
  public currentDiputado;
  public selectUser;
  public selectFinger;
  
  constructor(private http: HttpClient,public loginWithFingerprintService:LoginWithFingerprintService,private notifyService : NotificationService) { 
    this.loginWithFingerprintService.startConnection(this) 
  }

  ngOnInit(): void {
    this.selectUser = '0';
    this.selectFinger = '0';
    this.http.get(`${environment.apiBaseUrl}/api/users/allMinimal`).subscribe((data:any)=>{
      this.diputados=data;
    });
    this.loginWithFingerprintService.startListeningEnrollByAdmin(this)
  }

  public startEnroll(value){
    //console.log("Finger selected: " + value)
    this.isSelected = true
    if(this.currentDiputado != null){
      this.loginWithFingerprintService.AddToEnrollListByAdmin(this.currentDiputado,value)
      this.waitingEnroll = true
    }
  }

  public selectDiputado(id){
    this.currentDiputado = id;
  }

  public succefullFingerprint(message){
    this.attemps++
    this.notifyService.showSuccess(message,"")
    if (this.attemps == 3){
      this.waitingEnroll = false
     // console.log("Huella registrada correctamente")
      this.notifyService.showSuccess("Huella registrada correctamente","")
      this.selectUser = '0';
      this.selectFinger = '0';
      this.isSelected = false
      this.attemps = 0
    }
  }

  public failFingerprint(message){
    this.notifyService.showError(message,"");
}
  
  public cancelEnroll(){
    this.http.get(`${environment.apiBaseUrl}/api/users/cancelEnrollByAdmin`).subscribe()
    this.selectUser = '0';
    this.selectFinger = '0';
    this.waitingEnroll = false
    this.isSelected = false
    this.attemps = 0
    this.notifyService.showError("Registro de huella cancelado","")
  }

  public enabled(){
    return (this.isSelected || !this.currentDiputado )
  }
}
