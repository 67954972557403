import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { BlankLayoutCardComponent } from 'app/components/blank-layout-card';
import { AuthService } from 'app/services';
import { Subscription } from 'rxjs';
import { User } from 'app/models';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-off-sesion',
  templateUrl: './off-sesion.component.html',
  host: {'class': 'mdl-grid block p-5'},
  styleUrls: ['../components/blank-layout-card/blank-layout-card.component.scss']
})
export class OffSesionComponent extends BlankLayoutCardComponent implements OnInit {
  currentUser: User;
  currentUserSubscription: Subscription;
  admin: boolean=false;
  constructor(
    private router: Router,
    private authservice: AuthService,
  ) { super()
    this.currentUserSubscription = this.authservice.currentUser.subscribe(user => {
      this.currentUser = user;
    });}

  ngOnInit(): void {
    if(this.currentUser.rol=="UADMIN" || this.currentUser.rol=="USUREL" ){
      this.admin=true;
    }else{
      this.admin=false;
    }
  }

  toHome(){
    if(this.admin){
      localStorage.setItem('conferenceCode', JSON.stringify(CryptoJS.AES.encrypt("true","codigoRenaper_secret").toString()));
      this.router.navigate(['app/bifurcacion']);
    }
  }
  public logout() {
    this.router.navigate(['/login']);
  }
}
