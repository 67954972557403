import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from 'app/services/notification/notification.service';
import { LoginWithFingerprintService } from 'app/services/signal-r/loginWithFingerprint.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-enroll-fingerprint',
  templateUrl: './enroll-fingerprint.component.html',
  host: {'class': 'mdl-grid block p-5'},
  styleUrls: ['./enroll-fingerprint.component.scss']
})
export class EnrollFingerprintComponent implements OnInit {
  private cant = 3;
  private attemps = 0;
  public isSelected = false;
  public selectFinger;

  constructor(public loginWithFingerprintService:LoginWithFingerprintService, private notifyService : NotificationService, private router: Router, private http: HttpClient) {}

  ngOnInit(): void {
    this.selectFinger = '0';
    this.loginWithFingerprintService.startConnection(this) 
  }

  public succefullFingerprint(message){
    //console.log(message)
    this.attemps++
    this.notifyService.showSuccess(message,"")
    if (this.attemps == this.cant){
      this.notifyService.showSuccess("Huella registrada correctamente","")
      this.loginWithFingerprintService.closeConnection()
      this.goToLogin()
    }
  }

  public failFingerprint(message){
      this.notifyService.showError(message,"");
  }

  public goToLogin(){
    this.loginWithFingerprintService.determineLocalIpByUrl()
    .then((localIp) => this.router.navigate(['/loginWithFingerprint'], { queryParams: { ip: localIp } }))    
  }

  public startEnroll(value){
    //console.log("Finger selected: " + value)
    this.isSelected = true
    this.loginWithFingerprintService.determineLocalIpByUrl()
    .then((localIp) =>  this.loginWithFingerprintService.addToEnrollList(localIp,value))
    .then(() => this.loginWithFingerprintService.startListeningEnroll(this))
  }

  public reconnect(){
    // Intenta Reconectar a los 3seg
    return new Promise(function(resolve, reject) {  setTimeout(resolve, 3000)})
    .then(() => {
      //console.log("Intentando reconexion")
      //Al terminar el Timeout (A los 3seg) Intentará Reconectar
      this.loginWithFingerprintService.connect()
    })
  }

  public cancelEnroll(){
    this.loginWithFingerprintService.determineLocalIpByUrl()
    .then((localIp) => this.http.get(`${environment.apiBaseUrl}/api/users/cancelEnroll/${localIp}`).subscribe())
    this.selectFinger = '0';
    this.isSelected = false
    this.loginWithFingerprintService.waitingEnroll = false
    this.attemps = 0
    this.notifyService.showError("Registro de huella cancelado","")
  }
}
