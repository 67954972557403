import { Component, HostBinding, OnInit, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SignalRService } from 'app/services/signal-r/signal-r.service';
import { MensajeHub } from '../../models/mensajeHub';
import { ConferenceService } from 'app/services/conference/conference.service';
import { BlankLayoutCardComponent } from 'app/components/blank-layout-card';
import { UpgradableComponent } from 'theme/components/upgradable';
import { User } from 'app/models';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth/auth.service';
import { VotacionService } from 'app/services/votacionService';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { VotacionModalResultadoService } from 'app/services/signal-r/votacion-modal-resultado.service';
import { GrillaService } from 'app/services/grilla.service';
import { SesionInstanciaActiva } from 'app/models/sesionInstanciaActiva';
import { SesionService } from 'app/services/sesion.service';


@Component({
  selector: 'app-hemiciclo-con-colores',
  templateUrl: './hemiciclo-con-colores.component.html',
  styleUrls: ['./hemiciclo-con-colores.component.scss']
})
export class HemicicloConColoresComponent extends UpgradableComponent implements OnInit, OnDestroy {
  @HostBinding('class.mdl-grid') private readonly mdlGrid = true;
  @HostBinding('class.mdl-grid--no-spacing') private readonly mdlGridNoSpacing = true;

  @HostListener('window:unload', ['$event'])

  unloadHander($event) {
    this.SignalR.closeConnection();
    return $event.returnValue = 'Your changes will not be saved';
  }

  @ViewChild('votacioncurso', { static: false }) contenidoDelModal: ElementRef;

  closeResult = '';
  public diputados: any[] = [];
  public presentes: any[] = [];
  data: any;
  currentUser: User;
  scrollbol: boolean = false;
  hide: boolean = true
  cantVotosPositivos: number = 0
  cantVotosNegativos: number = 0
  cantVotosAbstenciones: number = 0
  votacionEnCurso: any;
  sesionActiva: SesionInstanciaActiva

  sub: Subscription;
  constructor(private http: HttpClient,
    public SignalR: SignalRService,
    public confeservice: ConferenceService,
    private authService: AuthService,
    private votacionCursoService: VotacionService,
    private modalService: NgbModal,
    private votacionModalService: VotacionModalResultadoService,
    public gr: GrillaService,
    private sesionService: SesionService) {
    super();
    this.authService.currentUser.subscribe(user => {
      this.currentUser = user;
    })
    this.diputados = [];
    this.presentes = [];


    this.cantVotosPositivos = 0
    this.cantVotosNegativos = 0
    this.cantVotosAbstenciones = 0
  }

  ngOnDestroy(): void {
    
  }

  ngOnInit(): void {

    this.loadSesion()
    this.http.get(`${environment.apiBaseUrl}/api/users/hemiciclo`).subscribe(
      (data: any) => {     
        this.diputados = data;
        this.http.get(`${environment.apiBaseUrl}/api/sesionInstanciaPresente/presentes`).subscribe(
          (data: any) => {
            this.presentes = data;
            for (var i = 0; i < this.diputados.length; i++) {
              for (var x = 0; x < this.presentes.length; x++) {
                if (this.diputados[i].id == this.presentes[x].usuario_id) {
                  this.diputados[i].presente = true;
                }
              }
            }
          });

        this.loadVoto();
      });
   this.socketAusentesPresentes()
   this.socketEmitirVoto()
  }

  loadSesion() {
    this.sesionService.getActiva().subscribe({
      next: (data) => {
        this.sesionActiva = data    
      },
      error: (err) => { },
    });
  }

  socketAusentesPresentes(){
    this.SignalR.getVotacion$().subscribe((data: MensajeHub) => {
      // el socket asiste o ausenta a los diputados 
      this.loadSesion()
      if (data.tipo == MensajeHub.CONST_TIPO_ENTRA_SESION || data.tipo == MensajeHub.CONST_TIPO_SALE_SESION) {
        var request = JSON.parse(data.mensajeJSON)
        for (var i = 0; i < this.diputados.length; i++) {
          this.diputados[i].presente = false;
          for (var x = 0; x < request.presentes.length; x++) {
            if (this.diputados[i].id == request.presentes[x]) {
              this.diputados[i].presente = true;
            }
          }
        }
      }

      if (data.tipo == MensajeHub.CONST_TIPO_INICIAR_VOTO) {
          this.loadVoto()
      }

      //si se finaliza o se anula la votacion, pinta todos los usuarios como estaban
      if (data.tipo == MensajeHub.CONST_TIPO_FINALIZAR_VOTO || data.tipo == MensajeHub.CONST_TIPO_ANULAR_VOTO) {
        this.close();
        this.hide = true
        this.diputados.forEach((x) => x.voto = null)
      }
    })
  }


socketEmitirVoto(){
//pinta en el hemiciclo el voto emitido
this.SignalR.getVoto$().subscribe((data: MensajeHub) => {
  var banca = data.mensajeJSON
  var tipo = data.tipo
  this.diputados.find(x => x.banca.id == banca).voto = tipo

  if (tipo == "VP") {
    this.cantVotosPositivos++
  } else if (tipo == "VN") {
    this.cantVotosNegativos++
  } else if (tipo == "VA") {
    this.cantVotosAbstenciones++
  }
  
  this.loadVoto()
  this.loadSesion()
})
  }

loadVoto() {
  
    this.sub = this.votacionCursoService.getVotacionEnCurso().subscribe({
      next: (votacionCurso) => {
        
        this.votacionEnCurso = votacionCurso
        if (votacionCurso) {
          //si la votacion esta en curso, carga a los usuarios con sus votaciones
          votacionCurso.diputados_positivas.forEach(x =>
            this.diputados.find(d => d.id == x).voto = 'VP')

          this.cantVotosPositivos = votacionCurso.cantPositivas

          votacionCurso.diputados_negativas.forEach(x =>
            this.diputados.find(d => d.id == x).voto = 'VN')
          this.cantVotosNegativos = votacionCurso.cantNegativas

          votacionCurso.diputados_abstenciones.forEach(x =>
            this.diputados.find(d => d.id == x).voto = 'VA')
          this.cantVotosAbstenciones = votacionCurso.cantAbstenciones




          //retorna un boolean si hay una votacion en curso y el usuario voto
          this.http.get(`${environment.apiBaseUrl}/api/votacion/votoDiputado/${this.currentUser.id}`).subscribe(
            (voto: any) => {
              if (this.hide && votacionCurso && voto) {
                this.hide = false
                this.scrollbol = true;
                this.open(this.contenidoDelModal);
              }
              else if (this.hide && votacionCurso && (this.currentUser.rol != "USUDIP") && (this.currentUser.rol != "UPRESI")) {
                this.hide = false
                this.scrollbol = true;
                this.open(this.contenidoDelModal);
              }
            });

        }
      },
      error: (err) => { },
    });
    //this.sub.unsubscribe()
  }







  open(votacionModal) {    
    this.modalService.open(votacionModal, { ariaLabelledBy: 'modal-basic-title', centered: true, keyboard: false, backdrop: "static", size: 'xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  close() {
    this.modalService.dismissAll()
  }


 

  finalizar() {
    
    this.votacionCursoService.getVotacionEnCurso().subscribe({
      next: (votacionCurso) => {
        if (votacionCurso) {
          this.http.put(`${environment.apiBaseUrl}/api/votacion/${votacionCurso.id}/finalizar`, "").subscribe(
            x => {
              localStorage.removeItem('nominal')
              localStorage.removeItem('voto_id_signo')
              this.votacionModalService.setVotacion(this.gr.getVotacionId(votacionCurso.id));

            }
          );

        }
      },
      error: (err) => { },
    });




  }


  scroll(el: HTMLElement) {
    if (this.scrollbol) {
      el.scrollIntoView({ behavior: 'smooth' });
      this.scrollbol = false;
    }
  }




}

