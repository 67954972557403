import { Component, OnInit } from '@angular/core';
import { GrillaService} from 'app/services/grilla.service'
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from '@services/*';
import { User } from 'app/models';
import { BlankLayoutCardComponent } from 'app/components/blank-layout-card';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActualizarVotacionesService } from 'app/services/signal-r/actualizarVotaciones';
import { VotacionModalResultadoService } from 'app/services/signal-r/votacion-modal-resultado.service';

@Component({
  selector: 'app-grilla',
  templateUrl: './grilla.component.html',
  styleUrls: ['./grilla.component.css']
})
export class GrillaComponent extends BlankLayoutCardComponent implements OnInit {
  closeResult = '';
  currentUser: User;
  cantVotosPostivios:any=0;
  cantVotosNegativos:any=0;
  cantVotosAbstenciones:any=0;
  currentUserSubscription: Subscription;
  admin: boolean = false;
  finalizado: boolean = false;
  nominal: boolean;
  mostrarV: boolean = false;
  mostrarN: string ='no hay';
  constructor(public gr: GrillaService,
              private modalService: NgbModal,
              private http:HttpClient,
              private router: Router,
              private authService: AuthService,
              private votacionesService: ActualizarVotacionesService,
              private votacionModalService: VotacionModalResultadoService) {
                super();
                this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
                  this.currentUser = user;
              })}

  ngOnInit(): void {
   if(localStorage.getItem('nominal')){
      this.mostrarN=localStorage.getItem('nominal');
    }
    if(this.currentUser.rol=="UADMIN" || this.currentUser.rol=="SECADM" || this.currentUser.rol=="SECLEG"||
    this.currentUser.rol=="USULEG"|| this.currentUser.rol=="UUJIER"){
      this.admin=true;
    }
    if(localStorage.getItem("votosNegativos")){
      this.cantVotosNegativos=localStorage.getItem("votosNegativos");
      this.cantVotosNegativos=parseInt(this.cantVotosNegativos)}
    if(localStorage.getItem("votosPositivos")){
      this.cantVotosPostivios=localStorage.getItem("votosPositivos");
      this.cantVotosPostivios=parseInt(this.cantVotosPostivios)
    }
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }
  ingresarNominal(){
    this.router.navigate(['/app/nominal'])
  }
  iniciarSigno(id){
    this.http.put(`${environment.apiBaseUrl}/api/votacion/${id}/iniciar/S`,"").subscribe(
      x=>{
      }
    );
  }
  iniciarNominal(id){    
    this.http.put(`${environment.apiBaseUrl}/api/votacion/${id}/iniciar/N`,"").subscribe(
      x=>{
        this.nominal=true;
        this.gr.loadGrilla();       
        localStorage.setItem("voto_id",id);        
        this.cantVotosNegativos=0;
        this.cantVotosPostivios=0;
        this.cantVotosAbstenciones=0;
        localStorage.setItem("votosNegativos",this.cantVotosNegativos);
        localStorage.setItem("votosAbstenciones",this.cantVotosAbstenciones);
        localStorage.setItem("votosPositivos",this.cantVotosPostivios);
        this.router.navigate(['/app/nominal']);
        this.finalizado=true;
      }
    );
  }

  finalizar(id){
    console.log('grilla-component: finaliza por grilla');
    this.http.put(`${environment.apiBaseUrl}/api/votacion/${id}/finalizar`,"").subscribe(
      x=>{     
        this.finalizado=true;
        localStorage.removeItem('disableArray')
        localStorage.removeItem('nominal')
        localStorage.removeItem('voto_id_signo')
        this.mostrarN='no hay';
        this.gr.loadGrilla();

        this.votacionModalService.setVotacion(this.getVotacionId(id));
       
      }
    );
  }

  anular(id){
    this.http.put(`${environment.apiBaseUrl}/api/votacion/${id}/anular`,"").subscribe(
      x=>{
        this.gr.loadGrilla();
      }
    );
  }

  parsearExpediente(item){
    if(item.id_expediente!=null){
    return (item.numero_exp +"/"+ item.anios_exp +"/"+ item.origen_exp);
    }
    else{
      return "-"
    }
  }

 
  parsearPositivas(item){
    return item.cantPositivas;
  }
  parsearNegativas(item){
    return item.cantNegativas;
  }
  parsearPresentes(item){
    return item.cantPresentes;
  }
  parsearAbstenciones(item){
    return item.cantAbstenciones;
  }
  parsearDetalle(item){
    return item.descripcion;
  }

  
  mostrarVotacion(id){
    this.http.get(`${environment.apiBaseUrl}/api/votacion/${id}/hemiciclo`).subscribe(
      (data:any)=>{
        this.mostrarV=true;
      });
  }
  cerrarVotacion(id){
    this.http.get(`${environment.apiBaseUrl}/api/votacion/cerrarHemiciclo`).subscribe(
      (data:any)=>{
        this.mostrarV=false;
      });
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getVotacionId(id):any{
    return this.gr.elements.find(x=> x.id == id)
  }

  abrirResultado(votacion){
    this.votacionModalService.setVotacion(this.gr.getVotacionId(votacion.id));
  }
  
}
