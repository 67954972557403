import { Injectable, ErrorHandler, Injector, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate,Router, RouterStateSnapshot } from '@angular/router';
import { ConferenceService } from 'app/services/conference/conference.service.js';
import { AuthService } from './auth.service';
import { NotificationService } from '../../services/notification/notification.service';
import { zip } from 'rxjs';
import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root',
})
export class AuthGuardForAdmin implements CanActivate{
  private check: boolean = true;
  private toRenaper: boolean;
  constructor(private authService: AuthService,
              private authenticationService: AuthService,
              private router: Router,
              private notifyService : NotificationService,
              private confeservice: ConferenceService,) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser.rol == "UADMIN"){
        // authorised so return true
        return true;
    }else{
    // not logged in so redirect to login page with the return url
        this.router.navigate(['/app/dashboard']);
        this.showToasterError("Acceso denegado a esta pagina");
        return false;
    };
  }
  
  showToasterError(msj1,msj2=""){
    if(this.check==true){
      this.notifyService.showError(msj1,msj2);
      this.check=false;
      return;
    }
    this.check=true;
  }
}
