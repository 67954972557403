import { Injectable, ErrorHandler, Injector, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate,Router, RouterStateSnapshot } from '@angular/router';
import { ConferenceService } from 'app/services/conference/conference.service.js';
import { AuthService } from './auth.service';
import { NotificationService } from '../../services/notification/notification.service';
import { zip } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root',
})
export class AuthGuardForSesion implements CanActivate{
  private check: boolean = true;
  private toRenaper: boolean;
  private data: any;
  constructor(private authService: AuthService,
              private authenticationService: AuthService,
              private router: Router,
              private http: HttpClient,
              private notifyService : NotificationService,
              private confeservice: ConferenceService,) {
  }

  ngOnInit(): void {
  }
  canActivate(route: ActivatedRouteSnapshot) {
      if(localStorage.getItem('dataIniciado')){
        this.data=JSON.parse(localStorage.getItem('dataIniciado'))
        const currentUser = this.authenticationService.currentUserValue;
        if(currentUser && currentUser.rol!="UADMIN" && currentUser.rol!="OBSERV"){
          if(currentUser.rol!="USUREL"){
            if (currentUser && this.data.fechaHoraInicia  && !this.data.fechaHoraFinaliza){
                // authorised so return true
                return true;
            }else{
            // not logged in so redirect to login page with the return url
                //this.router.navigate(['/login']);
                this.showToasterError("Acceso denegado a esta pagina");
                return false;
            };
          }else{
              this.showToasterError("Acceso denegado a esta pagina");
              return false;
          }
        }else{
            return true
        }
    }}

  showToasterError(msj1,msj2=""){
    if(this.check==true){
      this.notifyService.showError(msj1,msj2);
      this.check=false;
      return;
    }
    this.check=true;
  }
}