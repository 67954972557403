import { Component, OnInit } from "@angular/core";
import { BlankLayoutCardComponent } from "app/components/blank-layout-card";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { User } from "app/models";
import { AuthService } from "app/services";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { Console } from "console";

@Component({
  selector: "app-bifurcacion",
  templateUrl: "./bifurcacion.component.html",
  host: { class: "mdl-grid block p-5" },
  styleUrls: [
    "./bifurcacion.component.scss",
    "../components/blank-layout-card/blank-layout-card.component.scss",
  ],
})
export class BifurcacionComponent
  extends BlankLayoutCardComponent
  implements OnInit
{
  comision: any;
  sesion: any;
  goSesion: boolean;
  currentUser: User;
  goComision: boolean;
  currentUserSubscription: Subscription;
  constructor(
    private router: Router,
    private authService: AuthService,
    private http: HttpClient
  ) {
    super();
    this.currentUserSubscription = this.authService.currentUser.subscribe(
      (user) => {
        this.currentUser = user;
      }
    );
  }

  ngOnInit(): void {
    this.comision = localStorage.getItem("dataComision");
    this.sesion = JSON.parse(localStorage.getItem("dataIniciado"));
   

    switch (this.currentUser.rol) {
      case "UADMIN": {
        this.goSesion = true;
        this.goComision = false;
        break;
      }
      case "USUREL": {
        this.goSesion = false;
        this.goComision = true;
        break;
      }
      case "OBSERV": {
        this.goSesion = true;
        this.goComision = false;
        break;
      }
      /*       case "USUDIP": { 
        if(!this.sesion.fechaHoraInicia && !this.sesion.fechaHoraFinaliza){
          this.goSesion=false
        }
        else{
          this.goSesion=true
        }
        if(this.comision == 'null'){
          this.goComision=true
        }
        else{
          this.goComision=false
        }
        break; 
      }  */
      default: {
        if (!this.sesion.fechaHoraInicia && !this.sesion.fechaHoraFinaliza) {
          this.goSesion = false;
        } else {
          this.goSesion = true;
        }
        if (this.comision == "null") {
          this.goComision = true;
        } else {
          this.goComision = false;
        }
        break;
      }
    }
  }

  toSesion() {
    
    switch (this.currentUser.rol) {
      
      /*       case "UADMIN": { 
        this.router.navigate(['/app/dashboard'])
        break; 
      }
      case "USUREL": { 
        this.router.navigate(['/app/dashboard'])
        break; 
      } */
      case "USUDIP": {
        this.router.navigate(["/app/index-remote"]);
        break;
      }
      case "UPRESI": {
        this.router.navigate(["/app/presidente"]);
        break;
      }
      case "SECADM": {
        this.router.navigate(["/app/secretarioadministrativo"]);
        break;
      }
      case "UUJIER": {
        this.router.navigate(["/app/ujier"]);
        break;
      }
      case "UADMIN": {
        this.router.navigate(["/app/dashboard"]);
        break;
      }
      case "OBSERV": {
        this.router.navigate(["/app/observador"]);
        break;
      }
      case "USULEG": {
        this.router.navigate(["/app/usuariolegislativo"]);
        break;
      }
      case "SECLEG": { 
        this.router.navigate(["/app/secretariolegislativo"]);
        break;
      }
      default: {
        this.router.navigate(["/app/dashboard"]);
        break;
      }
    }
  }

  toComision() {
    this.router.navigate(["/app/dashboard-comision"]);
  }

  public logout() {
    this.router.navigate(["/login"]);
  }
}
