import { Component, OnInit } from '@angular/core';
import { MensajeHub } from 'app/models/mensajeHub';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { SignalRService } from 'app/services/signal-r/signal-r.service';
import { ConferenceService } from 'app/services/conference/conference.service';
import { Router } from '@angular/router';
import { GrillaService } from 'app/services/grilla.service';
import { NotificationService } from 'app/services/notification/notification.service';
import { delay } from 'rxjs/operators';
import { BlankLayoutCardComponent } from 'app/components/blank-layout-card';
import { UpgradableComponent } from 'theme/components/upgradable';

import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {Location} from '@angular/common';
import { VotacionModalResultadoService } from 'app/services/signal-r/votacion-modal-resultado.service';
import { VotacionService } from 'app/services/votacionService';
import { User } from 'app/models';
import { AuthService } from 'app/services';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-nominal',
  templateUrl: './nominal.component.html',
  host: {'class': 'blank-layout-card mdl-grid ui-components'},
  styleUrls: ['./nominal.component.scss']
})
export class NominalComponent extends UpgradableComponent implements OnInit {
  p:any;
  filtroNominal:any="";
  filtroAusentes:any="";
  public diputados:any[]= [];
  public ausentes:any[]= [];
  public quorum:string = "";
  fecha_sesion: string;
  nro_sesion: string;
  nro_periodo: string;
  disable: boolean[]=[];
  colorVoto: string [] = [];
  check: boolean = true;
  clicked: boolean = false;
  currentUser: User;
  currentUserSubscription: Subscription;



  constructor(private http: HttpClient,
              public SignalR:SignalRService,              
              public router: Router,
              public gr: GrillaService,
              private notifyService : NotificationService,
              public confeservice: ConferenceService,
              private votacionModalService: VotacionModalResultadoService,
              private _location: Location,
              private authService: AuthService,
              public votacionService:VotacionService) {
                super();
                this.diputados=[];
                this.ausentes=[];              
                this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
                  this.currentUser = user;
              })
              
   }

  ngOnInit(): void {  
    localStorage.setItem('nominal','en curso');
    if(localStorage.getItem("voto_id")){
      this.http.get(`${environment.apiBaseUrl}/api/votacion/${localStorage.getItem("voto_id")}/presentes`).subscribe(
        (data:any)=>{
        
          
          this.diputados=data.presentes;
          this.ausentes=data.ausentes;
          
          if(localStorage.getItem('disableArray')){
            this.disable=JSON.parse(localStorage.getItem('disableArray'));
          }else{
            for(let i=0; i < this.diputados.length; i++){
             if(!this.diputados[i].tieneVoto){
                this.disable[this.diputados[i].usuario_id]=false;
              }else{
                this.disable[this.diputados[i].usuario_id]=true;
              }             
            }
            localStorage.setItem('disableArray', JSON.stringify(this.disable));
          }
          

          if(localStorage.getItem('colorVotoArray')){
            this.colorVoto = JSON.parse(localStorage.getItem('colorVotoArray'));
          }else{
            this.colorVoto = Array(this.diputados.length + this.ausentes.length).fill('white');
            localStorage.setItem('colorVotoArray', JSON.stringify(this.colorVoto));
          }


          
        });        

    }else{
        this.router.navigate(['/app/dashboard']);
        this.toaster()
    }

    this.SignalR.getVotacion$().subscribe((data:MensajeHub)=>{
      if (data.tipo == MensajeHub.CONST_TIPO_ENTRA_SESION || data.tipo == MensajeHub.CONST_TIPO_SALE_SESION)
      {
        var mensajeOBJ: any;
        mensajeOBJ=JSON.parse(data.mensajeJSON);

      }
    })
    
    

  }

  public onInputChange(event) {
    event.target.required = true;
  }

  toaster(){
    if(this.check==true){
      this.notifyService.showError("Aun no hay una votacion nominal en curso!","")
      this.check=false;
      return;
    }
    this.check=true;
  }

  agregar(id){
    //this.diputados.push(this.ausentes[num]);
    this.http.get(`${environment.apiBaseUrl}/api/votacionPresente/${localStorage.getItem("voto_id")}/transferir/${id}`).subscribe(
      data=>{
        
        this.loadDiputados();
        
      },
      error=>{
        
        this.notifyService.showError(error.error,"")
      });
  }

  afirmativo(id){
    var votacionId = this.gr.getVotacionId(localStorage.getItem("voto_id")).id
     this.votacionService.votoAfirmativo(votacionId,id).subscribe({
        next: (voto) => {
          this.disable[id]=true
          this.colorVoto[id] = '#00d45a'
          localStorage.setItem('disableArray', JSON.stringify(this.disable));
          localStorage.setItem('colorVotoArray', JSON.stringify(this.colorVoto));
        },
        error: (err) => { },
      });    
  }
  
  negativo(id){
    var votacionId = this.gr.getVotacionId(localStorage.getItem("voto_id")).id
       this.votacionService.votoNegativo(votacionId,id).subscribe({
        next: (voto) => {
          this.disable[id]=true
          this.colorVoto[id] = '#f44336'
          localStorage.setItem('disableArray', JSON.stringify(this.disable));
          localStorage.setItem('colorVotoArray', JSON.stringify(this.colorVoto));
        },
        error: (err) => { },
      });    
  }

  abstencion(id){
    var votacionId = this.gr.getVotacionId(localStorage.getItem("voto_id")).id
        this.votacionService.votoAbstencion(votacionId,id).subscribe({
        next: (voto) => {
          this.disable[id]=true
          this.colorVoto[id] = "#565656";
          localStorage.setItem('disableArray', JSON.stringify(this.disable));
          localStorage.setItem('colorVotoArray', JSON.stringify(this.colorVoto));
        },
        error: (err) => { },
      });    
  }

  eliminar(id){
    if(confirm("Estás seguro que quiere eliminar este usuario?")){
    this.http.delete(`${environment.apiBaseUrl}/api/votacionPresente/${id}`).subscribe(
      x=>{ 
        this.loadDiputados();
      })
    }
  }

  limpiar(id){
    var votacionId = this.gr.getVotacionId(localStorage.getItem("voto_id")).id
    if(confirm("Estás seguro que quiere limpiar este usuario?")){
       this.votacionService.limpiarVoto(votacionId,id).subscribe({
          next: (voto) => {
            this.disable[id]=false
            this.colorVoto[id] = "white"
            localStorage.setItem('disableArray', JSON.stringify(this.disable));
            localStorage.setItem('colorVotoArray', JSON.stringify(this.colorVoto));
          },
          error: (err) => { },
        });
      }
  }

  finalizar(){
  var votacionId = this.gr.getVotacionId(localStorage.getItem("voto_id")).id 
  this.clicked = true;
  this.votacionService.finalizar(votacionId).subscribe({
     next: (voto) =>  {    

      localStorage.removeItem('disableArray');
      localStorage.removeItem('colorVotoArray');
      localStorage.removeItem('voto_id');
      localStorage.removeItem('nominal'); 
      this.votacionModalService.setVotacion(this.gr.getVotacionId(votacionId));
     
     
      //this._location.back();
     },
     error: (err) => {  },
   });

  }
  
  loadDisable(){
    this.disable=JSON.parse(localStorage.getItem('disableArray'));
  }
  
  loadDiputados(){
    this.http.get(`${environment.apiBaseUrl}/api/votacion/${localStorage.getItem("voto_id")}/presentes`).subscribe(
      (data:any)=>{
        
        this.diputados=data.presentes;
        this.ausentes=data.ausentes;
        
        for(let i=0; i < this.diputados.length; i++){
          if(!this.diputados[i].tieneVoto){
             this.disable[this.diputados[i].usuario_id]=false;
           }else{
             this.disable[this.diputados[i].usuario_id]=true;
           }
         }
         localStorage.setItem('disableArray', JSON.stringify(this.disable));
      })
  }

  redirectToGrilla(){
    switch(this.currentUser.rol) { 
      case "SECADM": { 
        this.router.navigate(['/app/secretarioadministrativo']) 
         break; 
      } 
      case "SECLEG": { 
        this.router.navigate(['/app/secretariolegislativo']) 
         break; 
      } 
      default: { 
        this.router.navigate(['/app/dashboard']) 
         break; 
      }
    }    
  }

  inicializarColorVoto(){   
   let colorVoto: string[] = new Array(this.disable.length).fill('white');
    
     console.log(this.colorVoto)
  }


}

