import { NgModule } from '@angular/core';
import { Component, OnInit} from '@angular/core';
import { UpgradableComponent } from 'theme/components/upgradable';
import { OradoresService } from 'app/services/oradores.service';

import { Oradores } from 'app/models/oradores';

//drag n drop
import {CdkDrag, DragDropModule, copyArrayItem} from '@angular/cdk/drag-drop';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { SignalRService } from 'app/services/signal-r/signal-r.service';
import { MensajeHub } from 'app/models/mensajeHub';






@Component({
  selector: 'app-oradores',
  templateUrl: './oradores.component.html',
  styleUrls: ['./oradores.component.scss'],
  
})

export class OradoresComponent extends UpgradableComponent implements OnInit {

  diputados: Oradores[] = [];
  oradores: Oradores [] = [];
  sesion: any;
  num: number = 0;
  i: number;
  autoScrollDisabled: boolean


  constructor
  (
    private oradoresService: OradoresService,
    public SignalR: SignalRService
  
  ) {
    super();
    }

  ngOnInit(): void {

    if(localStorage.getItem("dataIniciado")){
      this.sesion =JSON.parse(localStorage.getItem("dataIniciado"))
    } 

    this.loadData();     

    this.oradoresService.getDiputados().subscribe(data =>this.diputados = data) 

    this.SignalR.getOradores$().subscribe((data: MensajeHub) => {
      if (data.tipo == MensajeHub.CONST_TIPO_ORADORES_UPDATE) 
        {
        this.loadData()
        }
      })
  }

  loadData(){
    this.oradoresService.getOradores(this.sesion.id_sesion).subscribe(data =>this.oradores = data)
  }




  //reordena los oradores
    drop(event: CdkDragDrop<Oradores[]>) {
      if (event.previousContainer === event.container) {          
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {                   
                     
        copyArrayItem(event.previousContainer.data,
                          event.container.data,
                          event.previousIndex,
                          event.currentIndex);
      }
      this.oradoresService.enviarOradores(this.oradores, this.sesion.id_sesion)
    }

    borrarOrador(element: Oradores)
    {
      this.oradores.splice(this.oradores.indexOf(element),1)
      this.oradoresService.enviarOradores(this.oradores, this.sesion.id_sesion)    
    }

    // actualizarCurrentIndex()
    // {
    //   this.oradores.forEach(element => 
    //   {
    //     element.currentIndex = this.oradores.indexOf(element);
      
    //   });
    // }
}




