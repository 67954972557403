import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { SignalRService } from 'app/services/signal-r/signal-r.service';
import { MensajeHub } from '../models/mensajeHub';
import * as jsPDF from 'jspdf';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-sumario-presencial',
  templateUrl: './sumarioPresencial.component.html',
  styleUrls: ['./sumarioPresencial.component.css']
})
export class SumarioPresencialComponent implements OnInit {
  constructor(private http: HttpClient,
    public SignalR: SignalRService) {}
  fecha_sesion: string;
  nro_sesion: string;
  nro_periodo: string;
  ordenDelDiaURL: string;
  sumarioURL: string;
  idSesion:any;
  data:any="";
  srcOrden:any = null;
  srcSumario:any = null;
  archivoURL: string | null = null;
  showSumario:boolean=false;
  showOrden:boolean=false;


  ngOnInit(): void {
    // Misma observacion que en sumario remoto
    /* if(localStorage.getItem("dataIniciado")){
      this.data=JSON.parse(localStorage.getItem("dataIniciado"))
      
      this.idSesion=this.data.id_sesion
        this.sumarioURL=this.data.sumarioUrl;
        this.ordenDelDiaURL=this.data.ordenDelDiaUrl;
        if(this.data.fecha_sesion){
          this.fecha_sesion=this.data.fecha_sesion;
          
          }
        if(this.data.nro_periodo){
          this.nro_periodo=this.data.nro_periodo;
          }
        if(this.data.nro_sesion){
          this.nro_sesion=this.data.nro_sesion;
          }
    }else{ */
        this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/iniciado`).subscribe((data:any)=>{
          if(data){
            this.idSesion=data.id_sesion;
            if (data.sumarioUrl){
              this.verArchivo(data.sumarioUrl).subscribe(url => {
                this.srcSumario = url;
              });
              this.sumarioURL=data.sumarioUrl;
            }if (data.ordenDelDiaUrl){
              this.verArchivo(data.ordenDelDiaUrl).subscribe(url => {
                this.srcOrden = url;
              });
              this.ordenDelDiaURL=data.ordenDelDiaUrl;
            }
          }
        });
    /* } */

    this.SignalR.getArchivo$().subscribe((data: MensajeHub) => {
      if (data.tipo == MensajeHub.ARCHIVO) {
        let arch = JSON.parse(data.mensajeJSON);
        if (arch.Tipo == "sumarioFile"){
          this.verArchivo(arch.Nombre).subscribe(url => {
            this.srcSumario = url;
          });
          this.sumarioURL = arch.Nombre;
        }else if (arch.Tipo == "ordenFile"){
          this.verArchivo(arch.Nombre).subscribe(url => {
            this.srcOrden = url;
          });
          this.ordenDelDiaURL = arch.Nombre;
        }else if (arch.Tipo == "eliminarSumario"){
          this.sumarioURL = null;
          this.showSumario = false;
        }else if (arch.Tipo == "eliminarOrden"){
          this.ordenDelDiaURL = null;
          this.showOrden = false;
        };
      }
    })

    this.SignalR.startListeningToArchivos();
  }

  verArchivo(nombreArchivo: string): Observable<string> {
    return this.http.get(`${environment.apiBaseUrl}/api/archivo/descargar-archivo/${nombreArchivo}`, { responseType: 'arraybuffer' }).pipe(
      map(response => {
        const blob = new Blob([response], { type: 'application/pdf' });
        return URL.createObjectURL(blob);
      })
    );
  }
  
  mostrarOrden(){
    this.showOrden=true
    this.showSumario=false
  }

  mostrarSumario(){
    this.showSumario=true
    this.showOrden=false
  }

  reporte(){
    var cadena:any="";
    var doc = new jsPDF()
    var img = new Image()
    var img2 = new Image();
    var img3 = new Image();
    var posHorCant = 5
    var posHorNombre = 10
    var posHorBanca = 85
    var posHorBloque = 145
    var posVer = 85
    var cant = 1

    img.src="../../assets/logosSesionar/logosParaPdf.jpeg";
    img2.src="../../assets/logosSesionar/honorableCamDip.jpeg";
    doc.addImage(img, "JPEG",25,283, 165, 15);
    doc.addImage(img2, "JPEG",85,0,40, 35);
    doc.setFontSize(12);
    doc.setFontStyle('italic');
    doc.setFontType("bold");
    this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/reporte_usuarios/${this.idSesion}`).subscribe((data:any)=>{
        doc.setFontSize(20);
        doc.text(`Presentes en la Sesion ${this.idSesion}:`,5,45)
        doc.setFontSize(10);
        doc.text("Fecha de Sesion: "+this.fecha_sesion+"\n"+"Numero de Periodo: "+this.nro_periodo+"\n"+"Numero de Sesion: "+this.nro_sesion,10,55)
        doc.setFontSize(12);
        doc.text("Nombre                                                 Banca                                             Bloque",10,75)
      doc.setFontSize(8);
      doc.setFontType("");
      for (let elemento of data){
        doc.text(`${cant}. `,posHorCant,posVer)
        if(elemento.banca != 0){
          doc.text(elemento.apellido+" "+elemento.nombre,posHorNombre,posVer)
          doc.text(`${elemento.banca}`,posHorBanca,posVer)
          doc.text(elemento.bloqueNombre,posHorBloque,posVer)
          //cadena=cadena+elemento.apellido+" "+elemento.nombre+"                                                                                      "+elemento.banca.id+"                                                       "+elemento.bloqueNombre+"\n"+"\n"
        }else{
          doc.text(elemento.apellido+" "+elemento.nombre,posHorNombre,posVer)
          doc.text("Sin Banca",posHorBanca,posVer)
          doc.text(elemento.bloqueNombre,posHorBloque,posVer)
          //cadena=cadena+elemento.apellido+" "+elemento.nombre+"                                                                           "+"Sin Banca"+"                                             "+elemento.bloqueNombre+"\n"+"\n"
        }
        posVer=posVer+3
        cant=cant+1
        
      }
      doc.setFontSize(10);
      doc.setFontType("bold");
      doc.text(`Cantidad de diputados presentes: ${cant - 1}`, 10,280)
      doc.save(`reporte_de_sesion_${this.idSesion}.pdf`)
      })
  }
}
