import { Component, HostBinding, OnInit, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { UpgradableComponent } from 'theme/components/upgradable';
import { Observable, Subject, Subscription } from 'rxjs';
import { SignalRService } from 'app/services/signal-r/signal-r.service';

import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@services/*';
import { User } from 'app/models';
import { MensajeHub } from '../../models/mensajeHub';
import { GrillaService} from 'app/services/grilla.service';
import { NotificationService } from 'app/services/notification/notification.service';
import { delay } from 'rxjs/operators';
import { ConferenceService } from 'app/services/conference/conference.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { VotacionService } from 'app/services/votacionService';

@Component({
  selector: 'app-votacion-modal',
  templateUrl: './votacion-modal.component.html',
  styleUrls: ['./votacion-modal.component.scss']
})
export class VotacionModalComponent extends UpgradableComponent implements OnInit,OnDestroy{
  @HostBinding('class.mdl-grid') private readonly mdlGrid = true;
  @HostBinding('class.mdl-grid--no-spacing') private readonly mdlGridNoSpacing = true;
  @HostListener('window:unload', ['$event'])
  unloadHander($event) {
    this.SignalR.closeConnection();
    return $event.returnValue='Your changes will not be saved';
  }

  @ViewChild('votacionmodal', {static: false}) contenidoDelModal: ElementRef;
  closeResult = '';
  hide:boolean = true;
  voto:any;
  currentUser: User;
  currentUserSubscription: Subscription;
  admin: boolean = false;
  cantVotosPostivios:any=0;
  cantVotosNegativos:any=0;
  cantVotosAbstenciones:any=0;
  check: any = true;
  toasWcheck: boolean;
  toasScheck: boolean;
  toasNcheck: boolean;
  toasPcheck: boolean;
  toasEcheck: boolean;
  toasAcheck: boolean;
  mostrarVotacion: any=false;
  scrollbol:boolean=false;
  modalReference:any;
  
  constructor(
    private modalService: NgbModal,
    public SignalR:SignalRService,
    private http:HttpClient,
    private notifyService : NotificationService,
    private authService: AuthService,
    public gr: GrillaService,
    public votacionService:VotacionService) {
      super();
      this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      this.currentUser = user;
    })
  }

  ngOnDestroy() {
  };

  scroll(el: HTMLElement) {
    if(this.scrollbol){
      el.scrollIntoView({behavior: 'smooth'});
      this.scrollbol=false;
    }
  }

  ngOnInit(): void {
   
   
    // Analizo mensajes desde la API.
    this.SignalR.getVotacion$().subscribe((data:MensajeHub)=>{
      //console.log("signal"+this.hide)
      if (data.tipo == MensajeHub.CONST_TIPO_INICIAR_VOTO)
      {
        delay(2000)
        this.toasWcheck=true
        this.showToasterEfectuar(`Atención! Efectúe su voto.`);
        this.loadVoto();        
        this.scrollbol=true;
        
      }

      else if (data.tipo == MensajeHub.CONST_TIPO_FINALIZAR_VOTO)
      {
        delay(2000)
        this.toasEcheck=true
        this.showToasterFinalizado(`Ha finalizado el tiempo de votación.`);
        this.close();
        this.hide=true;
      }
      else if (data.tipo == MensajeHub.CONST_TIPO_ANULAR_VOTO)
      {
      
        delay(2000)
        this.toasAcheck=true
        this.showToasterAnulado(`La votación ha sido anulada`);
        this.close();
        this.hide=true;
      }
      else if (data.tipo == MensajeHub.CONST_TIPO_VOTO_HEMICICLO)
      {

        this.mostrarVotacion=true;

      }
      else if (data.tipo == MensajeHub.CONST_TIPO_CERRAR_VOTO_HEMICICLO)
      {
        this.mostrarVotacion=false;
      }
    })

    if(this.hide){
      this.loadVoto();
    }
    
    this.SignalR.startListeningNewLogoutRequest(this)
  }


  loadVoto(){
    this.http.get(`${environment.apiBaseUrl}/api/votacion/iniciado/${this.currentUser.id}`).subscribe(
      (data:any)=>{
        //con este metodo checkea si el usuario ya realizo o no un voto.
        this.voto=data;
        
        if(this.voto != null && this.voto.tipo=="S"){
          if(this.voto.fechaHoraFinaliza){
            this.hide=true;  
          }    

          else if(this.voto.fechaHoraInicia && this.voto.fechaHoraFinaliza == null){
              this.hide=false;
              this.open(this.contenidoDelModal);
            }
         }
      
    });
  }
  afirmativo(){
    this.votacionService.votoAfirmativo(this.voto.id,this.currentUser.id).subscribe({
      next: (voto) => {
        this.hide=!this.hide;
          delay(2000)
          this.toasPcheck=true
          this.showToasterAfirmativo(`Se ha ingresado su voto POSITIVO`);
          this.close();
        

        this.loadVoto();
      },
      error: (err) => { },
    });
  };

  negativo(){

    this.votacionService.votoNegativo(this.voto.id,this.currentUser.id).subscribe({
      next: (voto) => {
        this.hide=!this.hide;
        delay(2000)
        this.toasPcheck=true
        this.showToasterAfirmativo(`Se ha ingresado su voto NEGATIVO`);
        this.close();
        

        this.loadVoto();
      },
      error: (err) => { },
    });
  }

  abstencion(){
   
    this.votacionService.votoAbstencion(this.voto.id,this.currentUser.id).subscribe({
      next: (voto) => {
        this.hide=!this.hide;
  
          delay(2000)
          this.toasPcheck=true
          this.showToasterAfirmativo(`Se ha ingresado su voto ABSTENCION`);
          this.close();
        

        this.loadVoto();
      },
      error: (err) => { },
    });
  }

  stopConnectio(){
    this.SignalR.closeConnection();
  }

  showToasterFinalizado(msj1,msj2=""){
    if(this.toasEcheck==true){
      this.notifyService.showError(msj1,msj2);
      this.toasEcheck=false;
      return;
   }
  }

  showToasterAnulado(msj1,msj2=""){
    if(this.toasAcheck==true){
      this.notifyService.showError(msj1,msj2);
      this.toasAcheck=false;
      return;
   }
  }
  showToasterEfectuar(msj1,msj2=""){
    if(this.toasWcheck==true){
      this.notifyService.showWarning(msj1,msj2);
      this.toasWcheck=false;
      return;
    }
  }

  showToasterNegativo(msj1,msj2=""){
    if(this.toasNcheck==true){
      this.notifyService.showSuccess(msj1,msj2);
      this.toasNcheck=false;
      return;
    }
  }

  showToasterAfirmativo(msj1,msj2=""){
    if(this.toasPcheck==true){
      this.notifyService.showSuccess(msj1,msj2);
      this.toasPcheck=false;
      return;
    }
  }

  showToasterAbstencion(msj1,msj2=""){
    if(this.toasNcheck==true){
      this.notifyService.showSuccess(msj1,msj2);
      this.toasNcheck=false;
      return;
    }
  }


  open(votacionModal) {
    this.modalReference = this.modalService.open(votacionModal, {ariaLabelledBy: 'modal-basic-title', centered: true, keyboard: false, backdrop: "static", size:'lg'})
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  close() {
    this.hide=true;
    this.modalService.dismissAll()
  }

}
