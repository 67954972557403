import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bars',
  templateUrl: './progress-bars.component.html',
})
export class ProgressBarsComponent implements OnInit{ 
  public var1: string;
  ngOnInit() {
    this.var1= null;
  }
}
