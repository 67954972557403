import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from '@angular/common'; 
@Pipe({
  name: 'filterSesion'
})
export class FilterSesionPipe implements PipeTransform {
  constructor(public datepipe: DatePipe){}
  parsearFechaHora(datetime :Date){
    var somaDateVar:any;
    somaDateVar = this.datepipe.transform(datetime, 'dd/MM/yyyy HH:mm');
    if(datetime){
      return somaDateVar;
    }else{
      return "-"
    }
  }
  transform(value: any, arg: any): any {
    if (arg === '') return value;  
    const resultPosts = [];
    for (const post of value) {
      if ( post.id_sesion.indexOf(arg.toLowerCase()) > -1 
          || this.parsearFechaHora(post.fechaHoraInicia).indexOf(arg.toLowerCase()) > -1
          || this.parsearFechaHora(post.fechaHoraFinaliza).indexOf(arg.toLowerCase()) > -1  )  {
        resultPosts.push(post);
      }
    };
    return resultPosts;
  }


}