import { Component, HostBinding, OnInit, OnDestroy, HostListener } from '@angular/core';
import { UpgradableComponent } from 'theme/components/upgradable';
import { Observable, Subject, Subscription } from 'rxjs';
import { SignalRService } from 'app/services/signal-r/signal-r.service';

import { environment } from 'environments/environment';
import { AuthService } from '@services/*';
import { User } from 'app/models';
import { GrillaService } from 'app/services/grilla.service';
import { HttpClient } from '@angular/common/http';
import { ConferenceService } from 'app/services/conference/conference.service';
import { VotacionService } from 'app/services/votacionService';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-presidente',
  templateUrl: './presidente.component.html',
  styleUrls: ['./presidente.component.scss']
})
export class PresidenteComponent extends UpgradableComponent implements OnInit, OnDestroy {
  @HostBinding('class.mdl-grid') private readonly mdlGrid = true;
  @HostBinding('class.mdl-grid--no-spacing') private readonly mdlGridNoSpacing = true;
  @HostListener('window:unload', ['$event'])
  unloadHander($event) {
    /*       if(this.currentUser.rol!="UADMIN" && this.currentUser.rol!="OBSERV" && this.currentUser.rol!="USUREL"){
    
          } */
    this.SignalR.closeConnection();
    return $event.returnValue = 'Your changes will not be saved';
  }

  currentUser: User;
  currentUserSubscription: Subscription;
  mostrarVotacion: any = false;
  mostrarHemicicloColor: boolean = false;
  voto:any;

  constructor(
    public SignalR: SignalRService,
    private authService: AuthService,
    public gr: GrillaService,
    private confeservice: ConferenceService,
    private http:HttpClient,
    private votacionCursoService: VotacionService) {
    super();
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      this.currentUser = user;
    })

    


 
  }

  ngOnDestroy() { }


  ngOnInit(): void {
    this.votacionCursoService.getVotacionEnCurso().subscribe({
      next: (votacionCurso) => {
      
        if(votacionCurso){          
         this.mostrarHemicicloColor = true
        }
        else{
          this.mostrarHemicicloColor = false
        }
      },
      error: (err) => { },
    });



    if (this.currentUser.rol =="UPRESI" ) {
      this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/${this.currentUser.id}/entrar`).subscribe(data=>{
        this.confeservice.loadConference();
      });
    } else {
      this.confeservice.loadConference();
  }
}


  chequearHemiciclo() {
   
    if (localStorage.getItem("mostrarVotacion")) {
      this.mostrarVotacion = localStorage.getItem("mostrarVotacion");
    } else {
      this.mostrarVotacion = "false";
    }
    return this.mostrarVotacion;
  }

  stopConnectio() {
    this.SignalR.closeConnection();
  }

 


}
