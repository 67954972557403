import { Component, Directive, OnInit, Pipe,PipeTransform} from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationService } from 'app/services/notification/notification.service';
import { BlankLayoutCardComponent } from 'app/components/blank-layout-card';
import { UpgradableComponent } from 'theme/components/upgradable';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'app/services';
import { Subscription } from 'rxjs';
import { User } from 'app/models';


@Component({
  selector: 'app-ausentes',
  templateUrl: './ausentes.component.html',
  styleUrls: ['./ausentes.component.css']
})
export class AusentesComponent extends BlankLayoutCardComponent  implements OnInit {
  filtroUsuario:any ="";
  item:any;
  public diputados:any[]= [];
  public bancas:any[]=[];
  public bloques:any[]=[];
  public roles:any[]=[];
  admin:boolean=false;
  p:any;
  loading:boolean=false;
  sesion:any="";
  currentUser: User;
  currentUserSubscription: Subscription;

  constructor(private http: HttpClient,
              private snackBar: MatSnackBar,
              private clipboard: Clipboard,
              public router: Router,
              private fb: FormBuilder,
              private authService: AuthService,
              private notifyService : NotificationService,
              ) {
                super();
                this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
                  this.currentUser = user;})
                this.diputados=[];
                this.bancas=[];
                this.bloques=[];
   }





  ngOnInit(): void {
    this.sesion=JSON.parse(localStorage.getItem('dataIniciado'))
    if(this.currentUser.rol=="UADMIN"){
      this.admin=true;
    }
    this.recargar();
  }

  copiarPortapeles(){
    var textocopiado=""
    this.recargar();
    for (let diputado of this.diputados){
      textocopiado=textocopiado +"°"+ diputado.apellido +" "+ diputado.nombre +"     "+diputado.bloqueNombre+"\n"+"\n"
    }
    this.clipboard.copy(textocopiado);

    // Se muestra un snackbar durante 2 segundos en la parte inferior
    this.notifyService.showSuccess('¡Texto copiado al portapapeles!','')

    // Se muestra un snackbar durante 2 segundos en la parte inferior
    this.notifyService.showSuccess('¡Texto copiado al portapapeles!','')

  }



  recargar(){
    this.sesion=JSON.parse(localStorage.getItem('dataIniciado'))
    this.loading=true;
    if(this.sesion.id_sesion){
      this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/ausentes/${this.sesion.id_sesion}`).subscribe(
        (data:any)=>{
          this.diputados=data;
          this.loading=false;
        });
    }
  }

  checkAdmin(rol){
    if(rol=="UADMIN"){
      return true
    }else{
      return false
    }
  }
  traductorDeBoolean(bool){
    if(bool){
      return "SI"
    }else{
      return "NO"
    }
  }
  retunrRol(rol){
    if(rol == "UADMIN"){
      return "Administrador"
    }else if(rol == "USUDIP"){
      return "Diputado"
    }else{
      return "Observador"
    }
  }

  retunrRolForInfo(rol){
    if(rol == "UADMIN"){
      return "Administrador"
    }else if(rol == "USUDIP"){
      return "Diputado"
    }else{
      return "Observador"
    }
  }
  checked(bool){
    if(bool){
      return "checked"
    }else{
      return ""
    }
  }
  traductorDeGenero(gen){
    if(gen == "M"){
      return "Masculino"
    }else if(gen== "F"){
      return "Femenino"
    }else{
      return "Otros"
    }
  }
}
