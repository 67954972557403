import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { MensajeHub } from '../../models/mensajeHub';
import { environment } from 'environments/environment';
import { Observable, Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { User } from 'app/models';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '@services/*';
import { Router } from '@angular/router';
import { CurrentUserService } from '../currentUser.service';
import { Login } from 'app/models/login';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  private user: User;
  constructor(private http: HttpClient, private router: Router,
    private authService:AuthService
  ) {
    this.user = this.authService.currentUserValue;
  }

  private votacion$ = new Subject<MensajeHub>();
  private microfono$ = new Subject<MensajeHub>();
  private voto$ = new Subject<MensajeHub>();
  private archivo$ = new Subject<MensajeHub>();

  private oradores$ = new Subject<MensajeHub>();

  private hubConnection: signalR.HubConnection

  public startConnection = () => {
    let self = this;

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.votacionHubUrl)
      .build();

    this.hubConnection
      .start()
      .then(() => {
        this.hubConnection.invoke('GetConnectionID')
          .then(function (connectionId) {
            // const json = `{"id":${self.user.id},"ConnectionId":"${connectionId}"}`;
            /*   console.log('Connection ID: ')
             console.log(connectionId)
 
             console.log("self user ")
             console.log(self.user)
             console.log("current user")
             console.log(self.currentUserSubject.value)
             
             console.log("local storage user")
             console.log(JSON.parse(localStorage.getItem('currentUser'))) 
  */
            var temp = JSON.parse(localStorage.getItem('currentUser'))
            if (self.user?.id != temp.id) {
              self.user = temp;
            }

            if (self.user?.id && connectionId) {
              self.http.get(`${environment.apiBaseUrl}/api/users/conectarSesion/${self.user.id}/${connectionId}`).subscribe(x => {
              });
            }
          })
      })
      .catch(err => console.log('Ocurrió el siguiente error al iniciar la conexión: ' + err))
  }

  public startListeningToNuevaVotacion = () => {    
    this.hubConnection.on('nuevavotacion', (votacion) => {
      this.votacion$.next(votacion);
      //console.log(votacion);
    });
  }

  public startListeningToVotos = () => {
    this.hubConnection.on('emitirvoto', (voto) => {
      this.voto$.next(voto);
      //console.log(votacion);
    });
  }

  //WORKING
  public startListeningToArchivos = () => {
    console.log("Waiting Archivo");
    this.hubConnection.on('activararchivo', (archivo) => {
      this.archivo$.next(archivo);
    });
  }
  
  public closeConnection = () => {
    this.hubConnection.stop()
    //console.log(votacion);
  };

  public startListeningNewLogoutRequest(component) {
    // console.log("current user:",this.currentUser)

    console.log("Waiting Logout Request");
    window.confirm = () => true;

    //permite abrir el ws para escuchar el deslogear.
    this.user = this.authService.currentUserValue
        
    if (this.user.rol == "USUDIP" || this.user.rol == "UPRESI") {
      this.hubConnection.on('newLogoutRequest', (data) => {
        this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/${Number(data)}/salir`).subscribe((data: any) => {
          //console.log("data",data)
          this.router.navigate(['/login'])
        })
      })
    }
  }

  public startListeningToMicrofono() {
      this.hubConnection.on('habilitarmicrofono', (microfono) => {
      this.microfono$.next(microfono);
      //console.log(microfono);
    });
  }

  public startListeningOradores () {    
    this.hubConnection.on('actualizarOradores', (oradores)=>{
      this.oradores$.next(oradores)
    })
  }

  getVotacion$(): Observable<MensajeHub> {
    return this.votacion$.asObservable();
  }

  getOradores$(): Observable<MensajeHub>{
    return this.oradores$.asObservable();
  }
  
  getMicrofono$(): Observable<MensajeHub> {
    return this.microfono$.asObservable();
  }

  getVoto$(): Observable<MensajeHub> {
    return this.voto$.asObservable();
  }

  getArchivo$(): Observable<MensajeHub> {
    return this.archivo$.asObservable();
  }
  conectarWebSocket(connectionId) {
    let user = JSON.parse(localStorage.getItem('currentUser'))
    // const json = `{"id":${self.user.id},"ConnectionId":"${connectionId}"}`;
    if (user?.id && connectionId) {
      //console.log('Conectando el id: '+user.id+' con conexion: '+connectionId)
      this.http.get(`${environment.apiBaseUrl}/api/users/conectarSesion/${user.id}/${connectionId}`).subscribe(x => {
        console.log('Conectado!')
      });
    }
  }
}
