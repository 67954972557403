import { Component, OnInit, OnDestroy, HostListener, Inject, ViewChild, ElementRef } from '@angular/core';
import { UpgradableComponent } from 'theme/components/upgradable';
import { BlankLayoutCardComponent } from 'app/components/blank-layout-card';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { AuthService } from 'app/services';
import { User } from 'app/models';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { NotificationService } from 'app/services/notification/notification.service';
import { Router } from '@angular/router';
import { SignalRService } from 'app/services/signal-r/signal-r.service';
import { MensajeHub } from 'app/models/mensajeHub';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-reunion',
  templateUrl: './reunion.component.html',
  host: {'class': 'blank-layout-card mdl-grid ui-components'},
  styleUrls: ['./reunion.component.css']
})
export class ReunionComponent extends  UpgradableComponent implements OnInit,OnDestroy {
  @ViewChild('voto') top; 
  urlTogo: any;
  @HostListener('window:unload', ['$event'])
  unloadHander($event) {
    if(this.currentUser.rol!="USUREL" && this.currentUser.rol!="UADMIN" && this.currentUser.rol!="OBSERV"){
    this.http.get(`${environment.apiBaseUrl}/api/reunionInstancia/${this.currentUser.id}/${localStorage.getItem('idReunion')}/salir`).subscribe(
      (data:any)=>{
      });
      }
  }
  private mensajeOBJ: any;
  voto:any;
  currentUser: User;
  currentUserSubscription: Subscription;
  cantVotosPostivios:any=0;
  cantVotosNegativos:any=0;
  loading:boolean=false;
  hide:boolean = true;
  comision:any="";
  presentes:any="";
  idPresentes:any="";
  scrollbol:boolean=false;
  expedientes:any[]=[];
  expedienteReunion:any="";
  fecha:any="";
  sala:any="";
  votacionActual:any="";
  cantPresentes:number=0;
  cantMinQuorum:number=999;
  public quorum:string = "";
  webex_url:any="";
  webex_active:any="";
  constructor(private http: HttpClient,
    private authService: AuthService,
    private fb: FormBuilder,
    @Inject(DOCUMENT) private document: Document,
    public SignalR:SignalRService,
    private elRef:ElementRef,
    private router: Router,
    private notifyService : NotificationService) {
    super();
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      this.currentUser = user;});
   }
  ngOnDestroy() {
    if(this.currentUser.rol!="USUREL" && this.currentUser.rol!="UADMIN" && this.currentUser.rol!="OBSERV" ){
    this.http.get(`${environment.apiBaseUrl}/api/reunionInstancia/${this.currentUser.id}/${localStorage.getItem('idReunion')}/salir`).subscribe(
      (data:any)=>{
      });
    }
  }
  ngOnInit(): void {
    if(localStorage.getItem(`presentesReunion${localStorage.getItem('idReunion')}`)){
      this.cantPresentes=parseInt(localStorage.getItem(`presentesReunion${localStorage.getItem('idReunion')}`));
    }
    if(this.currentUser.rol!="USUREL" && this.currentUser.rol!="UADMIN" && this.currentUser.rol!="OBSERV"){
    this.http.get(`${environment.apiBaseUrl}/api/reunionInstancia/${this.currentUser.id}/${localStorage.getItem('idReunion')}/entrar`).subscribe(
      (data:any)=>{
      });
    }
    this.loadVoto();
    this.loadReunion();

    if(localStorage.getItem(`votosNegativos${localStorage.getItem('idReunion')}`)){
      this.cantVotosNegativos=localStorage.getItem(`votosNegativos${localStorage.getItem('idReunion')}`);
    }
    if(localStorage.getItem(`votosPositivos${localStorage.getItem('idReunion')}`)){
      this.cantVotosPostivios=localStorage.getItem(`votosPositivos${localStorage.getItem('idReunion')}`);
    }
      this.SignalR.getVotacion$().subscribe((data:MensajeHub)=>{
        this.mensajeOBJ=JSON.parse(data.mensajeJSON);
        if(this.mensajeOBJ.id_reunion==parseInt(localStorage.getItem('idReunion'))){
          if (data.tipo == MensajeHub.CONST_TIPO_ENTRA_REUNION || data.tipo == MensajeHub.CONST_TIPO_SALE_REUNION)
          {
            this.loadReunion();
            this.loadVoto();
            this.cantPresentes=this.mensajeOBJ.cantidadPresentes;
            this.idPresentes=this.mensajeOBJ.idsUsuariosPresentes;
            localStorage.setItem(`presentesReunion${localStorage.getItem('idReunion')}`,this.mensajeOBJ.cantidadPresentes);
            if(this.cantPresentes>=this.cantMinQuorum){
              this.quorum="HAY QUORUM";
            }else{this.quorum="";};
          }
          if (data.tipo == MensajeHub.CONST_TIPO_INICIAR_VOTO_REUNION)
          {
            this.notifyService.showWarning(`Atención! Efectúe su voto.`,"");
            //localStorage.setItem("voto",data.mensajeJSON);
            //this.voto=JSON.parse(data.mensajeJSON);
            this.loadReunion();
            this.loadVoto();
            this.expedienteReunion=JSON.parse(localStorage.getItem(`expediente${localStorage.getItem('idReunion')}`))
            this.cantVotosNegativos=0;
            this.cantVotosPostivios=0;
            localStorage.setItem(`votosNegativos${localStorage.getItem('idReunion')}`,this.cantVotosNegativos);
            localStorage.setItem(`votosPositivos${localStorage.getItem('idReunion')}`,this.cantVotosPostivios);
            this.hide=false;
            this.scrollbol=true;
            //this.scroll(document.getElementById("voto"))
          }
          else if (data.tipo == MensajeHub.CONST_TIPO_FINALIZAR_VOTO_REUNION)
          {
            this.notifyService.showWarning(`Ha finalizado el tiempo de votación.`,"");

            //localStorage.removeItem("voto");
            this.loadReunion();
            this.loadVoto();
            this.hide=true;
            // this.gr.loadGrilla();
          }
          else if (data.tipo == MensajeHub.CONST_TIPO_ANULAR_VOTO_REUNION)
          {

            this.notifyService.showWarning(`La votación ha sido anulada`,"");
            //localStorage.removeItem("voto");
            this.loadReunion();
            this.loadVoto();
            this.hide=true;
            //this.gr.loadGrilla();
          }
          else if (data.tipo == MensajeHub.CONST_TIPO_VOTO_REUNION_NEGATIVO)
          {
            this.cantVotosNegativos=parseInt(this.cantVotosNegativos) +1;// JSON.parse(data.mensajeJSON);
            localStorage.setItem(`votosNegativos${localStorage.getItem('idReunion')}`,this.cantVotosNegativos)

          }
          else if (data.tipo == MensajeHub.CONST_TIPO_VOTO_REUNION_POSITIVO)
          {
            this.cantVotosPostivios=  parseInt(this.cantVotosPostivios) +1;// JSON.parse(data.mensajeJSON);
            localStorage.setItem(`votosPositivos${localStorage.getItem('idReunion')}`,this.cantVotosPostivios)

          }
        }
      })
  }
  unirse(){
    if(this.webex_url){
      if(this.webex_url.includes('https://') || this.webex_url.includes('http://')){
        window.open(this.webex_url,"_blank")
      }else{
        window.open("https://"+this.webex_url,"_blank")
      }
    }
  }
  scroll(el: HTMLElement) {
    if(this.scrollbol){
      el.scrollIntoView({behavior: 'smooth'});
      this.scrollbol=false;
    }
  }
  loadReunion(){
    this.loading=true;
    this.http.get(`${environment.apiBaseUrl}/api/reunionInstancia/${localStorage.getItem('idReunion')}`).subscribe(
      (data:any)=>{
        this.loading=false;
        this.webex_active=data.webex_active
        this.webex_url=data.webex_url
        this.fecha=data.fecha;
        this.cantMinQuorum=data.cantMinQuorum
        this.sala=data.sala
        this.comision=data.comision;
        this.presentes=data.integrantes;
        this.idPresentes=data.idsUsuariosPresentes;
        this.expedientes=data.expedientes
        this.cantPresentes=data.cantidadPresentes
        localStorage.setItem(`presentesReunion${localStorage.getItem('idReunion')}`,data.cantidadPresentes);
        if(this.cantPresentes>=this.cantMinQuorum){
          this.quorum="HAY QUORUM";
        }else{this.quorum="";};
      });
  }
  loadVoto(){
    this.http.get(`${environment.apiBaseUrl}/api/reunionVotacion/iniciado/${this.currentUser.id}/${localStorage.getItem('idReunion')}`).subscribe(
      (data:any)=>{
        this.voto=data;
        if(this.voto != null){
          localStorage.setItem(`${data.id_expediente}${localStorage.getItem('idReunion')}`,JSON.stringify(data));
          localStorage.setItem(`expediente${localStorage.getItem('idReunion')}`,JSON.stringify(data));
          localStorage.setItem(`id${data.id_expediente}${localStorage.getItem('idReunion')}`,data.id_votacion);
          this.hide=true;
          if(this.voto){
            if (this.voto.fechaHoraFinaliza) {
              this.hide=true;
            }
          else if(this.voto.fechaHoraInicia) {
            if (this.voto.fechaHoraFinaliza == null){
              this.hide=false;}
            }
          }
        }else{
          this.hide=true;
        }
    });
  }

  crearVotacion(modo,item){
    if(confirm("Estás seguro en crear esta votacion?")){
      localStorage.setItem(`expediente${localStorage.getItem('idReunion')}`,JSON.stringify(item));
      localStorage.setItem(`${item.id_expediente}${localStorage.getItem('idReunion')}`,JSON.stringify(item));
      let head = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      var infoA:any;
      infoA=
      `{
        "id_reunion":"${localStorage.getItem('idReunion')}",
        "id_expediente":"${item.id_expediente}",
        "modo":"${modo}"
      }`;

      this.http.post(`${environment.apiBaseUrl}/api/reunionVotacion`,infoA,{headers: head}).subscribe(
        (data:any)=>{
          localStorage.setItem(`id${item.id_expediente}${localStorage.getItem('idReunion')}`,data.id)
          this.loadReunion();
          this.loadVoto();
          this.notifyService.showSuccess("Votacion creada con exito!","");
        },
        error=>{this.notifyService.showError("Hubo un problema al crear votacion!","");}
      )
    }
  }

  estaPresente(item){
    if(this.idPresentes.includes(item.usuario_id)){
      return true;
    }else{
      return false;
    }
  }

  iniciar(id){
    this.http.put(`${environment.apiBaseUrl}/api/reunionVotacion/${id}/iniciar`,"").subscribe(
      (data:any)=>{
        window.scrollTo(0,0);
        //this.loadReunion();
        //this.loadVoto();
      },
      error=>{
        this.notifyService.showError(error.error,"");})
    }
  finalizar(id){
    this.http.put(`${environment.apiBaseUrl}/api/reunionVotacion/${id}/finalizar`,"").subscribe(
      (data:any)=>{
        //this.loadReunion();
        //this.loadVoto();
        //this.notifyService.showWarning("Votacion finalizada!","");
      },
      error=>{this.notifyService.showError("Hubo un problema al finalizar votacion!","");})
  }
  anular(exp,id){
    this.http.put(`${environment.apiBaseUrl}/api/reunionVotacion/${id}/anular`,"").subscribe(
      (data:any)=>{
       // this.loadReunion();
        //this.loadVoto();
        localStorage.removeItem(`${exp}${localStorage.getItem('idReunion')}`)
        //this.notifyService.showWarning("Votacion anulada!","");
      },
      error=>{this.notifyService.showError("Hubo un problema al anular votacion!","");})
  }

  afirmativo(){
    var id:any=""
    var expedienteReunion:any=""
    expedienteReunion=JSON.parse(localStorage.getItem(`expediente${localStorage.getItem('idReunion')}`))
    id=localStorage.getItem(`id${expedienteReunion.expediente}${localStorage.getItem('idReunion')}`)
    this.http.post(`${environment.apiBaseUrl}/api/reunionVotacion/${id}/${this.currentUser.id}/${localStorage.getItem('idReunion')}/positivo`,"").subscribe(
      (data:any)=>{
        this.hide=!this.hide;
        //this.loadReunion();
        this.loadVoto();
        this.notifyService.showSuccess("Haz votado positivo con exito!","");
      },
      error=>{this.notifyService.showError("Hubo un problema al cargar el positivo!","");})
  }
  negativo(){
    var id:any=""
    var expedienteReunion:any=""
    expedienteReunion=JSON.parse(localStorage.getItem(`expediente${localStorage.getItem('idReunion')}`))
    id=localStorage.getItem(`id${expedienteReunion.id_expediente}${localStorage.getItem('idReunion')}`)
    this.http.post(`${environment.apiBaseUrl}/api/reunionVotacion/${id}/${this.currentUser.id}/${localStorage.getItem('idReunion')}/negativo`,"").subscribe(
      (data:any)=>{
        this.hide=!this.hide;
        //this.loadReunion();
        this.loadVoto();
        this.notifyService.showSuccess("Haz votado negativo con exito!","");
      },
      error=>{this.notifyService.showError("Hubo un problema al cargar el negativo!","");})
  }
  hayQuorum(){
    if(parseInt(localStorage.getItem(`presentesReunion${localStorage.getItem('idReunion')}`))>=this.cantMinQuorum){
      this.quorum="HAY QUORUM";
    }else{this.quorum="";
    }
  }
  expedienteAcutalVotando(exp){
    var expedienteReunion:any="";
    if(localStorage.getItem(`${exp}${localStorage.getItem('idReunion')}`)){
        expedienteReunion=JSON.parse(localStorage.getItem(`${exp}${localStorage.getItem('idReunion')}`))
        if(exp==expedienteReunion.id_expediente){
          return true
        }else{
          return false
        }
    }else{
      return false
    }
  }
  traductorDeModo(modo){
    if(modo == "A"){
       return "APROBADO"
     }else if(modo == "M"){
       return "CON MODIFICACION"
     }else if(modo == "P"){
       return "POSTERGADO"
     }else if(modo == "R"){
       return "EN RECHAZO"
     }else{
       return ""
     }
   }
}
