import { Component, HostBinding, OnInit, OnDestroy, HostListener } from '@angular/core';
import { UpgradableComponent } from 'theme/components/upgradable';
import { Observable, Subject, Subscription } from 'rxjs';
import { SignalRService } from 'app/services/signal-r/signal-r.service';

import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@services/*';
import { User } from 'app/models';
import { MensajeHub } from '../../models/mensajeHub';
import { GrillaService} from 'app/services/grilla.service';
import { NotificationService } from 'app/services/notification/notification.service';
import { delay } from 'rxjs/operators';
import { ConferenceService } from 'app/services/conference/conference.service';
import { VotacionService } from 'app/services/votacionService';


@Component({
  selector: 'app-voto-diputado',
  templateUrl: './voto-diputado.component.html',
  styleUrls: ['./voto-diputado.component.scss']
})
export class VotoDiputadoComponent extends UpgradableComponent implements OnInit,OnDestroy {
  @HostBinding('class.mdl-grid') private readonly mdlGrid = true;
  @HostBinding('class.mdl-grid--no-spacing') private readonly mdlGridNoSpacing = true;
  @HostListener('window:unload', ['$event'])
  unloadHander($event) {
    this.SignalR.closeConnection();
    return $event.returnValue='Your changes will not be saved';
  }

  hide:boolean = true;
  voto:any;
  currentUser: User;
  currentUserSubscription: Subscription;
  admin: boolean = false;
  observ: boolean = false;
  cantVotosPostivios:any=0;
  cantVotosNegativos:any=0;
  cantVotosAbstenciones:any=0;
  check: any = true;
  toasWcheck: boolean;
  toasScheck: boolean;
  toasNcheck: boolean;
  toasPcheck: boolean;
  toasEcheck: boolean;
  toasAcheck: boolean;
  mostrarVotacion: any=false;
  scrollbol:boolean=false;

  constructor(
    public SignalR:SignalRService,
    private http:HttpClient,
    private confeservice: ConferenceService,
    private notifyService : NotificationService,
    private authService: AuthService,
    public gr: GrillaService,
    public votacionService:VotacionService) {
    super();
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      this.currentUser = user;
    })
  }
  
  ngOnDestroy() {

  };
  
  scroll(el: HTMLElement) {
    if(this.scrollbol){
      el.scrollIntoView({behavior: 'smooth'});
      this.scrollbol=false;
    }
  }
  ngOnInit(): void {
    localStorage.removeItem('idReunion');
    if(localStorage.getItem("currentUser")){
      if(this.currentUser.rol!="UADMIN" && this.currentUser.rol!="OBSERV" && this.currentUser.rol!="USUREL"){

        this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/${this.currentUser.id}/entrar`).subscribe(data=>{

          this.confeservice.loadConference();
        });}else{
          this.confeservice.loadConference();
        }

    }
    if(localStorage.getItem("mostrarVotacion")){
      this.mostrarVotacion=localStorage.getItem("mostrarVotacion");
    }else{
      this.mostrarVotacion="true";
    }
    //this.check=localStorage.getItem("check")
    if(localStorage.getItem("votosNegativos")){
      this.cantVotosNegativos=localStorage.getItem("votosNegativos");
    }
    if(localStorage.getItem("votosAbstenciones")){
      this.cantVotosAbstenciones=localStorage.getItem("votosAbstenciones");
    }
    if(localStorage.getItem("votosPositivos")){
      this.cantVotosPostivios=localStorage.getItem("votosPositivos");
    }
    

    this.hide=true;

    // Reconozco si el usuario tiene roles de administrador.
    if (this.currentUser.rol=="UADMIN" || this.currentUser.rol=="SECADM" || this.currentUser.rol=="SECLEG" || this.currentUser.rol=="USULEG"){
      this.admin=true;
      
    }else{
      this.admin=false;
    }
    if(this.currentUser.rol == "OBSERV"){
      this.observ=true;
    }else{
      this.observ=false;
    }
      // Busco si hay una sesion activa en la BD.

    this.loadVoto();

    // Analizo mensajes desde la API.
    this.SignalR.getVotacion$().subscribe((data:MensajeHub)=>{
      //console.log("signal"+this.hide)
      
      if (data.tipo == MensajeHub.CONST_TIPO_INICIAR_VOTO)
      {
        if(!this.observ){
          delay(2000)
          this.toasWcheck=true
          this.showToasterEfectuar(`Atención! Efectúe su voto.`);
        }
        //localStorage.setItem("voto",data.mensajeJSON);
        //this.voto=JSON.parse(data.mensajeJSON);
        this.loadVoto();
        this.cantVotosNegativos=0;
        this.cantVotosAbstenciones=0;
        this.cantVotosPostivios=0;
        localStorage.setItem("votosNegativos",this.cantVotosNegativos);
        localStorage.setItem("votosAbstenciones",this.cantVotosAbstenciones);
        localStorage.setItem("votosPositivos",this.cantVotosPostivios);
        this.hide=false;
        this.scrollbol=true;
      }
      else if (data.tipo == MensajeHub.CONST_TIPO_FINALIZAR_VOTO)
      {
        if(!this.observ){
          delay(2000)
          this.toasEcheck=true
          this.showToasterFinalizado(`Ha finalizado el tiempo de votación.`);
        }
        //localStorage.removeItem("voto");
        this.loadVoto();
        this.hide=true;
        this.gr.loadGrilla();
      }
      else if (data.tipo == MensajeHub.CONST_TIPO_ANULAR_VOTO)
      {
        if(!this.observ){
          delay(2000)
          this.toasAcheck=true
          this.showToasterAnulado(`La votación ha sido anulada`);
        }
        //localStorage.removeItem("voto");
        this.loadVoto();
        this.hide=true;
        this.gr.loadGrilla();
      }
      else if (data.tipo == MensajeHub.CONST_TIPO_VOTO_NEGATIVO)
      {
        this.cantVotosNegativos=parseInt(this.cantVotosNegativos) +1;// JSON.parse(data.mensajeJSON);
        localStorage.setItem("votosNegativos",this.cantVotosNegativos)

      }
      else if (data.tipo == MensajeHub.CONST_TIPO_VOTO_ABSTENCION)
      {
        this.cantVotosAbstenciones=parseInt(this.cantVotosAbstenciones) +1;// JSON.parse(data.mensajeJSON);
        localStorage.setItem("votosAbstenciones",this.cantVotosAbstenciones)

      }
      else if (data.tipo == MensajeHub.CONST_TIPO_VOTO_POSITIVO)
      {
        this.cantVotosPostivios=  parseInt(this.cantVotosPostivios) +1;// JSON.parse(data.mensajeJSON);
        localStorage.setItem("votosPositivos",this.cantVotosPostivios)

      }
      else if (data.tipo == MensajeHub.CONST_TIPO_VOTO_HEMICICLO)
      {
        this.mostrarVotacion=true;
        localStorage.setItem("mostrarVotacion",this.mostrarVotacion.toString());
      }
      else if (data.tipo == MensajeHub.CONST_TIPO_CERRAR_VOTO_HEMICICLO)
      {
        this.mostrarVotacion=false;
        localStorage.setItem("mostrarVotacion",this.mostrarVotacion.toString());
      }
    })
    

    this.SignalR.startListeningNewLogoutRequest(this)
  }

  chequearHemiciclo(){
    //console.log("mostrar?? ");
    if(localStorage.getItem("mostrarVotacion")){
      this.mostrarVotacion=localStorage.getItem("mostrarVotacion");
    }else{
      this.mostrarVotacion="false";
    }
    return this.mostrarVotacion;
  }

  loadVoto(){
    this.http.get(`${environment.apiBaseUrl}/api/votacion/iniciado/${this.currentUser.id}`).subscribe(
      (data:any)=>{
        this.voto=data;
        if(this.voto != null && this.voto.tipo=="S"){
          this.hide=true;
          if(this.voto){
            if (this.voto.fechaHoraFinaliza) {
              this.hide=true;
            }
          else if(this.voto.fechaHoraInicia) {
            if (this.voto.fechaHoraFinaliza == null){
              this.hide=false;}
            }
          }
        }else{
          this.hide=true;
        }
    });
  }

  
  afirmativo(){
    this.votacionService.votoAfirmativo(this.voto.id,this.currentUser.id).subscribe({
      next: (voto) => {
        this.hide=!this.hide;
        if(!this.observ){
          delay(2000)
          this.toasPcheck=true
          this.showToasterAfirmativo(`Se ha ingresado su voto POSITIVO`);
        }

        this.loadVoto();
      },
      error: (err) => { },
    });
  };

  negativo(){

    this.votacionService.votoNegativo(this.voto.id,this.currentUser.id).subscribe({
      next: (voto) => {
        this.hide=!this.hide;
        if(!this.observ){
          delay(2000)
          this.toasPcheck=true
          this.showToasterAfirmativo(`Se ha ingresado su voto NEGATIVO`);
        }

        this.loadVoto();
      },
      error: (err) => { },
    });
  }

  abstencion(){
   
    this.votacionService.votoAbstencion(this.voto.id,this.currentUser.id).subscribe({
      next: (voto) => {
        this.hide=!this.hide;
        if(!this.observ){
          delay(2000)
          this.toasPcheck=true
          this.showToasterAfirmativo(`Se ha ingresado su voto ABSTENCION`);
        }

        this.loadVoto();
      },
      error: (err) => { },
    });
  }

  stopConnectio(){
    this.SignalR.closeConnection();
  }
  showToasterFinalizado(msj1,msj2=""){
    if(this.toasEcheck==true){
      this.notifyService.showError(msj1,msj2);
      this.toasEcheck=false;
      return;
   }
  }

  showToasterAnulado(msj1,msj2=""){
    if(this.toasAcheck==true){
      this.notifyService.showError(msj1,msj2);
      this.toasAcheck=false;
      return;
   }
  }
  showToasterEfectuar(msj1,msj2=""){
    if(this.toasWcheck==true){
      this.notifyService.showWarning(msj1,msj2);
      this.toasWcheck=false;
      return;
    }
  }

  showToasterNegativo(msj1,msj2=""){
    if(this.toasNcheck==true){
      this.notifyService.showSuccess(msj1,msj2);
      this.toasNcheck=false;
      return;
    }
  }

  showToasterAfirmativo(msj1,msj2=""){
    if(this.toasPcheck==true){
      this.notifyService.showSuccess(msj1,msj2);
      this.toasPcheck=false;
      return;
    }
  }

  showToasterAbstencion(msj1,msj2=""){
    if(this.toasNcheck==true){
      this.notifyService.showSuccess(msj1,msj2);
      this.toasNcheck=false;
      return;
    }
  }
  

  esDiputado(){
    return (this.currentUser.rol=="USUDIP"||this.currentUser.rol=="UPRESI")
  }
  
}