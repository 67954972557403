import { Component, OnInit } from '@angular/core';
import { SignalRService } from './services/signal-r/signal-r.service';
import { ConferenceService } from './services/signal-r/conference.service';


@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  //constructor(public signalRService: SignalRService, public conferenceService: ConferenceService) {
  constructor(public signalRService: SignalRService) {
    (window as any).pdfWorkerSrc = '/assets/pdf.worker.js';
  }

  ngOnInit() {
    //this.signalRService.startConnection();
    //this.signalRService.startListeningToNuevaVotacion();
    //this.conferenceService.startConnection();
    //this.conferenceService.startListeningToNuevaVotacion();
  }
}
