import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConferenceService } from 'app/services/conference/conference.service.js';
import { environment } from '../../../environments/environment';
import { User } from 'app/models';
import { CurrentUserService } from '../currentUser.service';

const tokenName = 'token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private url = `${environment.apiBaseUrl}/api/auth`;

  private currentUserSubject: BehaviorSubject<User>;
  private user: User;
  public currentUser: Observable<User>;
  public loginByFingerprintObservable: Observable<boolean>;
  public loginByFingerprintOSubject: BehaviorSubject<boolean>;


  constructor(private http: HttpClient,
    private confeservice: ConferenceService,
    private cuService: CurrentUserService) {

    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.user = this.currentUserSubject.value;

    this.loginByFingerprintOSubject = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('loginByFingerprint')));
    this.loginByFingerprintObservable = this.loginByFingerprintOSubject.asObservable();
  }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get loginByFingerprintValue(): boolean {
    return this.loginByFingerprintOSubject.value;
  }


  login(usuario: string, contraseña: string) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/users/login`, { usuario, contraseña })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user.user && user.user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.cargarUsuario(usuario,contraseña)
    
          localStorage.setItem('currentUser', JSON.stringify(user.user));


          localStorage.setItem('loginByFingerprint', JSON.stringify(false));


          this.currentUserSubject.next(user.user);
          this.loginByFingerprintOSubject.next(false);

        }
        return user;
      }));
    /*var user=new User();
    // let user: new User;
    user.usuario="nico";
    user.apellido="Perez";
    user.nombre="Nicolas";
    user.dni="40458821";
    user.genero="M";
    user.pictureUrl="abad.png";
    user.token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6Ijg2IiwibmJmIjoxNTg4MDAxMDg4LCJleHAiOjE1ODg2MDU4ODgsImlhdCI6MTU4ODAwMTA4OH0.v-w237U12KAor_-4PLckcwzvSr3VXwecSZEIFhPuKWU";
    user.contraseña=null;
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
    return this.currentUserSubject;*/
  }

  connect(userId: string, connectionId: string) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/users/conectar`, { userId, connectionId })
      .subscribe();
  }

  loginByFingerprint(loginData) {
    return new Promise<any>((resolve, reject) => {
      //console.log(loginData.login, loginData.user, loginData.user.token)
      if (loginData.login && loginData.user && loginData.user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(loginData.user));
        localStorage.setItem('loginByFingerprint', JSON.stringify(true));
        this.currentUserSubject.next(loginData.user);
        this.loginByFingerprintOSubject.next(true);
        resolve(loginData);
      }
      reject(loginData);
    })
  }

  public logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('check');
    localStorage.removeItem('conferenceCode');
    localStorage.removeItem('currentUser');
    localStorage.clear();
    this.currentUserSubject.next(null);
  }

  public get authToken(): string {
    return localStorage.getItem(tokenName);
  }

  public get userData(): Observable<any> {
    // send current user or load data from backend using token
    return this.loadUser();
  }

  private loadUser(): Observable<any> {
    // use request to load user data with token
    // it's fake and useing only for example
    if (localStorage.getItem('usuario') && localStorage.getItem('nombre') && localStorage.getItem('apellido') && localStorage.getItem('id')
      && localStorage.getItem('token') && localStorage.getItem('contraseña')) {
      this.user = {
        pin: localStorage.getItem('pin'),
        validarRenaper: localStorage.getItem('validarRenaper'),
        dni: localStorage.getItem('dni'),
        genero: localStorage.getItem('genero'),
        apellido: localStorage.getItem('apellido'),
        contraseña: localStorage.getItem('contraseña'),
        nombre: localStorage.getItem('nombre'),
        usuario: localStorage.getItem('usuario'),
        id: localStorage.getItem('id'),
        pictureUrl: localStorage.getItem('pictureUrl'),
        rol: localStorage.getItem('rol'),
        token: localStorage.getItem('token'),
        habilitado: ("true" == localStorage.getItem('habilitado'))
      };
    }
    return of(this.user);
  }

cargarUsuario(usuario,contraseña):void{
    this.cuService
      .login(usuario,contraseña)
      .subscribe({
        next: (data) => {
        },
        error: (err) => { },
      });
}

}
