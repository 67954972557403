import { Injectable } from '@angular/core';
import { set } from 'd3';
import { BehaviorSubject,of } from 'rxjs';


@Injectable()
export class VotacionModalResultadoService {

  constructor() { }

  public votacion = new BehaviorSubject<any>(null);
 
  setVotacion(valueChange: any) {
    // console.log("3) votacion.modal.res.service: setVotacion() | setea la siguiente votacion ")
    // console.log("3.b)", this.votacion);
    this.votacion.next(valueChange);

    // Clear BehaviorSubject after emit value
    
    setTimeout(() => {
      this.votacion.next(null);
    }, 150);

  }

  getVotacion() {
    // console.log("5) votacion.modal.res.service: getVotacion() | devuelve una votacion como observable")
    this.votacion.next   
    return this.votacion.asObservable();
  }
 
}