import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterReunion'
})
export class FilterReunionPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    if (arg === '') return value;  
    const resultPosts = [];
    for (const post of value) {
      if ( post.fecha.toLowerCase().indexOf(arg.toLowerCase()) > -1 
          || post.sala.toLowerCase().indexOf(arg.toLowerCase()) > -1
          || post.d_comision.toLowerCase().indexOf(arg.toLowerCase()) > -1  )  {
        resultPosts.push(post);
      }
    };
    return resultPosts;
  }


}