export class SolicitudesService {
    solicitudesEnroll: any[]=[];
    solicitudesLogin: any[]=[];

    solicitudNuevaEnroll(data) {
        // Se generan multiples conexiones al deslogear y logear
            if(!this.solicitudesEnroll.some((x)=> x.id==data.id)  ){
                this.solicitudesEnroll.push(data)
            }
    }

    solicitudNuevaLogin(data) {
        // Se generan multiples conexiones al deslogear y logear
            if(!this.solicitudesLogin.some((x)=> x.id==data.id)){
                this.solicitudesLogin.push(data)
            }
    }

    solicitudAtendidaEnroll(data) { 
        this.solicitudesEnroll.map((x)=>{
            if(x.id==data.id) {                
                this.solicitudesEnroll.splice(x,1);
            }
        });
    }

    
    solicitudAtendidaLogin(data) { 
        this.solicitudesLogin.map((x)=>{
            if(x.id==data.id) {                
                this.solicitudesLogin.splice(x,1);
            }
        });
    }

    hayPendientes() {
        return ((this.solicitudesEnroll.length>0)||(this.solicitudesLogin.length>0));
    }

    cantidadSolicitudes() {
        return ((this.solicitudesEnroll.length)+(this.solicitudesLogin.length));
    }

    getSolicitudesLogin(){
        return this.solicitudesLogin
    }
    
    getSolicitudesEnroll(){
        return this.solicitudesEnroll
    }
}