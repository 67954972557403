import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { VotacionEnCurso } from 'app/models/votacionEnCurso';

@Injectable({ providedIn: 'root' })
export class VotacionService {

    path:string=`${environment.apiBaseUrl}/api/votacion`

    constructor(private http: HttpClient) {
    }
   
     public getVotacionEnCurso():Observable<VotacionEnCurso> {
        var url =this.path + `/votacionEnCurso`
        return this.http.get<VotacionEnCurso>(url)
      
    }

    votoAfirmativo(votoId,userId){
        var url =this.path+'/'+votoId+'/'+userId + '/positivo'
        return this.http.post<any>(url,"")
    }

    votoNegativo(votoId,userId){
        var url =this.path+'/'+votoId+'/'+userId + '/negativo'
        return this.http.post<any>(url,"")
    }
    
    votoAbstencion(votoId,userId){  
        var url =this.path+'/'+votoId+'/'+userId + '/abstencion'
        return this.http.post<any>(url,"")
    }

    limpiarVoto(votoId,userId){  
        var url =this.path+'/'+votoId+'/'+userId + '/limpiar'
        return this.http.post<any>(url,"")
    }

finalizar(id){       
        var url =this.path+'/'+id + '/finalizar'   
      // console.log("imprime el lo grabado:")
        return this.http.put<any>(url,"")
    }
}