
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { ReportePdf } from 'app/models/reportePdf';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class PdfService {
    constructor(private http: HttpClient) { }


     getReporte(): Observable<ReportePdf[]> {
        var url = `${environment.apiBaseUrl}/api/users/pdfReporte`
        return this.http.get<ReportePdf[]>(url);
    }

}
