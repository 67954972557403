import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { User } from 'app/models';
import { AuthService } from 'app/services';
import { GrillaService } from 'app/services/grilla.service';
import { SignalRService } from 'app/services/signal-r/signal-r.service';
import { Subscription } from 'rxjs';
import { UpgradableComponent } from 'theme/components/upgradable';

@Component({
  selector: 'app-cargar-votacion',
  templateUrl: './cargar-votacion.component.html',
  styleUrls: ['./cargar-votacion.component.scss']
})

export class CargarVotacionComponent extends UpgradableComponent implements OnInit, OnDestroy {
  @HostBinding('class.mdl-grid') private readonly mdlGrid = true;
  @HostBinding('class.mdl-grid--no-spacing') private readonly mdlGridNoSpacing = true;
  @HostListener('window:unload', ['$event'])

  unloadHander($event) {
    this.SignalR.closeConnection();
    return $event.returnValue = 'Your changes will not be saved';
  }

  currentUser: User;
  currentUserSubscription: Subscription;
  mostrarVotacion: any = false;



   constructor(
    public SignalR: SignalRService,
    private authService: AuthService,
    public gr: GrillaService) {
    super();
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      this.currentUser = user;
    })
  }



  ngOnDestroy() { }

  ngOnInit(): void {
    
  
     
  }

  stopConnectio() {
    this.SignalR.closeConnection();
  }

 



}