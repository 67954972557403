import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User } from '../../app/models';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<User[]>(`${environment.apiBaseUrl}/api/users`);
    }

    getById(id: number) {
        return this.http.get(`${environment.apiBaseUrl}/api/users/${id}`);
    }

    register(user: User) {
        return this.http.post(`${environment.apiBaseUrl}/api/users/register`, user);
    }

    update(user: User) {
        return this.http.put(`${environment.apiBaseUrl}/api/users/${user.id}`, user);
    }

    delete(id: number) {
        return this.http.delete(`${environment.apiBaseUrl}/api/users/${id}`);
    }

    hemiciclo() {
        return this.http.get<any[]>(`${environment.apiBaseUrl}/api/users/hemiciclo`);
    }


}