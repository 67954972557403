import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BlankLayoutCardComponent } from 'app/components/blank-layout-card';
import { environment } from 'environments/environment';
import { NotificationService } from 'app/services/notification/notification.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'app-agregar-sesion',
  templateUrl: './agregar-sesion.component.html',
  styleUrls: ['./agregar-sesion.component.css']
})
export class AgregarSesionComponent  extends BlankLayoutCardComponent implements OnInit {
  public sesionForm: FormGroup;
  error: any;
  hayArchivo:boolean=false;
  ordenDelDia:any="";
  private httpSI: HttpClient;
  formData= new FormData;
  sumario:any="";
  id_disponibles:any[]=[];
  constructor(private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    private notifyService : NotificationService,
    private handler: HttpBackend,) {
      super()
      this.httpSI = new HttpClient(handler);
      this.sesionForm = this.fb.group({
        id_sesion: new FormControl('', Validators.required),
        webex_mk: new FormControl(''),
        webex_pw: new FormControl(''),
        webex_url: new FormControl(''),
        webex_active: new FormControl('', Validators.required),
        sumarioUrl: new FormControl(null),
        ordenDelDiaUrl: new FormControl(null),
        presentes: new FormControl(''),
      });
     }
  public onInputChange(event) {
    event.target.required = true;
  }
  get f() { return this.sesionForm.controls; }
  
  ngOnInit(): void {
    this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/idSesionesDisponibles`).subscribe((data:any)=>{
      this.id_disponibles=data;
    })
    this.sesionForm.valueChanges.subscribe(() => {
      this.error = null;
    });
  }
  onFileSelectedOrden(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.sesionForm.get('ordenDelDiaUrl').setValue(file);
      this.formData.append('file',file);
      
    }
  }
  onFileSelectedSumario(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.sesionForm.get('sumarioUrl').setValue(file);
      this.formData.append('file',file)
      this.hayArchivo=true
    }
  }
  grabar(){

    if(!this.f.ordenDelDiaUrl.value){
      this.sesionForm.get('ordenDelDiaUrl').setValue(null);
    }else if(this.f.ordenDelDiaUrl.value.name){
      this.sesionForm.get('ordenDelDiaUrl').setValue(this.f.ordenDelDiaUrl.value.name);
      

   


    }
    if(!this.f.sumarioUrl.value){
      this.sesionForm.get('sumarioUrl').setValue(null);
    }else  if(this.f.sumarioUrl.value.name){
      this.sesionForm.get('sumarioUrl').setValue(this.f.sumarioUrl.value.name);
      
    }
    let head = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    var infoA:any;
    infoA=`{
      "id_sesion":"${this.f.id_sesion.value}",
      "webex_mk":"${this.f.webex_mk.value}",
      "webex_pw":"${this.f.webex_pw.value}",
      "webex_url":"${this.f.webex_url.value}",
      "webex_active":"${this.f.webex_active.value}",
      "sumarioUrl":"${this.sesionForm.get('sumarioUrl').value}",
      "ordenDelDiaUrl":"${this.sesionForm.get('ordenDelDiaUrl').value}",
      "presentes":"${this.f.presentes.value}",
  }`
  //this.saveAsProject();
  

  
  if(this.hayArchivo){
    this.http.post<any>(`${environment.apiBaseUrl}/api/sesionInstancia/uploadFiles/${this.f.id_sesion.value}`,this.formData)
    .subscribe(res=>{this.formData = new FormData;this.hayArchivo=false;},
      error=>{})
  };
 this.http.post(`${environment.apiBaseUrl}/api/sesionInstancia/crear`,infoA,{headers: head}).subscribe(
    (data:any)=>{
      this.notifyService.showSuccess("Sesion creada con exito!","");
      this.router.navigate(['/app/sesion-admin'])
      this.sesionForm.reset()
    },
    error=>{
      this.notifyService.showError(error.error,"");
    })
  
  }
  volver(){
    this.router.navigate(['/app/sesion-admin'])
  }

  saveAsProject(){
    //you can enter your own file name and extension
    this.writeContents(this.ordenDelDia, this.ordenDelDia.name, 'pdf');
  }

  writeContents(content, fileName, contentType) {

    //'../../assets/descargas'
    var a = document.createElement('a');
    var file = new Blob([content], {type: contentType});
    a.href = URL.createObjectURL(file);
    //a.href ='../../assets/descargas'
    a.download = fileName;
    this.http.post('../../assets/descargas',a);
    a.click();
  }
}
