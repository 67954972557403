import { Component, OnInit } from '@angular/core';
import { SignalRService } from 'app/services/signal-r/signal-r.service';
import { MensajeHub } from '../../models/mensajeHub';
import { UpgradableComponent } from 'theme/components/upgradable';
import { SesionService } from 'app/services/sesion.service';

@Component({
  selector: 'app-estadisticas',
  templateUrl: './estadisticas.component.html',
  styleUrls: ['./estadisticas.component.scss']
})
export class EstadisticasComponent extends UpgradableComponent implements OnInit {

  public cantPresentes: number;

  constructor(public SignalR: SignalRService,
    private sesionService: SesionService) {
    super();
  }

  ngOnInit(): void {
    this.sesionService.getActiva().subscribe({
      next: (data) => {
        this.cantPresentes = Number(data.cantPresentes)
      },
      error: (err) => {
        this.cantPresentes = 0;
      },
    });
    this.cargarSocket()
  }

  mayoriaSimple() {
    return (Math.floor((this.cantPresentes) / 2)) + 1;
  }

  mayoriaAbsoluta() {
    return Math.ceil((92 / 2) + 1);
  }

  dosTerciosCamara() {
    return Math.ceil((92 * 2) / 3);
  }

  unQuintoPresentes() {

    return Math.ceil((this.cantPresentes) / 5);
  }

  unTercioPresentes() {
    return Math.ceil((this.cantPresentes) / 3);
  }

  mayoriaDosTerciosPresentes() {
    return Math.ceil(((this.cantPresentes) * 2) / 3);;
  }


  cargarSocket(): void {
    this.SignalR.getVotacion$().subscribe((data: MensajeHub) => {
      if (data.tipo == MensajeHub.CONST_TIPO_ENTRA_SESION || data.tipo == MensajeHub.CONST_TIPO_SALE_SESION) {
        this.cantPresentes = Number(JSON.parse(data.mensajeJSON).cantPresentes);

      }
    })
  }

}
