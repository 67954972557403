import { Component, NgZone, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'app/services/notification/notification.service';
import { LoginWithFingerprintService } from 'app/services/signal-r/loginWithFingerprint.service';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

declare global {
  interface Window {
    RTCPeerConnection: RTCPeerConnection;
    mozRTCPeerConnection: RTCPeerConnection;
    webkitRTCPeerConnection: RTCPeerConnection;
  }
}
@Component({
  selector: 'app-login-with-fingerprint',
  templateUrl: './login-with-fingerprint.component.html',
  host: {'class': 'mdl-grid block p-5'},
  styleUrls: ['./login-with-fingerprint.component.scss','../components/blank-layout-card/blank-layout-card.component.scss']
})

export class LoginWithFingerprintComponent implements OnInit, OnDestroy {
  cantErrors: number = 0;
  ipAddress: string;
  nombre: string;
  apellido: string;
  pictureUrl: string;
  banca: string;
  public isButtonVisible = false;

  constructor(public loginWithFingerprintService:LoginWithFingerprintService, private http: HttpClient, private notifyService : NotificationService,private router: Router) {}

  ngOnInit(): void {
    //console.log("On Init")
    this.loginWithFingerprintService.startConnection(this) 
    .then(() => this.loginWithFingerprintService.determineLocalIpByUrl())
    .then((localIp) => {
      this.loginWithFingerprintService.addToLoginList(localIp);
      //console.log(localIp);
      this.ipAddress = localIp;
      this.http.get(`${environment.apiBaseUrl}/api/users/requestUserDataByIP/${localIp}`).subscribe(
        (data: any) => {
          this.nombre = data.nombre;
          this.apellido = data.apellido;
          this.pictureUrl = data.pictureUrl; 
          this.banca = data.banca;
        });
    })
    .then(() => this.loginWithFingerprintService.startListeningLogin(this))
  }

  ngOnDestroy(){
    this.loginWithFingerprintService.closeConnection();
  }

  public reconnect(){
    return new Promise(function(resolve, reject) {  setTimeout(resolve, 3000)})
    .then(() => {
      //console.log("Intentando reconexion")
      //Al terminar el Timeout (A los 3seg) Intentará Reconectar
      this.loginWithFingerprintService.connect()
      .then(() => this.loginWithFingerprintService.determineLocalIpByUrl())
      .then((localIp) => this.loginWithFingerprintService.addToLoginList(localIp))
      .then(() => this.loginWithFingerprintService.startListeningLogin(this))
    });
  }

  public succefullFingerprint(){
    this.notifyService.showSuccess("Usuario loggeado exitosamente!","")
  }
  
  public failFingerprint(){
      this.cantErrors++;
      if (this.cantErrors >= 3){
        //console.log("Error de Autenticacion N°" + this.cantErrors + ", puede solicitar apertura remota")
        this.notifyService.showError("Error de Autenticacion N°" + this.cantErrors + ", puede solicitar apertura remota","");
        this.isButtonVisible = true;
        return
      }
     // console.log("Error de Autenticacion N°" + this.cantErrors)
      this.notifyService.showError("Error de Autenticacion N°" + this.cantErrors,"");
  }

  public goToEnroll(){
    console.log("Remote Login accepted")
    this.notifyService.showSuccess("Registro de Huella Autorizado","")
    this.loginWithFingerprintService.determineLocalIpByUrl()
    .then((localIp) => this.router.navigate(['app/enrollFingerprint'], { queryParams: { ip: localIp } }))    
  }

  public requestRemoteLogin(){
    console.log("Remote Login requested")
    this.notifyService.showSuccess("Acceso remoto solicitado","")
    this.loginWithFingerprintService.determineLocalIpByUrl()
    .then((localIp) => this.http.get(`${environment.apiBaseUrl}/api/users/requestRemoteLogin/`+localIp)
    .subscribe((data) => console.log("Resultado de la solicitud:... ")))
  }

  public requestRemoteEnroll(){
    console.log("Remote Enroll requested")
    this.notifyService.showSuccess("Registro de huella dactilar solicitado. Aguarde un momento y será redireccionado","")
    this.loginWithFingerprintService.determineLocalIpByUrl()
    .then((localIp) => this.http.get(`${environment.apiBaseUrl}/api/users/requestRemoteEnroll/`+localIp)
    .subscribe((data) => console.log("Resultado de la solicitud:... ")))
  }

  public goToLogin(){
 this.router.navigate(['/login']);    
  }

  
  clearAttemps(){
    this.cantErrors = 0
    this.notifyService.showSuccess("Intentos reiniciados exitosamente","")
  }
}
