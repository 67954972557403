import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LayoutsModule } from './layouts';
import { CommonLayoutComponent } from './layouts/common-layout';
import { ChartsComponent } from './pages/charts';
import { ComponentsComponent } from './pages/components';
import { DashboardComponent } from './pages/dashboard';
import { Dashboard2Component } from './pages/dashboard2';
import { FormsComponent } from './pages/forms';
import { LoginComponent } from './pages/pages/login'
import { AuthGuard } from './services';
import { AuthGuardForLogin } from './services/auth/auth.guarForLogin';
import { AuthGuardForAdmin } from './services/auth/auth.guardForAdmin';
import { AuthGuardForRenaper } from './services/auth/auth.guardForRenaper';
import { AuthGuardForSesion } from './services/auth/auth.guardForSesion';
import { AuthGuardForComision } from './services/auth/auth.guardForComision';
import { AuthGuardForReunion } from './services/auth/auth.guardForReunion';
import { AuthGuardForOffSesion } from './services/auth/auth.guardForOffSesion';
import { ErrorComponent } from './pages/pages/error';
import { BlankLayoutComponent } from './layouts/blank-layout';
import { RenaperComponent } from './renaper/renaper.component';
import { ConferenceComponent } from './conference';
import { GrillaComponent } from './grilla/grilla.component';
import { MenuComponent } from './menu/menu.component';
import { SumarioComponent } from './sumario/sumario.component';
import { SumarioPresencialComponent } from './sumarioPresencial/sumarioPresencial.component';
import { SesionComponent } from './sesion/sesion.component';
import { OffSesionComponent } from './off-sesion/off-sesion.component';
import { NominalComponent } from './nominal/nominal.component';
import { DiputadosComponent } from './diputados/diputados.component';
import { AgregarUsuarioComponent } from './agregar-usuario/agregar-usuario.component';
import { BifurcacionComponent } from './bifurcacion/bifurcacion.component';
import { DashboardComisionComponent } from 'app/comision/dashboard/dashboard-comision.component'
import { ReunionComponent } from 'app/comision/reunion/reunion.component'
import { SesionAdminComponent } from 'app/sesion-admin/sesion-admin.component'
import { AgregarSesionComponent } from './agregar-sesion/agregar-sesion.component';
import { AusentesComponent } from './ausentes/ausentes.component'
import { LoginWithFingerprintComponent } from './login-with-fingerprint/login-with-fingerprint.component';
import { EnrollFingerprintComponent } from './enroll-fingerprint/enroll-fingerprint.component';
import { EnrollAdminComponent } from './enroll-admin/enroll-admin.component';
import { IndexPresencialDiputadoComponent } from './index-presencial-diputado/index-presencial-diputado.component';
import { IndexRemoteDiputadoComponent } from './index-remote-diputado/index-remote-diputado.component';
import { UjierComponent } from './ujier/ujier.component';
import { ObserverComponent } from './components/observer/observer.component';
import { PresidenteComponent } from './components/presidente/presidente.component';
import { UsulegComponent } from './components/usuleg/usuleg.component';
import { SeclegComponent } from './components/secleg/secleg.component';
import { CargarVotacionComponent } from './components/cargar-votacion/cargar-votacion.component';
import { SecadminComponent } from './components/secadmin/secadmin.component';
import { OradoresComponent } from './components/oradores/oradores.component';


@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        { path: '', redirectTo: '/login', pathMatch: 'full' },
        {
          path: '', component: BlankLayoutComponent, children: [
            { path: '404', component: ErrorComponent, pathMatch: 'full' },
            { path: 'login', component: LoginComponent, pathMatch: 'full' },
            { path: 'loginWithFingerprint', component: LoginWithFingerprintComponent, pathMatch: 'full' },
            { path: 'renaper', component: RenaperComponent, canActivate: [AuthGuardForRenaper], pathMatch: 'full' },
          ]
        },
        {
          path: 'app', component: CommonLayoutComponent, canActivate: [AuthGuard], children: [
           
           
            { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardForSesion], pathMatch: 'full' },

            { path: 'index-presencial', component: IndexPresencialDiputadoComponent, canActivate: [AuthGuardForSesion], pathMatch: 'full' },

            { path: 'index-remote', component: IndexRemoteDiputadoComponent, canActivate: [AuthGuardForSesion], pathMatch: 'full' },

            { path: 'ujier', component: UjierComponent, canActivate: [AuthGuardForSesion], pathMatch: 'full' },

            { path: 'presidente', component: PresidenteComponent, canActivate: [AuthGuardForSesion], pathMatch: 'full' },

            { path: 'observador', component: ObserverComponent, canActivate: [AuthGuardForSesion], pathMatch: 'full' },

            { path: 'usuariolegislativo', component: UsulegComponent, canActivate: [AuthGuardForSesion], pathMatch: 'full' },

            { path: 'secretariolegislativo', component: SeclegComponent, canActivate: [AuthGuardForSesion], pathMatch: 'full' },

            { path: 'secretarioadministrativo', component: SecadminComponent, canActivate: [AuthGuardForSesion], pathMatch: 'full' },

            { path: 'cargar-votaciones', component: CargarVotacionComponent, canActivate: [AuthGuardForSesion], pathMatch: 'full' },
            
            

           
            



            { path: 'dashboard-comision', component: DashboardComisionComponent, canActivate: [AuthGuardForComision], pathMatch: 'full' },
            { path: 'reunion', component: ReunionComponent, canActivate: [AuthGuardForReunion], pathMatch: 'full' },
            //{ path: 'conference', component: ConferenceComponent, pathMatch: 'full' },
            { path: 'grilla', component: GrillaComponent, canActivate: [AuthGuardForAdmin], pathMatch: 'full' },
            { path: 'sesion-admin', component: SesionAdminComponent, canActivate: [AuthGuardForAdmin], pathMatch: 'full' },
            { path: 'oradores', component: OradoresComponent, canActivate: [AuthGuardForAdmin], pathMatch: 'full'},
            { path: 'nominal', component: NominalComponent, canActivate: [AuthGuardForSesion], pathMatch: 'full' },
            { path: 'diputados', component: DiputadosComponent, canActivate: [AuthGuardForAdmin], pathMatch: 'full' },
            { path: 'ausentes', component: AusentesComponent, canActivate: [AuthGuardForAdmin], pathMatch: 'full' },
            { path: 'agregar-usuario', component: AgregarUsuarioComponent, canActivate: [AuthGuardForAdmin], pathMatch: 'full' },
            { path: 'agregar-sesion', component: AgregarSesionComponent, canActivate: [AuthGuardForAdmin], pathMatch: 'full' },
            { path: 'menu', component: MenuComponent, canActivate: [AuthGuardForAdmin], pathMatch: 'full' },
            { path: 'sesion', component: SesionComponent, canActivate: [AuthGuardForAdmin], pathMatch: 'full' },
            { path: 'sumario', component: SumarioComponent, canActivate: [AuthGuardForAdmin], pathMatch: 'full' },
            { path: 'sumarioPresencial', component: SumarioPresencialComponent, canActivate: [AuthGuardForAdmin], pathMatch: 'full' },

            { path: 'enrollAdmin', component: EnrollAdminComponent, canActivate: [AuthGuardForAdmin], pathMatch: 'full' },
            { path: 'enrollFingerprint', component: EnrollFingerprintComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'off-sesion', component: OffSesionComponent, canActivate: [AuthGuardForOffSesion], pathMatch: 'full' },
            { path: 'bifurcacion', component: BifurcacionComponent, canActivate: [AuthGuard], pathMatch: 'full' },

            //{ path: '**', redirectTo: '/404' },

          ]
        }, // add 'canActivate: AuthGuard' for catching unauth users
        /*{ path: 'ui', loadChildren: './pages/ui/ui.module#UIModule' },
        { path: 'maps', loadChildren: './pages/maps/maps.module#MapsModule' },
        { path: 'pages', loadChildren: './pages/pages/pages.module#PagesModule' },*/
        { path: '**', redirectTo: '/404' },

      ],
      { useHash: true, relativeLinkResolution: 'legacy' },
    ),
    
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
