// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

var url ='https://sesionar.hcdiputados-ba.gov.ar';
export const environment = {
  production: false,
  apiBaseUrl: 'https://sesionar.hcdiputados-ba.gov.ar/api',
  votacionHubUrl: 'https://sesionar.hcdiputados-ba.gov.ar/hub/votacionHub',
  conferenceHubUrl: 'https://sesionar.hcdiputados-ba.gov.ar/hub/conferenceHub',
  loginWithFingerprintHubUrl: 'https://sesionar.hcdiputados-ba.gov.ar/hub/loginWithFingerprintHub',
  loginRequestHub: 'https://sesionar.hcdiputados-ba.gov.ar/hub/adminHub'
};
