import { Component, OnInit } from '@angular/core';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import { Subject, Observable, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders, HttpBackend} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from 'app/services';
import { User } from 'app/models';
import { Router } from '@angular/router';
import { ConferenceService } from 'app/services/conference/conference.service.js';
import * as CryptoJS from 'crypto-js';
import { NotificationService } from 'app/services/notification/notification.service';
import { BlankLayoutCardComponent } from 'app/components/blank-layout-card';
import { FormGroup, FormBuilder, FormControl,Validators } from '@angular/forms';
import { SignalRService } from 'app/services/signal-r/signal-r.service';

@Component({
  selector: 'app-renaper',
  host: {'class': 'blank-layout-card mdl-grid ui-components'},
  templateUrl: './renaper.component.html',
  styleUrls: ['./renaper.component.scss']
})
export class RenaperComponent extends BlankLayoutCardComponent implements OnInit {
  private httpSI: HttpClient;
  currentUser: User;
  currentUserSubscription: Subscription;
  public loading: boolean = false;
  admin: boolean = false;
  join: boolean;
  barra: boolean;
  putPin:boolean;
  public pinForm: FormGroup;
  pin:any;
  constructor(private http: HttpClient,
    private authservice: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private handler: HttpBackend,
    private confeservice: ConferenceService,
    public signalRService: SignalRService,
    private notifyService : NotificationService) {

      super();
      this.httpSI = new HttpClient(handler);

      this.currentUserSubscription = this.authservice.currentUser.subscribe(user => {
        this.currentUser = user;
      });

      this.pinForm = this.fb.group({
        pin: new FormControl('', Validators.required),
      });
      this.pin = this.pinForm.get('pin');
  }


  get f() { return this.pinForm.controls; }
  public onInputChange(event) {
    event.target.required = true;
  }

  goLogin:any;
  statePic:any;
  type: String;
  imagesAr:any[]=[];
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
      // width: {ideal: 1024},
      // height: {ideal: 576}
    };
    public errors: WebcamInitError[] = [];

    // latest snapshot
    public webcamImage: WebcamImage = null;

    // webcam snapshot trigger
    private trigger: Subject<void> = new Subject<void>();
    // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
    private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();

    public ngOnInit(): void {
      localStorage.setItem('conferenceCode', JSON.stringify(CryptoJS.AES.encrypt("false","codigoRenaper_secret").toString()));
      this.putPin=false;
      if(this.currentUser){
        if(this.currentUser.rol=="UADMIN"){
          this.admin=true;
        }
      }
      WebcamUtil.getAvailableVideoInputs()
        .then((mediaDevices: MediaDeviceInfo[]) => {
          this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
        });
        this.statePic=0;
    }

    public serStatePic(){
      this.statePic=0;
    }

    public triggerSnapshot(): void {
      this.trigger.next();
    }

    public toggleWebcam(): void {
      this.showWebcam = !this.showWebcam;
    }

    public handleInitError(error: WebcamInitError): void {
      this.errors.push(error);
    }

    public showNextWebcam(directionOrDeviceId: boolean|string): void {
      // true => move forward through devices
      // false => move backwards through devices
      // string => move to device with given deviceId
      this.nextWebcam.next(directionOrDeviceId);
    }

    public handleImage(webcamImage: WebcamImage): void {
      //console.info('received webcam image', webcamImage);
      
      var info: any;
      if(this.statePic==0){
        this.showToasterMsg("Foto rostro natural tomada con exito!");
        this.type = "SN";
      };
      if(this.statePic==1){
        this.showToasterMsg("Foto sonriendo tomada con exito!");
        this.type = "SS";
      };
      if(this.statePic==2){
        this.showToasterMsg("Foto guiñando un ojo tomada con exito!");
        this.type = "SBL";
      };
      info={"file":`${webcamImage.imageAsBase64}`,
      "imageType": `${this.type}`};
      //console.log(info)

      this.imagesAr.push(info);
      this.statePic ++;
      //console.log(this.statePic);
      if(this.statePic==3){

        this.buildJson();
      }
      //console.log(this.imagesAr)
      //this.webcamImage = webcamImage;
    }

    buildJson(){
      //console.log(this.loading)
      let head = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      var completeInfo:any;
      completeInfo=`{"number":"${this.currentUser.dni}","gender":"${this.currentUser.genero}","selfieList":${JSON.stringify(this.imagesAr)}}`
      //localStorage.setItem('conferenceCode', JSON.stringify(CryptoJS.AES.encrypt("10","codigoRenaper_secret").toString()));
      //console.log((JSON.parse(localStorage.getItem('conferenceCode'))).toString());
      //console.log(CryptoJS.AES.decrypt((JSON.parse(localStorage.getItem('conferenceCode'))).toString(),"codigoRenaper_secret").toString(CryptoJS.enc.Utf8));
      //this.router.navigate(['/app/dashboard'])
      //this.statePic=0
      //this.goLogin=true;
      this.goLogin=false;
      this.loading=true;
      this.http.post(`${environment.apiBaseUrl}/api/renaper`,JSON.stringify(completeInfo),{headers: head}).subscribe(
        (data:any) =>{

          var req:any;
          //console.log("Respuesta RENAPER")
          //console.log(data)
          req=JSON.parse(data.content);
          if(req.matchThreshold){
            this.join=true;
            localStorage.setItem('conferenceCode', JSON.stringify(CryptoJS.AES.encrypt((req.matchThreshold).toString(),"codigoRenaper_secret").toString()));
            this.showToasterMsg(`Porcentaje de validación: ${req.confidence}%`,"Identificación de RENAPER aprobada!")
          }else{
            this.join=false;
            /*this.statePic=0;*/
            localStorage.setItem('conferenceCode', JSON.stringify(CryptoJS.AES.encrypt("false","codigoRenaper_secret").toString()));
            this.showFailToasterMsg("Validación RENAPER","Identificación rechazada.");
            this.putPin=true;

          }
          this.loading=false;
          this.imagesAr=[];
        },
      error=>{
        this.join=false;
        /*this.statePic=0;*/
        localStorage.setItem('conferenceCode', JSON.stringify(CryptoJS.AES.encrypt("false","codigoRenaper_secret").toString()));
        this.showFailToasterMsg("Validación RENAPER","Identificación rechazada.");
        this.putPin=true;
        this.loading=false;
        this.imagesAr=[];
      }
      );
    }

    checkPin(){
      let head = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      var pin_user:any;
      this.loading=true;
      this.putPin=false;

      pin_user=`{"id_usuario":"${this.currentUser.usuario}","pin":"${this.f.pin.value}"}`
      //console.log(pin_user);
      this.http.post(`${environment.apiBaseUrl}/api/users/chekPIN`,pin_user,{headers: head}).subscribe(
        (data:any)=>{
          this.pinForm.reset();

          if(data.login){
            this.showToasterMsg(data.message);
            localStorage.setItem('conferenceCode', JSON.stringify(CryptoJS.AES.encrypt("true","codigoRenaper_secret").toString()));
            this.toHome();
          }else{
            this.showFailToasterMsg(data.message);
            this.loading=false;
            this.putPin=true;
          }
        }
      )

    }

    showToasterMsg(msj1,msj2=""){
        this.notifyService.showSuccess(msj1,msj2);
    }

    showFailToasterMsg(msj1,msj2=""){
      this.notifyService.showError(msj1,msj2);
    }

    toHome(){
      //this.signalRService.startConnection();
     // this.signalRService.startListeningToNuevaVotacion();
      if(this.currentUser.rol=="UADMIN" || this.currentUser.rol=="OBSERV"){
        localStorage.setItem('conferenceCode', JSON.stringify(CryptoJS.AES.encrypt("true","codigoRenaper_secret").toString()));
        this.router.navigate(['app/bifurcacion']);
      } else {
        this.router.navigate(['app/bifurcacion']);
/*         this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/${this.currentUser.id}/entrar`).subscribe(data=>{
          this.router.navigate(['/app/dashboard'])}
         ) */
      }
    }

    toLogin(){
      this.router.navigate(['/login']);
    };
    toRenaper(){
      this.putPin=false;
      this.statePic=0;
      
      this.router.navigate(['/renaper']);
    };

    public cameraWasSwitched(deviceId: string): void {
      //console.log('active device: ' + deviceId);
      this.deviceId = deviceId;
    }

    public get triggerObservable(): Observable<void> {
      return this.trigger.asObservable();
    }

    public get nextWebcamObservable(): Observable<boolean|string> {
      return this.nextWebcam.asObservable();
    }

}
