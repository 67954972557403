import { Component, OnInit, HostBinding } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../services/';
import { BlankLayoutCardComponent } from 'app/components/blank-layout-card';
import { first, filter } from 'rxjs/operators';
import { NotificationService } from '../../../services/notification/notification.service';
import { HttpClient } from '@angular/common/http';
import { User } from 'app/models';
import * as CryptoJS from 'crypto-js';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-login',
  styleUrls: ['../../../components/blank-layout-card/blank-layout-card.component.scss',
              '../../../pages/pages/login/login.component.scss'],
  templateUrl: './login.component.html',
})
export class LoginComponent extends BlankLayoutCardComponent implements OnInit {
  @HostBinding('class.mdl-grid') private readonly mdlGrid = true;
  @HostBinding('class.ui-components') private readonly uiComponents = true;
  public loginForm: FormGroup;
  public username;
  public password;
  //public usernamePattern = '^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$';
  public error: string;
  public loading: boolean;
  currentUser: User;
  currentUserSubscription: Subscription;

  constructor(private authService: AuthService,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private http: HttpClient,
              private notifyService : NotificationService) {
    super();
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.loginForm = this.fb.group({
      password: new FormControl('', Validators.required),
      username: new FormControl('', Validators.required),
    });
    this.username = this.loginForm.get('username');
    this.password = this.loginForm.get('password');
  }

  public ngOnInit() {
    this.authService.logout();
    this.loginForm.valueChanges.subscribe(() => {
      this.error = null;
    });
  }
  get f() { return this.loginForm.controls; }

  public login() {
    this.error = null;
    this.loading = true;

    if (this.loginForm.valid) {
      /*this.authService.login2(this.f.username.value, this.f.password.value);
      this.router.navigate(['/renaper']);*/

      this.authService.login(this.f.username.value, this.f.password.value).pipe(first())
        .subscribe(res =>{  
                            if(res.login){
                              this.http.get(`${environment.apiBaseUrl}/api/users/comisiones/${this.currentUser.id}`).subscribe((dataComision:any)=>{
                                localStorage.setItem('dataComision',JSON.stringify(dataComision))
                                  
                                  this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/iniciado`).subscribe((data:any)=>{
                                    localStorage.setItem('dataIniciado',JSON.stringify(data))
                                    this.showToasterSuccess("Usuario loggeado! Sin sesión.");
                                    if(this.currentUser.validarRenaper){
                                      this.router.navigate(['/renaper']);
                                    }else{
                                      localStorage.setItem('conferenceCode', JSON.stringify(CryptoJS.AES.encrypt("true","codigoRenaper_secret").toString()));
                                      if(data != null && data.fechaHoraInicia != null && data.fechaHoraFinaliza == null || dataComision != null || this.currentUser.rol == 'OBSERV'){
                                        this.router.navigate(['app/bifurcacion']);
                                      }else{
                                        this.router.navigate(['app/off-sesion']);
                                      }
                                    }                                  

/*                                  if(data != null && data.fechaHoraInicia != null && data.fechaHoraFinaliza == null || dataComision != null){
                                      console.log('Estoy en el if');
                                      if(this.currentUser.validarRenaper){
                                        this.router.navigate(['/renaper']);
                                      }else{
                                        localStorage.setItem('conferenceCode', JSON.stringify(CryptoJS.AES.encrypt("true","codigoRenaper_secret").toString()));
                                        this.router.navigate(['app/bifurcacion']);
                                      }
                                    }else{
                                      this.router.navigate(['app/off-sesion']);
                                    }
                                    this.showToasterSuccess("Usuario loggeado! Sin sesión."); */
                                  },
                                  error=>{
                                    this.showToasterSuccess("Usuario loggeado!");
                                    this.router.navigate(['app/off-sesion']);
                                  }
                                  );
                            },
                            error=>{
                              this.showToasterSuccess("Usuario loggeado!");
                              this.router.navigate(['app/off-sesion']);
                            })
                            }
                            else
                            { 
                              this.loading = false;
                              //console.log(res.message)
                              this.error=res.message;
                              this.showToasterError(this.error);
                            }
                          },
                   error =>{
                              this.loading = false;
                              if (error.message == `Http failure response for ${environment.apiBaseUrl}/api/users/login: 400 OK`){
                                this.error = "Usuario o contraseña incorrectos."
                              } else {
                                this.error = `Servidor en mantenimiento, intente de nuevo mas tarde.${error.message}`
                              }
                              this.showToasterError(this.error);
                            });
    }
  }

  public onInputChange(event) {
    event.target.required = true;
  }


  showToasterSuccess(msj1,msj2=""){
    this.notifyService.showSuccess(msj1,msj2)
  }

  showToasterError(msj1,msj2=""){
    if (this.error && this.loading==false){
      this.notifyService.showError(msj1,msj2)}
    }

  showToasterInfo(msj1,msj2=""){
      this.notifyService.showInfo(msj1,msj2)
  }

  showToasterWarning(msj1,msj2=""){
      this.notifyService.showWarning(msj1,msj2)
  }

  public goToLoginWithFingerprint() {
    this.router.navigate(['/loginWithFingerprint']);
  }
}
