import { Component, OnInit} from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SignalRService } from 'app/services/signal-r/signal-r.service';
import { MensajeHub } from '../models/mensajeHub';
import { ConferenceService } from 'app/services/conference/conference.service';
import { BlankLayoutCardComponent } from 'app/components/blank-layout-card';
import { UpgradableComponent } from 'theme/components/upgradable';


@Component({
  selector: 'app-hemiciclo-votacion',
  templateUrl: './hemiciclo-votacion.component.html',
  styleUrls: ['./hemiciclo-votacion.component.scss']
})
export class HemicicloVotacionComponent extends  UpgradableComponent implements OnInit {
  public hemicicloVotacion:any;
  public diputados:any[]= [];
  fecha_sesion: string="";
  nro_sesion: string="";
  nro_periodo: string="";
  caratula: string="";
  expediente: string="";
  descripcion: string="";
  cantidadPresentes: number=0;
  cantidadPositivos: number=0;
  cantidadNegativos: number=0;
  cantidadAbstenciones: number=0;
  votacion_id:string;
  private mensajeOBJ: any;
  data:any;
  constructor(private http: HttpClient,
              public SignalR:SignalRService,
              public confeservice: ConferenceService,) {
                super()
  }


  ngOnInit(): void {

    if(localStorage.getItem("hemicicloVotacion")){
      this.hemicicloVotacion=JSON.parse(localStorage.getItem("hemicicloVotacion"))
      this.diputados = this.hemicicloVotacion.diputados;
      this.cantidadPositivos = this.hemicicloVotacion.cantPositivas;
      this.cantidadNegativos = this.hemicicloVotacion.cantNegativas;
      this.cantidadAbstenciones = this.hemicicloVotacion.cantAbstenciones;

      this.cantidadPresentes = this.hemicicloVotacion.cantPresentes;
      this.fecha_sesion=this.hemicicloVotacion.fecha_sesion;
      this.nro_periodo=this.hemicicloVotacion.nro_periodo;
      this.nro_sesion=this.hemicicloVotacion.nro_sesion;
      this.expediente = this.hemicicloVotacion.expediente;
      this.caratula=this.hemicicloVotacion.caratula;
      this.descripcion = this.hemicicloVotacion.descripcion;
    }

/*     this.loadData(); */

    this.SignalR.getVotacion$().subscribe((data:MensajeHub)=>{
      if(data.tipo == MensajeHub.CONST_TIPO_VOTO_HEMICICLO){
        this.mensajeOBJ=JSON.parse(data.mensajeJSON);
        this.hemicicloVotacion=this.mensajeOBJ;
        localStorage.setItem("hemicicloVotacion",JSON.stringify(this.hemicicloVotacion));
        this.diputados=this.hemicicloVotacion.diputados;
        this.cantidadPositivos = this.hemicicloVotacion.cantPositivas;
        this.cantidadNegativos = this.hemicicloVotacion.cantNegativas;
        this.cantidadAbstenciones = this.hemicicloVotacion.cantAbstenciones;
        this.cantidadPresentes = this.hemicicloVotacion.cantPresentes;
        this.fecha_sesion=this.hemicicloVotacion.fecha_sesion;
        this.nro_periodo=this.hemicicloVotacion.nro_periodo;
        this.nro_sesion=this.hemicicloVotacion.nro_sesion;
        this.expediente = this.hemicicloVotacion.expediente;
        this.caratula=this.hemicicloVotacion.caratula;
        this.descripcion = this.hemicicloVotacion.descripcion;
      }
    })
  }

  limpiarHemiciclo(){
    localStorage.removeItem("hemicicloVotacion");
    localStorage.setItem("mostrarVotacion","false");
  }

  Salir(){
    
  }
}


