import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Oradores } from 'app/models/oradores';

@Injectable({ providedIn: 'root' })
export class OradoresService {

    path: string = `${environment.apiBaseUrl}/api/sesionInstancia/oradores/`

    constructor(private http: HttpClient) {}
   
    public getOradores(id_sesion: any):Observable<Oradores[]> {
        var url = this.path + `${id_sesion}`
        return this.http.get<Oradores[]>(url)      
    }

    public enviarOradores(oradores: Oradores[], id_sesion: any){
        var url = `${environment.apiBaseUrl}/api/sesionInstancia/oradores/actualizar/${id_sesion}`
        let head = new HttpHeaders({
          'Content-Type': 'application/json'
        });        
        this.http.post(url, oradores, {headers: head}).subscribe()
    }
 
    public getDiputados(){
        var url = this.path + `disponibles`
        return this.http.get<Oradores[]>(url)
    }
}
