import { Injectable, ErrorHandler, Injector, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate,Router, RouterStateSnapshot } from '@angular/router';
import { ConferenceService } from 'app/services/conference/conference.service.js';
import { AuthService } from './auth.service';
import { NotificationService } from '../../services/notification/notification.service';
import { zip } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root',
})
export class AuthGuardForOffSesion implements OnInit,CanActivate{
  private check: boolean = true;
  private toRenaper: boolean;
  private data:any;
  private dataComision:any;
  constructor(private authService: AuthService,
              private authenticationService: AuthService,
              private router: Router,
              private http: HttpClient,
              private notifyService : NotificationService,
              private confeservice: ConferenceService,) {

  }
  ngOnInit(): void {

  }

  canActivate(route: ActivatedRouteSnapshot){
     if(localStorage.getItem('dataIniciado')){
        this.data=JSON.parse(localStorage.getItem('dataIniciado'))
        this.dataComision=JSON.parse(localStorage.getItem('dataComision'))
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser && !this.data.fechaHoraInicia && !this.data.fechaHoraFinaliza && this.dataComision != 'null'){
            // authorised so return true
            return true;
        }else{
        // not logged in so redirect to login page with the return url
            this.showToasterError("Acceso denegado a esta pagina, (off session).");
            return false;
        };
      }
  }

  showToasterError(msj1,msj2=""){
    if(this.check==true){
      this.notifyService.showError(msj1,msj2);
      this.check=false;
      return;
    }
    this.check=true;
  }
}
