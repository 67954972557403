import { CommonModule } from '@angular/common';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { ThemeModule } from 'theme';
import { TooltipModule } from '../../../theme/directives/tooltip/tooltip.module';
import { CotoneasterCardComponent } from '../../pages/dashboard/cotoneaster-card';
import { DashboardComponent } from '../../pages/dashboard/dashboard.component';
import { LineChartComponent } from '../../pages/dashboard/line-chart';
import { PieChartComponent } from '../../pages/dashboard/pie-chart';
import { RobotCardComponent } from '../../pages/dashboard/robot-card';
import { TableCardComponent } from '../../pages/dashboard/table-card';
import { TodoListComponent } from '../../pages/dashboard/todo-list';
import { TrendingComponent } from '../../pages/dashboard/trending';
import { WeatherComponent } from '../../pages/dashboard/weather';
import { ConferenceComponent } from '../../conference';
import { MenuComponent} from 'app/menu/menu.component';
import { GrillaComponent} from 'app/grilla/grilla.component';
import { SumarioComponent } from '../../sumario/sumario.component';
import { SesionComponent } from '../../sesion';
import { HemicicloComponent } from '../../hemiciclo/hemiciclo.component';
import { HemicicloVotacionComponent } from '../../hemiciclo-votacion/hemiciclo-votacion.component';

@NgModule({
  imports: [
    CommonModule,
    ThemeModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
  ],
  declarations: [
  ],
  exports: [
  ],
})
export class DashboardComisionModule { }

