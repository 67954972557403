import { Component, OnInit, HostListener, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { SignalRService } from 'app/services/signal-r/signal-r.service';
import { AuthService } from '@services/*';
import { UserService } from 'app/services/user.service';
import { Subscription, Observable } from 'rxjs';
import { User } from 'app/models';
import { first, delay } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ConferenceService } from 'app/services/conference/conference.service.js';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-common-layout',
  templateUrl: './common-layout.component.html',
})

export class CommonLayoutComponent implements OnInit,OnDestroy {



  @HostListener('window:unload', ['$event'])
  unloadHander($event) {
    if(confirm('Estas seguro que desea salir?')){
      if(this.currentUser.rol!="UADMIN" && this.currentUser.rol!="OBSERV" && this.currentUser.rol!="USUREL"){
        if(localStorage.getItem('idReunion')){
          this.http.get(`${environment.apiBaseUrl}/api/reunionInstancia/${this.currentUser.id}/${localStorage.getItem('idReunion')}/salir`).subscribe(
            (data:any)=>{
            });
          }
        }

      // esta linea cierra la conexion cuando se presiona "Salir"
      //this.SignalR.closeConnection();
      return $event.returnValue='Your changes will not be saved';
    }
  }


  public user;
  currentUser: User;
  currentUserSubscription: Subscription;
  users: User[] = [];
  hide: boolean = false;
  admin: boolean;

  constructor(private authService: AuthService,
              private userService: UserService,
              public router: Router,
              public SignalR:SignalRService,
              private confeservice: ConferenceService,
              private activatedRoute: ActivatedRoute,
              private http:HttpClient) {
                this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
                    this.currentUser = user;
                });

                this.confeservice.loadConference();
                this.SignalR.startConnection();
                this.SignalR.startListeningToNuevaVotacion();
                this.SignalR.startListeningToVotos();
                this.SignalR.startListeningToMicrofono();
                this.SignalR.startListeningOradores();

              /* window.addEventListener("close", (event) => {
                event.preventDefault();
                this.logout();
              });*/
              }



  ngOnDestroy() {
    if(confirm('Estas seguro que desea salir?')){
     // this.SignalR.closeConnection();
      if(localStorage.getItem("currentUser")){
        if(this.currentUser.rol!="UADMIN" && this.currentUser.rol!="OBSERV" && this.currentUser.rol!="USUREL"){
          if(localStorage.getItem('idReunion')){
            this.http.get(`${environment.apiBaseUrl}/api/reunionInstancia/${this.currentUser.id}/${localStorage.getItem('idReunion')}/salir`).subscribe(
              (data:any)=>{
              });
            }
         }
      }
    }
  };

  public ngOnInit() {
    this.http.get(`${environment.apiBaseUrl}/api/users/comisiones/${this.currentUser.id}`).subscribe((data:any)=>{
      localStorage.setItem('dataComision',JSON.stringify(data))
    })
    this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/iniciado`).subscribe((data:any)=>{
      localStorage.setItem('dataIniciado',JSON.stringify(data))
      localStorage.setItem("presentes",data.cantPresentes.toString());
    });

    if(this.currentUser.rol=="UADMIN"){
      this.admin=true;
    }else{
      this.admin=false;
    }


    if(this.router.url == '/app/dashboard'){
      this.hide = true
    };

    this.authService.userData.subscribe(user => this.user = user ? user : {
      nombre: this.currentUser.nombre,
      apellido: this.currentUser.apellido,
    })

  }

  unload(){
    if(confirm('Estas seguro que desea salir?')){
      if(this.currentUser.rol!="UADMIN" && this.currentUser.rol!="OBSERV"  && this.currentUser.rol!="USUREL"){
        if( localStorage.getItem('idReunion')){
          this.http.get(`${environment.apiBaseUrl}/api/reunionInstancia/${this.currentUser.id}/${localStorage.getItem('idReunion')}/salir`).subscribe(
            (data:any)=>{
            });
          }
        }
      this.SignalR.closeConnection();
    }
  }

  public logoutForEvent(){

  };

  public logout() {
    if(this.currentUser.rol!="UADMIN" && this.currentUser.rol!="OBSERV" && this.currentUser.rol!="USUREL"){      

    }
    this.router.navigate(['/login']);
  }

  public home() {

    this.router.navigate(['/app/dashboard']);
  }

  private loadAllUsers() {
    this.userService.getAll().pipe(first()).subscribe(users => {
        this.users = users;
    });
  }

  esDiputadoYNoPresi() {
    return (this.currentUser.rol == "USUDIP");
  }

  esPresi() {
    return (this.currentUser.rol == "UPRESI");
  }

  esUjier() {
    return this.currentUser.rol == "UUJIER" || this.currentUser.rol == "UADMIN";
  }

  esUsuleg() {
    return this.currentUser.rol == "USULEG";
  }

  esAdmin() {
    return this.currentUser.rol == "UADMIN";
  }

  esDiputado(){
    return this.currentUser.rol == "USUDIP" || this.currentUser.rol == "UPRESI";
  }

  public Salir() {
    if (this.currentUser.rol == "USUDIP" || this.currentUser.rol == "UPRESI") {
      this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/${this.currentUser.id}/salir`).subscribe((data: any) => {
        this.router.navigate(['/login']);
      },
        error => {
        });
    }
    this.SignalR.closeConnection();
    this.router.navigate(['/login']);
  }

}
