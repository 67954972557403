import { Component, HostBinding, OnInit, OnDestroy, HostListener } from '@angular/core';
import { UpgradableComponent } from 'theme/components/upgradable';
import { Observable, Subject, Subscription } from 'rxjs';
import { SignalRService } from 'app/services/signal-r/signal-r.service';

import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@services/*';
import { User } from 'app/models';
import { MensajeHub } from '../models/mensajeHub';
import { GrillaService} from 'app/services/grilla.service';
import { NotificationService } from 'app/services/notification/notification.service';
import { delay } from 'rxjs/operators';
import { ConferenceService } from 'app/services/conference/conference.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ujier',
  templateUrl: './ujier.component.html',
  styleUrls: ['./ujier.component.scss']
})
export class UjierComponent extends UpgradableComponent implements OnInit,OnDestroy {
  @HostBinding('class.mdl-grid') private readonly mdlGrid = true;
  @HostBinding('class.mdl-grid--no-spacing') private readonly mdlGridNoSpacing = true;
  @HostListener('window:unload', ['$event'])
  unloadHander($event) {
    this.SignalR.closeConnection();
    return $event.returnValue='Your changes will not be saved';
  }

  hide:boolean = true;

  currentUser: User;
  currentUserSubscription: Subscription;
  ujier: boolean = false;
  observ: boolean = false;
  check: any = true;
  toasWcheck: boolean;
  toasScheck: boolean;
  toasNcheck: boolean;
  toasPcheck: boolean;
  toasEcheck: boolean;
  toasAcheck: boolean;
  mostrarVotacion: any=false;
  scrollbol:boolean=false;

  constructor(
    public SignalR:SignalRService,
    private http:HttpClient,
    private confeservice: ConferenceService,
    private notifyService : NotificationService,
    private authService: AuthService,
    public gr: GrillaService) {
    super();
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      this.currentUser = user;
    })
  }
  
  ngOnDestroy() {
    if(localStorage.getItem("currentUser")){
    }
  };
  scroll(el: HTMLElement) {
    if(this.scrollbol){
      el.scrollIntoView({behavior: 'smooth'});
      this.scrollbol=false;
    }
  }
  ngOnInit(): void {
    localStorage.removeItem('idReunion');
    if(localStorage.getItem("currentUser")){

    }

    this.hide=true;

    // Reconozco si el usuario tiene roles de ujier.
    if (this.currentUser.rol=="UUJIER"){
      this.ujier=true;
    }else{
      this.ujier=false;
    }

    
    this.SignalR.startListeningNewLogoutRequest(this)
  }

  chequearHemiciclo(){
    if(localStorage.getItem("mostrarVotacion")){
      this.mostrarVotacion=localStorage.getItem("mostrarVotacion");
    }else{
      this.mostrarVotacion="false";
    }
    return this.mostrarVotacion;
  }

  stopConnectio(){
    this.SignalR.closeConnection();
  }
  showToasterFinalizado(msj1,msj2=""){
    if(this.toasEcheck==true){
      this.notifyService.showError(msj1,msj2);
      this.toasEcheck=false;
      return;
   }
  }

  showToasterAnulado(msj1,msj2=""){
    if(this.toasAcheck==true){
      this.notifyService.showError(msj1,msj2);
      this.toasAcheck=false;
      return;
   }
  }
  showToasterEfectuar(msj1,msj2=""){
    if(this.toasWcheck==true){
      this.notifyService.showWarning(msj1,msj2);
      this.toasWcheck=false;
      return;
    }
  }

  showToasterNegativo(msj1,msj2=""){
    if(this.toasNcheck==true){
      this.notifyService.showSuccess(msj1,msj2);
      this.toasNcheck=false;
      return;
    }
  }

  showToasterAfirmativo(msj1,msj2=""){
    if(this.toasPcheck==true){
      this.notifyService.showSuccess(msj1,msj2);
      this.toasPcheck=false;
      return;
    }
  }

}