import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SignalRService } from 'app/services/signal-r/signal-r.service';
import { ConferenceService } from 'app/services/conference/conference.service';
import { AuthService } from 'app/services/auth/auth.service';

import { UpgradableComponent } from 'theme/components/upgradable';
import { environment } from '../../../environments/environment';
import { MensajeHub } from 'app/models/mensajeHub';
import { Bloque } from 'app/models/Bloque';
import { xhr } from 'd3';

@Component({
  selector: 'app-lista-ausentes',
  templateUrl: './lista-ausentes.component.html',
  styleUrls: ['./lista-ausentes.component.scss'],
})
export class ListaAusentesComponent extends UpgradableComponent implements OnInit {

  public presentes: any[] = [];
  public ausentes: any[] = [];
  public diputados: any[] = [];
  private mensajeOBJ: any;
  public idDiputados: any[] = [];
  public bloques: Bloque[] = [];

  data: any;

  constructor(private http: HttpClient,
    public SignalR: SignalRService,
    public confeservice: ConferenceService,
    private router: Router,
    private authService: AuthService) {
    super();
    this.diputados = [];
    this.ausentes = [];
    this.idDiputados = [];
    this.bloques = [];

    this.cargarBloques()
    this.cargarAusentes()
  }

  ngOnInit(): void {
    this.ordenarAusentes()
    //websocket
    this.cargarSocket()
  }

  //cargo los bloques obtenidos del endpoint 
  cargarBloques(): void {
    this.http.get(`${environment.apiBaseUrl}/api/bloque/disponibles`).subscribe(
      (data: Bloque[]) => {

        data.forEach(x => {
          let bloque = new Bloque()
          bloque.text = x.text
          bloque.cantidad = 0
          this.bloques.push(bloque)
        })
      });
  }

  cargarAusentes(): void {
    this.http.get(`${environment.apiBaseUrl}/api/users/getAllDiputados`).subscribe(
      (data: any) => {
        {
          data.forEach((x) => {
            this.diputados.push(x);
          })
        }

        this.idDiputados = data.map(d => d.id);
        this.http.get(`${environment.apiBaseUrl}/api/sesionInstanciaPresente/ausentes`).subscribe(
          (data: any) => {
            data.forEach((x) => {
              this.ausentes.push(x);
            })
            this.cargarCantidadPorBloques()
            this.ordenarAusentes()
          });
      });
  }

  ordenarAusentes(): void {
    this.ausentes.sort((a, b) => a.bloque.localeCompare(b.bloque))
  }

  cargarCantidadPorBloques(): void {

    this.bloques.forEach((x) => {
      x.cantidad = (this.ausentes.filter(a => a.bloque === x.text)).length
    });

  }

  cargarSocket(): void {
    this.SignalR.getVotacion$().subscribe((data: MensajeHub) => {
      if (data.tipo == MensajeHub.CONST_TIPO_ENTRA_SESION || data.tipo == MensajeHub.CONST_TIPO_SALE_SESION) {

        this.mensajeOBJ = JSON.parse(data.mensajeJSON);
        let presentesWS: any[] = this.mensajeOBJ.presentes

        while (this.ausentes.length > 0) {
          this.ausentes.pop();
        }

        for (var i = 0; i < this.diputados.length; i++) {
          if (!presentesWS.includes(this.diputados[i].id)) {
            this.ausentes.push(this.diputados[i]);
          }
        }
        
        this.cargarCantidadPorBloques()
        this.ordenarAusentes()
      }
    })
  }

  bloquesFiltrados(){
    return this.bloques.filter(x=>x.cantidad>0)
  }
}


