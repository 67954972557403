import { SolicitudesService } from './../services/solicitudes.service';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LoginRequestService } from 'app/services/signal-r/loginRequest.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-table-login-request',
  templateUrl: './table-login-request.component.html',
  styleUrls: ['./table-login-request.component.scss']
})
export class TableLoginRequestComponent implements OnInit {
  //public loginRequests = new Set();

  constructor(public loginRequestService:LoginRequestService, private http: HttpClient,
    private solicitudesService:SolicitudesService) { }

  ngOnInit(): void {
    this.loginRequestService.startConnection(this)
    .then(() => this.loginRequestService.startListeningNewLoginRequest(this))
  }

  public reconnect(){
    // Intenta Reconectar a los 3seg
    return new Promise(function(resolve, reject) {  setTimeout(resolve, 3000)})
    .then(() => {
      console.log("Intentando reconexion")
      //Al terminar el Timeout (A los 3seg) Intentará Reconectar
      this.loginRequestService.connect()
    })
  }
  public addLoginRequest(data){
    //this.loginRequests.add(data)
    this.solicitudesService.solicitudNuevaLogin(data);
  }

  public getLoginRequests(){
    return this.solicitudesService.getSolicitudesLogin()
    //return this.loginRequests;
  }

  public acceptLoginRequest(item){
    //console.log("Solicitud de login aceptada a ",item)
    this.http.get(`${environment.apiBaseUrl}/api/users/acceptRequestRemoteLogin/${item.id}`)
    .subscribe((data:any)=>{
      //this.loginRequests.delete(item)
      this.solicitudesService.solicitudAtendidaLogin(item);
    });
  }
}
