import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SignalRService } from 'app/services/signal-r/signal-r.service';
import { ConferenceService } from 'app/services/conference/conference.service';
import { AuthService } from 'app/services/auth/auth.service';

import { UpgradableComponent } from 'theme/components/upgradable';
import { environment } from '../../../environments/environment';
import { MensajeHub } from 'app/models/mensajeHub';
import { Bloque } from 'app/models/Bloque';


@Component({
  selector: 'app-lista-presentes',
  templateUrl: './lista-presentes.component.html',
  styleUrls: ['./lista-presentes.component.scss']
})
export class ListaPresentesComponent extends UpgradableComponent implements OnInit {

  public presentes: any[] = [];
  public diputados: any[] = [];
  private mensajeOBJ: any;
  public idDiputados: any[] = [];
  data: any;
  public bloques: Bloque[] = [];

  constructor(private http: HttpClient,
    public SignalR: SignalRService,
    public confeservice: ConferenceService,
    private router: Router,
    private authService: AuthService) {
    super();
    this.diputados = [];
    this.presentes = [];
    this.idDiputados = [];
    this.bloques = [];

    this.cargarBloques()
    this.cargarPresentes()

  }

  ngOnInit(): void {

    this.ordenarPresentes()
    //websocket
    this.cargarSocket()

  }





  //cargo los bloques obtenidos del endpoint 
  cargarBloques(): void {
    this.http.get(`${environment.apiBaseUrl}/api/bloque/disponibles`).subscribe(
      (data: Bloque[]) => {

        data.forEach(x => {
          let bloque = new Bloque()
          bloque.text = x.text
          bloque.cantidad = 0
          this.bloques.push(bloque)
        })
      });
  }


  cargarPresentes(): void {
    this.http.get(`${environment.apiBaseUrl}/api/users/getAllDiputados`).subscribe(
      (data: any) => {
        {
          data.forEach((x) => {
            this.diputados.push(x);
          }
          )
        }
        this.idDiputados = data.map(d => d.id);
        this.http.get(`${environment.apiBaseUrl}/api/sesionInstanciaPresente/presentes`).subscribe(
          (data: any) => {
            data.forEach((x) => {
              this.presentes.push(x);
            })
            this.cargarCantidadPorBloques()
          });
      });

  }


  ordenarPresentes(): void {
    this.presentes.sort((a, b) => a.bloque.localeCompare(b.bloque))

  }


  cargarSocket(): void {

    //websocket
    this.SignalR.getVotacion$().subscribe((data: MensajeHub) => {
      if (data.tipo == MensajeHub.CONST_TIPO_ENTRA_SESION || data.tipo == MensajeHub.CONST_TIPO_SALE_SESION) {

        this.mensajeOBJ = JSON.parse(data.mensajeJSON);
        let presentesWS: any[] = this.mensajeOBJ.presentes

        while (this.presentes.length > 0) {
          this.presentes.pop();
        }

        for (var i = 0; i < this.diputados.length; i++) {
          if (presentesWS.includes(this.diputados[i].id)) {
            this.presentes.push(this.diputados[i]);
          }
        }
        this.cargarCantidadPorBloques()
        this.ordenarPresentes()
      }
    })
  }

  cargarCantidadPorBloques(): void {

    this.bloques.forEach((x) => {
      x.cantidad = (this.presentes.filter(a => a.bloque === x.text)).length
    });


  }

  bloquesFiltrados(){
    return this.bloques.filter(x=>x.cantidad>0)
  }

}
