import { NgModule ,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {WebcamModule} from 'ngx-webcam';
import { FormsModule } from '../pages/forms';
import {RenaperComponent} from 'app/renaper/renaper.component';
import { FormsModule as NgFormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from '../../theme/directives/tooltip/tooltip.module';
import { ThemeModule } from 'theme';
@NgModule({
  declarations: [RenaperComponent],
  imports: [
    BrowserModule,
    CommonModule,
    NgFormsModule,
    WebcamModule,
    FormsModule,
    ThemeModule,
    ReactiveFormsModule,
    TooltipModule,
  ]
})
export class RenaperModule { }
