import { Component, OnInit} from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SignalRService } from 'app/services/signal-r/signal-r.service';
import { MensajeHub } from '../../models/mensajeHub';
import { ConferenceService } from 'app/services/conference/conference.service';
import { BlankLayoutCardComponent } from 'app/components/blank-layout-card';
import { UpgradableComponent } from 'theme/components/upgradable';
import { User } from 'app/models';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth/auth.service';
import { CurrentUserService } from 'app/services/currentUser.service';

@Component({
  selector: 'app-hemiciclo-full',
  templateUrl: './hemiciclo-full.component.html',
  styleUrls: ['./hemiciclo-full.component.scss']
})

export class HemicicloFullComponent extends UpgradableComponent implements OnInit{
  public diputados:any[]= [];
  public presentes:any[]= [];
  public quorum:string = "";
  fecha_sesion: string;
  nro_sesion: string;
  nro_periodo: string;
  cantidadPresentes: number;
  cantMinQuorum: number;
  private mensajeOBJ: any;
  private httpSI: HttpClient;
  idSala: any;
  pwSala: any;
  error: any;
  urlSala: any;
  data:any;
  currentUser: User;
  currentUserSubscription: Subscription;
  rolesSecretario:any[]= ["UADMIN","SECLEG","UUJIER","UPRESI","SECADM","USULEG"];

  showHemiciclo:boolean=true;
  showPresentes:boolean=false;
  showAusentes:boolean=false;
  esSecretario:boolean=false;
  showGrilla:boolean=false;
  showOradores:boolean = false;

  
  constructor(private http: HttpClient,
              public SignalR:SignalRService,
              public confeservice: ConferenceService,
              private router: Router,
              private authService: AuthService
              ) {
                super();
                this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
                  this.currentUser = user;
                })
                this.diputados=[];
                this.presentes=[];
   }
  
  loadData(){
    //this.confeservice.loadConference();

    if(localStorage.getItem("dataIniciado")){
      this.data=JSON.parse(localStorage.getItem("dataIniciado"))
      if(this.data){
        localStorage.setItem("presentes",this.data.cantPresentes.toString());
       
        if(this.data.cantPresentes){
          this.cantidadPresentes = this.data.cantPresentes;
        };
        if(this.data.fecha_sesion){
          this.fecha_sesion=this.data.fecha_sesion;
          }
        if(this.data.nro_periodo){
          this.nro_periodo=this.data.nro_periodo;
          }
        if(this.data.nro_sesion){
          this.nro_sesion=this.data.nro_sesion;
          }
        if(this.data.cantMinQuorum){
          this.cantMinQuorum=this.data.cantMinQuorum;}
        if(this.cantidadPresentes>=this.cantMinQuorum){
          this.quorum="HAY QUORUM";
        }else{this.quorum="";
        localStorage.setItem("quorum","")};
      }
    }
  }
  ngOnInit(): void {
    this.esSecretario = this.getEsSecretario();
  
  }

  public Salir() {
    if(this.currentUser.rol=="USUDIP" || this.currentUser.rol=="UPRESI" || 
    this.currentUser.rol=="UUJIER" || this.currentUser.rol=="SECADM" ||
    this.currentUser.rol=="USULEG"
    ){
        this.http.get(`${environment.apiBaseUrl}/api/sesionInstancia/${this.currentUser.id}/salir`).subscribe((data:any)=>{
          this.router.navigate(['/login']);
        },
        error=>{
        });
      }
      this.SignalR.closeConnection();
    this.router.navigate(['/login']);
  }

  mostrarOradores(){
    this.showOradores=true;
    this.showHemiciclo=false;
    this.showAusentes=false;
    this.showPresentes=false;
    this.showGrilla=false;
  }

  mostrarPresentes(){
    this.showOradores=false;
    this.showHemiciclo=false;
    this.showAusentes=false;
    this.showPresentes=true;
    this.showGrilla=false;
  }

  mostrarAusentes(){
    this.showOradores=false;
    this.showHemiciclo=false;
    this.showAusentes=true;
    this.showPresentes=false;
    this.showGrilla=false;
  }

  mostrarHemiciclo(){
    this.showOradores=false;
    this.showHemiciclo=true;
    this.showAusentes=false;
    this.showPresentes=false;
    this.showGrilla=false;
  }

  mostrarGrilla(){
    this.showOradores=false;
    this.showHemiciclo=false;
    this.showAusentes=false;
    this.showPresentes=false;
    this.showGrilla=true;
    
  }

  getEsSecretario(){
    if(this.rolesSecretario.includes(this.currentUser.rol)){
      return true;
    }
    else{
      return false;
    }
  }

  mayoriaSimple(){
    return Math.trunc(parseInt(this.confeservice.returnPresentes())/2)+1;
  }

  mayoriaAbsoluta(){
    return this.confeservice.returnPresentes();
  }

  dosTerciosCamara(){
    return Math.trunc((92 *2 )/3);
  }

  unQuintoPresentes(){
    return Math.trunc(parseInt(this.confeservice.returnPresentes())/5);
  }

  unTercioPresentes(){
    return Math.trunc(parseInt(this.confeservice.returnPresentes())/3);
  }

  mayoriaDosTerciosPresentes(){
    return Math.trunc((parseInt(this.confeservice.returnPresentes())*2)/3);;
  }

  esSecAdm() {
    return this.currentUser.rol == "SECADM";
  }

  esUsuLeg() {
    return this.currentUser.rol == "USULEG";
  }

  esSecLeg() {
    return this.currentUser.rol == "SECLEG";
  }
  
  esAdmin() {
    return this.currentUser.rol == "UADMIN";
  }

  esUjier() {
    return this.currentUser.rol == "UUJIER";
  }
}
