import { Component, HostBinding, OnInit, OnDestroy, HostListener } from '@angular/core';
import { UpgradableComponent } from 'theme/components/upgradable';
import { Observable, Subject, Subscription } from 'rxjs';
import { SignalRService } from 'app/services/signal-r/signal-r.service';

import { AuthService } from '@services/*';
import { User } from 'app/models';
import { GrillaService } from 'app/services/grilla.service';


@Component({
  selector: 'app-secleg',
  templateUrl: './secleg.component.html',
  styleUrls: ['./secleg.component.scss']
})



export class SeclegComponent extends UpgradableComponent implements OnInit, OnDestroy {
  @HostBinding('class.mdl-grid') private readonly mdlGrid = true;
  @HostBinding('class.mdl-grid--no-spacing') private readonly mdlGridNoSpacing = true;
  @HostListener('window:unload', ['$event'])
  unloadHander($event) {
    /*       if(this.currentUser.rol!="UADMIN" && this.currentUser.rol!="OBSERV" && this.currentUser.rol!="USUREL"){
    
          } */
    this.SignalR.closeConnection();
    return $event.returnValue = 'Your changes will not be saved';
  }

  currentUser: User;
  currentUserSubscription: Subscription;
  mostrarVotacion: any = false;

  constructor(
    public SignalR: SignalRService,
    private authService: AuthService,
    public gr: GrillaService) {
    super();
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      this.currentUser = user;
    })
  }

  ngOnDestroy() { }

 
  ngOnInit(): void {
  }

  chequearHemiciclo() {
    if (localStorage.getItem("mostrarVotacion")) {
      this.mostrarVotacion = localStorage.getItem("mostrarVotacion");
    } else {
      this.mostrarVotacion = "false";
    }
    return this.mostrarVotacion;
  }

  stopConnectio() {
    this.SignalR.closeConnection();
  }


}
